import axios from 'axios'
import sleep from '../utils/sleep'

const {debug, error} = require('../utils/logger')('heybar:client:api', '#77FF00')

let tokenUri = null
let enhanceSecurity = null
let applyTokenWithCredentials = null
let beforeApplyToken  = null

const setTokenUri = uri => {
    tokenUri = uri
}

const setEnhanceSecurity = es => {
    enhanceSecurity = es
}

const setApplyTokenWithCredentials = credentials => {
    applyTokenWithCredentials = credentials
}

const setBeforeApplyToken = func => {
    beforeApplyToken = func
}

let token = null
let waitQueue = []
let lock = false


const waitTask = async (func, ...params) => {
    let resolve = null
    let reject = null
    const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
    })

    waitQueue.push(async (error) => {
        if (!error) {
            const result = await func(...params)
            resolve(result)
        } else {
            reject(error)
        }
    })
    return promise
}


/**
 * 取得 Heybar Access Token
 */
const applyToken = async () => {
    debug(`CALL apply token, lock is ${lock}`)
    if (!lock) {
        lock = true

        try {
            // 最多執行三次，每次 sleep 500ms
            for (let i = 0; i < 3; i++) {
                try {
                    // debug(tokenUri)
                    let es = enhanceSecurity
                    if (enhanceSecurity && typeof(enhanceSecurity) === 'function') {
                        es = enhanceSecurity()
                    }

                    const axiosConfig = {}
                    
                    if (es) axiosConfig.headers = {'x-heybar-es': es}
                    if (applyTokenWithCredentials != null) {
                        axiosConfig.withCredentials = applyTokenWithCredentials
                    }
                    if (beforeApplyToken) {
                        beforeApplyToken(axiosConfig)
                    }
                    const result = await axios.get(tokenUri, axiosConfig)
                    token = result.data
                    debug(`Success got TOKEN`)
                    
                    // 成功取回 token 則將 waitQueue 內的 task 全都執行完
                    while (waitQueue.length > 0) {
                        const task = waitQueue.shift()
                        task()
                    }
                    break
                } catch (error) {
                    debug('apply token occur error (loop %o): %o', i, error)
                    token = null
                }
                sleep(500)
            }

            // 迴圈跑完 token 是 null，表示拿 token 失敗，將 waitQueue 裏的 task 全設為失敗
            if (!token) {
                while (waitQueue.length > 0) {
                    const task = waitQueue.shift()
                    task('Get Token Failed')
                }
            }
            return token
        } finally {
            lock = false
        }
    }
}
const heybarCaller = async (path, method, params = null, headers = null) => {
    // debug(`RUN RUN CALLER:: ${path}`)
    // debug(`TOKEN IS ::: ${token}`)
    if (!token) {
        applyToken()
        return await waitTask(heybarCaller, path, method, params, headers)
    } else {
        try {
            const result = await axios({
                url: `${process.env.SERVER_URL}${path}`,
                method,
                data: params,
                headers: Object.assign({}, headers? headers : {}, {'X-heybar-token': token})
            })

            return result.data

        } catch (error) {
            debug(error)
            if (error.response && error.response.status === 401) {
                debug('401 error, refresh TOKEN')
                applyToken()
                return await waitTask(heybarCaller, path, method, params, headers)
            } else {
                throw error
            }
        }
    }
}


// const applyToken2 = async () => {
//     try {
//         debug(tokenUri)
//         const result = await axios.get(tokenUri)
//         return result.data
//     } catch (error) {
//         debug('apply token occur error: %o', error)
//         return null
//     }
// }

// const heybarCaller2 = async (path, method, params = null, headers = null) => {
//     // 取得 token
//     const token = await applyToken()
//     debug('token %o', token)
    
//     if (!token) return Promise.reject(new Error('Can not got ACCESS TOKEN!!'))

//     for (let i = 0; i < 3; i++) {
//         try {
//             const result = await axios({
//                 url: `${process.env.SERVER_URL}${path}`,
//                 method,
//                 data: params,
//                 headers: Object.assign({}, headers? headers : {}, {'X-heybar-token': token})
//             })

//             return result.data

//         } catch (error) {
//             debug(error)
//             if (error.response && error.response.status === 401) {
//                 debug('401 error, try again(%i)', i)
//                 continue
//             } else {
//                 throw error
//             }
//         }
//     }
    
// }

const getInitialData = async () => await heybarCaller('/api/heybar/getInitialData', 'GET')

const getLoginInitialData = async () => await heybarCaller('/api/heybar/getLoginInitialData', 'GET')

const getProductsUnreadAmount = async (products, queryType, groups) => await heybarCaller('/api/n11s/amount/unread/products', 'POST', {products, queryType})

const getGroupsUnreadAmount = async (groups) => await heybarCaller('/api/n11s/amount/unread/groups', 'POST', {groups})

const getNotifications = async(params) => await heybarCaller('/api/n11s/notifications', 'POST', params)

const setAsReadByDate = async(params) => await heybarCaller('/api/n11s/set_as_read/date', 'PUT', params)

const getGroupInfo = async(productId, groupUrl, groupUrlType) => await heybarCaller('/api/n11s/group_info', 'POST', {groupParam: {productId, groupUrl, groupUrlType}})
// const getUserN11sSettings = async () => await heybarCaller('/api/heybar/getUserN11sSettings', 'GET')

const downloadSettings = async function(settingsName) {
    try {
        const resp = await axios({
            baseURL: process.env.DOWNLOAD_SETTINGS_URL || process.env.SERVER_URL,
            url    : `/js/settings/${settingsName}.json`,
            method : 'GET',
            headers: {'Content-Type': 'application/json'},
            timeout: 2000
        })
        return resp
    } catch (err) {
        error('downloadSettings error. %O', err)
        throw err
    }
}

const proxyGet = async(url) => await heybarCaller('/proxy/get', 'POST', {url})

export {
    setTokenUri,
    setEnhanceSecurity,
    applyToken,
    getInitialData,
    getLoginInitialData,
    getProductsUnreadAmount,
    getGroupsUnreadAmount,
    getNotifications,
    setAsReadByDate,
    getGroupInfo,
    // getUserN11sSettings,
    setApplyTokenWithCredentials,
    setBeforeApplyToken,
    downloadSettings,
    proxyGet,
}
