/**
 * 
 * @param {string} key 
 * @param {string} value 
 * @param {int} expires 存活的毫秒數
 * @param {string} domain 
 * @param {string} path 
 */
export function setCookie(key, value, expires, domain, path) {
    if (!key) throw new Error('Set Cookie occur Error. key is required')
    if (!value) throw new Error('Set Cookie occur Error. value is required')

    const content = []
    content.push(`${key}=${value}`)
    if (expires) {
        const expire_date = new Date()
        expire_date.setTime(expire_date.getTime() + expires)
        content.push(`expires=${expire_date.toGMTString()}`)        
    }
    if (domain) {
        content.push(`domain=${domain}`)
    }
    if (path) {
        content.push(`path=${path}`)
    }

    document.cookie = content.join(';')
}

export function getCookie(key) {
    if (document.cookie) {
        const kvList = document.cookie.split(/\s*;\s*/)
        for (const kv of kvList) {
            const k2v = kv.split('=')
            if (k2v[0] === key) {
                return  k2v[1]
            }
        }
    }

    return null
}

export function deleteCookie(key, domain, path) {
    const content = []
    content.push(`${key}=`)
    content.push('expires=Thu, 01 Jan 1970 00:00:00 UTC')

    if (domain) {
        content.push(`domain=${domain}`)
    }
    if (path) {
        content.push(`path=${path}`)
    }

    document.cookie = content.join(';')
}