import axios              from 'axios'
// import barCss             from '~css/bar.scss'
import * as SystemEvents  from '@/constants/system-event'
import * as SuggestEvents from '@/constants/suggest-event'

const { debug, info, error } = require('@/utils/logger')(__filePath, __fileName, '#33CCAA')

const iconSearchGuideLine       = `${process.env.SERVER_URL}/img/icon-search-guideline.png`
const iconSearchGuideLineActive = `${process.env.SERVER_URL}/img/icon-search-guideline-active.png`

const searchMixin = {
    isComposingInput: false,  // 目前是否正在進行中文輸入
    __clickOustsideTheSuggestionTemp: null,
    __abortController: null,

    clickOutsideTheSuggestion() {
        this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.CLICK_OUTSIDE })
    },

    getBindThisClickOutsideTheSuggestion() { 
        if (!this.__clickOustsideTheSuggestionTemp) this.__clickOustsideTheSuggestionTemp = this.clickOutsideTheSuggestion.bind(this)
        return this.__clickOustsideTheSuggestionTemp
    },

    bindSearchEvent(container, inputNode, search, btnImgNode, fireKeyinEvent) {
        const suggestionNode = inputNode.dataset.suggest ? container.querySelector(`#${inputNode.dataset.suggest}`) : null

        inputNode.addEventListener('compositionstart', evt => {this.isComposingInput = true})
        inputNode.addEventListener('compositionend', async evt => {
            this.isComposingInput = false 
            if (fireKeyinEvent) fireKeyinEvent(evt.target.value)
            // 若 keyword 不為空，則發出 suggest 的請求，否則發生事件
            if (evt.target.value.length > 0) await this.doSuggest(evt)
            else this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.EMPTY_KEYWORD})

        })
        // inputNode.onkeyup = async evt => {
        inputNode.oninput = async evt => {
            // hightlight Search 按鈕
            this.highlightSearchBtn(evt.target.value, btnImgNode)
            // 中文輸入由 compositionend 處理，此處只處理英數字
            if (this.isComposingInput) return
            // 發出 keyin 的事件
            if (fireKeyinEvent) fireKeyinEvent(evt.target.value)
            // 若 keyword 若為空，發出事件
            if (evt.target.value.length === 0) this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.EMPTY_KEYWORD})
            // 無論如何都去 call doSuggest，為了中止前一個尚未查回的 suggest
            await this.doSuggest(evt)
        }

        inputNode.onkeydown = evt => {
            // 按下 Enter 且不是正處於中文輸入法時，送出 search
            if (evt.key === 'Enter' && !this.isComposingInput) {
                this.doSearch(evt, search)
            // 按下"向下"鍵時，若不是處於中文輸入法且 suggestion 區塊正打開著的話，則將 foucs 移到 suggestion 區塊
            } else if (evt.key === 'ArrowDown' && !this.isComposingInput) {
                evt.stopPropagation()
                evt.preventDefault()

                if (this.suggestion && suggestionNode) {
                    if (suggestionNode.style.display !== 'none' && suggestionNode.firstElementChild) {
                        inputNode.blur()
                        suggestionNode.firstElementChild.focus()
                        suggestionNode.firstElementChild.dispatchEvent(new MouseEvent('mouseenter'))
                    }
                }
            }
        }

        if (this.suggestion) {
            // 綁定 suggestion node 的事件，讓 user 點擊裏面的 suggestion 項目後能夠回寫回 input
            if (suggestionNode) {
                suggestionNode.onclick = evt => {
                    evt.stopPropagation()
                    evt.preventDefault()
                    this.selectedSuggestion(evt, inputNode)
                }
            }
            // 註冊點在 suggestion 外的 click 事件
            const listener = this.getBindThisClickOutsideTheSuggestion()
            document.body.removeEventListener('click', listener)
            document.body.addEventListener('click', listener)
        }
        
        // 按下 search 按鈕進行 search (模擬在 input Node 按下 Enter)
        if (btnImgNode) btnImgNode.onclick = () => {
            if (inputNode) {
                inputNode.dispatchEvent(new KeyboardEvent('keydown',{'keyCode': 13, 'key': 'Enter', 'code': 'Enter'}));
            }
        }
    },
    /**
     * 開始執行關鍵字搜尋
     * 
     * @param {KeyboardEvent} evt 
     * @param {function | string} search 執行搜尋的 function 或 url
     */
    doSearch(evt, search) {
        if (evt.key === 'Enter') {
            evt.stopPropagation()
            evt.preventDefault()
            
            // 進行 search 時，若產品有開啟 suggestion 功能，則要將 suggestion 視窗關閉
            if (this.suggestion) {
                this.closeSuggestion()
            }

            // 開始 Search
            evt.target.value = evt.target.value.trim()
            if (evt.target.value.length > 0) {
                if (typeof(search) === 'function') {
                    search(evt.target.value)
                } else {
                    const searchUrl = search.replace(/:keyword/g, evt.target.value)
                    debug('go to search page :: %s', searchUrl)
                    location.href = searchUrl
                }
            }
            
        }
    },
    /**
     * 當傳入的 inputKeyword 長度大於 0 時，將 btnImgNode 顯示成高亮度，否則顯示成低亮度
     * 
     * @param {string} inputKeyword 
     * @param {HTMLButtonElement} btnImgNode 
     */
    highlightSearchBtn(inputKeyword, btnImgNode) {
        if (btnImgNode) {
            if (inputKeyword.length > 0) {
                btnImgNode.src = iconSearchGuideLineActive
            } else {
                btnImgNode.src = iconSearchGuideLine
            }
        }
    },
    async doSuggest(evt) {
        // 有啟用 suggestion
        if (this.suggestion) {
            let suggestionWords = []
            let doQuery  = false
            let isCancel = false
            if (evt.target.value.length > 0 && evt.target.value.length >= this.suggestActivateNum) {
                doQuery = true
                try {
                    debug('call suggestion api, keyword is %o', evt.target.value)
                    this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.SUGGEST_START})
                    // 如果有 AbortController 物件，則將前一次的 suggest 呼叫中止
                    if (this.__abortController) {
                        this.__abortController.abort()
                    }
                    this.__abortController = new AbortController()

                    if (typeof(this.suggestion) === 'string') {
                        const result = await axios.get(this.suggestion.replace(/:keyword/, evt.target.value), { signal: this.__abortController.signal })
                        debug('suggestion result is %o', result)

                        if (result.status - 200 < 100) {
                            suggestionWords = result.data
                        } else {
                            error('Call suggestion FAIL!!')
                            error(result)
                            throw new Error(`Call suggestion fail. status = ${result.status}`)
                        }
                    } else if (typeof(this.suggestion) === 'function') {
                        suggestionWords = await (abortSignal => new Promise((resolve, reject) => {
                            if ( abortSignal.aborted ) {
                                return reject( new axios.Cancel('canceled') );
                            }

                            abortSignal.addEventListener( 'abort', () => {
                                reject( new axios.Cancel('canceled') );
                            })

                            this.suggestion(evt.target.value).then(result => {
                                resolve(result)
                            })
                        }))(this.__abortController.signal)
                        
                        // suggestionWords = await this.suggestion(evt.target.value)  
                        
                        debug('suggestionWords is %s', suggestionWords)
                        
                    } else {
                        throw new Error('suggestion must be "string" or "function"')
                    }
                } catch (err) {
                    if (err instanceof axios.Cancel) {
                        isCancel = true
                        info(`cancel suggest "${evt.target.value}"`)
                    } else {
                        error('Call suggestion occur ERROR!')
                        error(err)
                    }
                } finally {
                    // 不是被 cancel 掉的才發 suggest_end 事件
                    if (!isCancel) this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.SUGGEST_END})
                }
            // 這段主要是為了當 user 按 backspace 鍵刪字時，若刪到低於觸發字數的話，要將前一個查詢 suggest 中止掉
            } else if (this.__abortController !== null) {
                this.__abortController.abort()
            }
            
            if (suggestionWords.length > 0) {
                this.emit(SystemEvents.SUGGEST, {action: SuggestEvents.SUGGESTIONS, data: suggestionWords})
            // 有進查詢且不是因為 cancle 的前提下， suggestionWords 為空才能發出 NO_DATA
            } else if (doQuery && !isCancel) {
                this.emit(SystemEvents.SUGGEST, {action: SuggestEvents.NO_DATA})
            }
        }
    },
    /**
     * 點擊 suggestion 項目後，要將該項目寫回 input，並觸發 Search
     * 
     * @param {MouseEvent} evt 
     * @param {HTMLInputElement} inputNode 
     */
    selectedSuggestion(evt, inputNode) {
        console.log(evt.target)
        let suggestionWordNode = null
        if (!evt.target.dataset.suggestionWord) {
            const parents = []
            let   element = evt.target
            while(element && element !== evt.currentTarget) {
                parents.push(element.parentNode);
                element = element.parentNode;
            }
            for (const node of parents) {
                if (node.dataset.suggestionWord) {
                    suggestionWordNode = node
                    break
                }
            }
        } else {
            suggestionWordNode = evt.target
        }

        if (!suggestionWordNode) return
        inputNode.value = suggestionWordNode.dataset.suggestionWord
        this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.SELECTED_SUGGESTION, data: suggestionWordNode.dataset.suggestionWord})
        // 在 input node 上觸發 Enter 事件來引發 Search
        inputNode.dispatchEvent(new KeyboardEvent('keydown',{'keyCode': 13, 'key': 'Enter', 'code': 'Enter'}));
        
        // if (!evt.target.dataset.suggestionWord) return
        // evt.stopPropagation()
        // evt.preventDefault()
        // inputNode.value = evt.target.dataset.suggestionWord
        // this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.SELECTED_SUGGESTION, data: evt.target.dataset.suggestionWord})
        // // 在 input node 上觸發 Enter 事件來引發 Search
        // inputNode.dispatchEvent(new KeyboardEvent('keydown',{'keyCode': 13, 'key': 'Enter', 'code': 'Enter'}));
    },
    
    /**
     * 關閉 Suggestion 視窗
     * (發出事件)
     */
    closeSuggestion() {
        this.emit(SystemEvents.SUGGEST, { action: SuggestEvents.CLOSE_SUGGESTION })
    }
}

/* 註：用 function 的寫法才能保在 this。若用 xxx: () => {} 的寫法，則 this 會變成 undefined */

export default () => ({...searchMixin})