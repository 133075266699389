// import barCss from '../assets/css/heybar.scss'
import barCss from '~css/bar.scss'
// import barCss from '../assets/css/bar.scss'

export default (pluginNames) => `
<div class="${barCss.bodyClass}">
    <!--產品固定區塊-->
    <div class="${barCss.barTop}">
        <heybar-mode class='${barCss.i_am_mobile}'></heybar-mode>
        <navigation style="width:100%"></navigation>
        <!--logo(pc版出現) 節日彩蛋加在此處-->
        <a href="#" class="${barCss.bar_logo}"><logo></logo></a>
        <!--右側所有區塊-->
        <div class="${barCss.rightBox}">
            <!--one word btn -->
            <one-word-btn></one-word-btn>
            <!--搜尋區塊(M版出現)-->
            <search></search>
            <!--搜尋input-->
            <search-input></search-input>
            <!--購物車-->
            <shopping-cart></shopping-cart>
            <!-- 客製化功能存放區 -->
            ${pluginNames.map(name => '<custom-feature-' + name + '></custom-feature-' + name + '>').join('')}
            <!--通知區塊-->
            <notify></notify>
            <!--個人區塊-->
            <personal></personal>
        </div>
    </div>
</div>
`

// 節日彩蛋使用
// <a href="#" class="${barCss.bar_logo}"><logo></logo></a>
// <a href="#" class="${barCss.bar_logo} ${barCss['is-festival']}" title="聖誕節快樂"><span id='festival_logo_left' style="height: 44px; width: 100px"></span><logo></logo><span id='festival_logo_right' style='height: 44px; width: 100px'></span></a>
// <a href="#" class="${barCss.bar_logo} ${barCss['is-festival']}" title="新年快樂"><span id='festival_logo_left' style="height: 44px; width: 100px"></span><logo></logo><span id='festival_logo_right' style='height: 44px; width: 100px'></span></a>
// <a href="#" class="${barCss.bar_logo} ${barCss['is-festival']}" title="中秋節快樂"><span id='festival_logo_left' style="height: 44px; width: 100px"></span><logo></logo><span id='festival_logo_right' style='height: 44px; width: 100px'></span></a>
// <a href="#" class="${barCss.bar_logo} ${barCss['is-festival']}" title="2023 新年快樂"><span id='festival_logo_left' style="height: 44px; width: 100px"></span><logo class="${barCss.logo_deep_hide}"></logo><span id='festival_logo_right' style='height: 44px; width: 100px' class="${barCss.logo_deep_hide}"></span></a>
// <a href="#" class="${barCss.bar_logo} ${barCss['is-festival']}" title="端午節快樂"><span id='festival_logo_left' style="height: 44px; width: 100px"></span><logo class="${barCss.logo_deep_hide}"></logo><span id='festival_logo_right' style='height: 44px; width: 100px' class="${barCss.logo_deep_hide}"></span></a>
// 