import StandardNotificationParser from './standard-parser'

const {debug} = require('@/utils/logger')(__filePath, __fileName, '#D28EFF')

export const getParserByProductId = productId => {
    switch(productId) {
        default:
            return StandardNotificationParser
    }
}

export const getParserByName = parserName => {
    switch(parserName) {
        default:
            return StandardNotificationParser
    }
}