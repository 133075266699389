import barCss           from '~css/bar.scss'
// import iconLoader       from '~img/icon-loader.svg'
const iconLoader  = `${process.env.SERVER_URL}/img/icon-loader.svg`

const notifyTemp = () => `
<!--通知區塊-->
<!--加上class"closeBox"關閉通知內容-->
<div class="${barCss.notice} ${barCss.closeBox}">
    <!--將class"barIcon_notice"換成"barIcon_notice_selected"會變橘色-->
    <a style="cursor:pointer;" class="${barCss.barIcon} ${barCss.barIcon_notice}" data-gtm-whitebar="browsing_notify-icon" id="heybarNotifyRing"></a>

    <!--通知內容-->
    <div class="${barCss['notice-content']}">
        <div class="${barCss['notice-label']}">通知</div>
        <div class="${barCss['notice-tabBox']}">
            <div class="${barCss['notice-tabHead']}">
                <!--加上class"active"成為選取狀態-->
                <div class="${barCss['notice-tag']} ${barCss.active} notice-tab" data-gtm-whitebar="browsing_notify-tab-this" style="display: none">{{productName}}</div>
                <!--加上class"redDot"有小紅點-->
                <div class="${barCss['notice-tag']} group-tab" data-gtm-whitebar="browsing_notify-tab-others-cmy" style="display: none">{{groupDesc}}</div>
                <!--加上class"redDot"有小紅點-->
                <div class="${barCss['notice-tag']} other-tab" data-gtm-whitebar="browsing_notify-tab-others" style="display: none">其他產品</div>
            </div>
            <div class="${barCss["notice-tabList"]} notice-tabListNode">
                <!--1筆通知-->                            
                <div class="${barCss['notice-tabItem']} ${barCss.nothing}" style='display:none'>
                    <p>您目前尚無個人通知</p>
                </div>
                <div ref="infiniteScroll" style="height:10px"></div>
                <footer style='text-align: center; margin-top: 30px;'>
                    <img src="${iconLoader}" />
                </footer>
            </div>
            <div class="${barCss["notice-tabList"]} group-tabListNode" style='display:none'>
                <!--1筆通知-->                            
                <div class="${barCss['notice-tabItem']} ${barCss.nothing}">
                    <p>您目前尚無{{groupDesc}}通知</p>
                </div>
                <div ref="infiniteScroll" style="height:10px"></div>
                <footer style='text-align: center; margin-top: 30px;'>
                    <img src="${iconLoader}" />
                </footer>
            </div>
            <div class="${barCss["notice-tabList"]} other-tabListNode" style='display: none'>
                <!--1筆通知-->                            
                <div class="${barCss['notice-tabItem']} ${barCss.nothing}">
                    <p>您目前尚無其他產品通知</p>
                </div>
                <div ref="infiniteScroll" style="height:10px"></div>
                <footer style='text-align: center; margin-top: 30px;'>
                    <img src="${iconLoader}" />
                </footer>
            </div>
            <div class='bottom-observer' style='width: 1px; height: 1px;'></div>
        </div>
    </div>
</div>
`

export {
    notifyTemp,
}