import barCss              from '~css/bar.scss'
// import iconSearchGuideLine from '~img/icon-search-guideline.png'

const iconSearchGuideLine = `${process.env.SERVER_URL}/img/icon-search-guideline.png`

// const searchInputSuggestion = `
// <div class="${barCss.suggestion}">
// <div class="${barCss.suggestion_item}">
//     一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十
// </div>
// <div class="${barCss.suggestion_item}">ha ha ha 2</div>
// <div class="${barCss.suggestion_item}">ha ha ha 3</div>
// </div>`
const searchInputSuggestion = () => `<div class="${barCss.suggestion}" id="search_input_suggest" style="display: none"></div>`

const searchInput = addSuggestion => `
<div class="${barCss.searchBox}" id="heybarSearchInput">
    <input type="text" class="${barCss['form-control']}" placeholder="搜尋" id="heybarSearchInputKeyword" style="padding-left: 8px" data-gtm-whitebar="search_tools-search" autocomplete="off" data-suggest='search_input_suggest'>
    <button type="button" id="heybarSearchInputBtn">
        <img src="${iconSearchGuideLine}" alt="" width="16">
    </button>
    ${addSuggestion ? searchInputSuggestion() : ''}
</div>`

export {
    searchInput,
}