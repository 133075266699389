export const OPEN = 'open'
export const CLOSE = 'close'
export const BEFORE_OPEN = 'beforeOpen'
export const BEFORE_CLOSE = 'beforeClose'
export const BEFORE_TAB_CHANGE = 'beforeTabChange'
export const TAB_CHANGE = 'tabChange'
export const CLICK      = 'click'
export const CLICK_USER = 'clickUser'
export const LINKS_BEFORE_LOGIN_OPEN = 'linksBeforeLoginOpen'
export const LINKS_BEFORE_LOGIN_CLOSE = 'linksBeforeLoginClose'
export const HIDE = 'hide'
export const SHOW = 'show'