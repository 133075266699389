const {debug} = require('@/utils/logger')(__filePath, __fileName, '#66FF66')

export const EVENT_ADD_BUTTETIN = 'notification/addBulletin'
export const EVENT_ADD_NOTIFICATION = 'notification/addNotification'
export const EVENT_CLICK_NOTIFICATION = 'notification/clickNotification'
export const EVENT_CLICK_GROUP_ITEM = 'notification/clickGroupItem'
export const EVENT_CLICK_PRODUCT_ITEM = 'notification/clickProductItem'
export const EVENT_NO_UNREAD_ALL      = 'notification/noUnread'
export const EVENT_NO_UNREAD_THIS     = 'notification/noUnread/this'
export const EVENT_NO_NOTIFICATION_UNREAD_THIS = 'notification/noUnread/notify/this'

export class NotificationEvent {
    constructor(eventName) {
        this.eventName = eventName
        this.callbacks = []
    }

    register(callback) {
        if (!this.callbacks.includes(callback)) {
            this.callbacks.push(callback)
        }
    }

    unregister(callback) {
        const index = this.callbacks.indexOf(callback)

        if (index > -1) {
            this.callbacks.splice(index, 1)
        }
    }

    fire(data) {
        this.callbacks.forEach(callback => {
            callback(data)
        })
    }
}
