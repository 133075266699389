import eventBus from "@/utils/event-bus"

const eventMixin = {
    SYSTEM_EVENT_PREFIX: '@system/',

    on (event, handlers, isSystemEvent = false) {
        this.handlers = this.handlers || {}
        if (!event || event.length === 0 || !handlers) {
            throw Error(`Can not handle event. event::${event}, handlers::${handlers}`)
        }

        event = isSystemEvent ? `${this.SYSTEM_EVENT_PREFIX}${event}` : event
        handlers = typeof(handlers) === 'function' ? [handlers] : handlers

        if (!this.handlers[event]) {
            this.handlers[event] = []
        }

        handlers.forEach(handler => {this.handlers[event].push(handler)})
    },

    off (event, handlers, isSystemEvent = false) {
        this.handlers = this.handlers || {}
        if (this.handlers[event]) {
            event = isSystemEvent ? `${this.SYSTEM_EVENT_PREFIX}${event}` : event
            handlers = typeof(handlers) === 'function' ? [handlers] : handlers

            handlers.forEach(handler => {
                const index = this.handlers[event].indexOf(handler)

                if (index > -1) {
                    this.handlers[event].splice(index, 1)
                }
            })
        }        
    },

    __fire (event, data, isSystemEvent) {
        this.handlers = this.handlers || {}
        const _event = event
        event  = isSystemEvent ? `${this.SYSTEM_EVENT_PREFIX}${event}` : event
        if (this.handlers[event]) {
            this.handlers[event].forEach(handler => {
                handler(data)
            })
        }

        if (isSystemEvent) {
            eventBus.fire(_event, this.name || this._isOpenPluginName_, data)
        }
    },
    /**
     * 發出對外的事件
     * @param {string} event 
     * @param {any} data 
     */
    fire (event, data) {
        this.__fire(event, data, false)
    },
    /**
     * 發出對內(heybar內)的事件
     * @param {string} event 
     * @param {any} data 
     */
    emit (event, data) {
        this.__fire(event, data, true)
    },

    cleanHandlers() {
        this.handlers = this.handlers || {}
        for (const event of Object.keys(this.handlers)) {
            if (!event.startsWith(this.SYSTEM_EVENT_PREFIX)) {
                delete this.handlers[event]
            }
        }
    },

    destroyHandlers() {
        this.handlers = {}
    }
}

export default () => ({...eventMixin})