import barCss from '~css/bar.scss'

const customFeatureTemp = (featureId, hideIn, bgUrl, bgHoverUrl) => `
<!--客製功能區塊-->
<!--加上class"closeBox"關閉客製功能內容-->
<div id="${featureId}" class="${barCss.customFeature} ${barCss.closeBox} ${hideIn === 'pc' ? barCss.hideInPC : hideIn === 'mobile' ? barCss.hideInMobile : ''}">
    <!--將class"barIcon_customFeature"換成"barIcon_customFeature_selected"會變橘色-->
    <a class="${barCss.barIcon} ${barCss.barIcon_customFeature}" style="cursor:pointer;--heybarIconUrl: url(${bgUrl}); --heybarIconHoverUrl: url(${bgHoverUrl});"></a>
    <!--客製功能小紅點-->
    <div class="${barCss.customFeature_count}" style='display: none'>
        <span></span>
    </div>
    <!--客製功能內容 -->
    <div class="${barCss['customFeature-content']}">
        <div class='dropdown-content' style='width: 100%; height: 100%; overflow-y: auto'></div>
        <div class='bottom-observer' style='width: 1px; height: 1px;'></div>
    </div>
</div>
`

export {
    customFeatureTemp,
}