import BaseComponent            from '@/components/base-component'
import { customFeatureTemp }     from "@/templates/custom-feature-temp"
import { stringToDom, isNil }   from '@/utils/tools'
import { domMixin, eventMixin } from '@/mixins'
import * as SystemEvents        from '@/constants/system-event'
import * as HeybarEvents        from '@/constants/heybar-event'
import * as modeHelper          from '@/helper/heybar-mode-helper'
import barCss                   from '~css/bar.scss'

const { debug, error } = require('@/utils/logger')(__filePath, __fileName, '#C94DDB')

/*
feature config:
{
    name: '',
    icon: ['', ''],
    triggerEvent: '',
    useArrow: false,
    dropdown: {
        content: '',
        closeAfterClick: boolean,
    },
    handler: {
        pc: '',
        mobile: '',
    },
    displayWhen: 'always/afterLogin/beforeLogin',
    hideIn: 'pc/mobile',
    action: {
        closeAfterClick: boolean,
        pc: {
            dropdown: '',
            link: ''
        },
        mobile: {
            dropdown: '',
            link: ''
        }
    }

}

*/

class CustomFeature extends BaseComponent {
    // static MARK_TAG = 'custom-features'
    
    constructor(mainNode, config, featureConfig) {
        super(mainNode, config)
        this.isOpen        = false
        this.featureConfig = featureConfig
        this.name          = featureConfig.name
        this.markTagName   = `custom-feature-${featureConfig.name}`
        this.id            = `heybar_${featureConfig.name}`
        this.__eventClose4BodyTemp = this.eventClose4Body.bind(this)
        
        // this.closeTimer    = null
    }

    // _getMarkNode() { return this.mainNode.querySelectorAll(this.markTagName) }
    // _getRestoreMarkNode() { return document.createElement(this.markTagName) }
    getMarkTagName() { return this.markTagName}
    getId() { return this.id}

    _getCustomFeatureNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id)
    }

    _getCustomFeatureContentNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id, ` .${barCss['customFeature-content']}`)
    }

    _getDropDownContentNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id, ` .${barCss['customFeature-content']} > .dropdown-content`)
    }

    _getBottomObserverNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id, ` .${barCss['customFeature-content']} > .bottom-observer`)
    }

    _getSwitchNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id, ` .${barCss.barIcon}`)
    }

    _getBadgeNode(anchor = this.mainNode) {
        return this.findById(anchor, this.id, ` .${barCss.customFeature_count}`)
    }

    _generateNode() {
        const node = stringToDom(customFeatureTemp(this.id, this.featureConfig.hideIn, this.featureConfig.icon[0], this.featureConfig.icon[1]))
        if (!isNil(this.featureConfig.dropdown) && !isNil(this.featureConfig.dropdown.content)) {
            this.setDropdown(this.featureConfig.dropdown.content, node)
        }
        
        return node
    }

    _dropdownEventHandler(evt) {
        if (this.featureConfig.triggerEvent === 'CLICK') {
            if (this.isOpen) this.eventClose(evt)
            else this.eventOpen(evt)
        } else if (this.featureConfig.triggerEvent === 'HOVER') {
            if (!this.isOpen) this.eventOpen(evt)
            else if (modeHelper.isMobileMode(this.mainNode)) {
                this.eventClose(evt)
            }
        }
    }

    _bindEvent(container) {
        const switchNode = this._getSwitchNode(container)
        if (switchNode) {
            let switchClickFunc = null

            if (this.featureConfig.handler.pc || this.featureConfig.handler.mobile) {
                switchClickFunc = evt => {
                    if (modeHelper.isMobileMode(this.mainNode)) {
                        if (this.featureConfig.handler.mobile) {
                            // if (this.isOpen) this.closeTimer = setTimeout(() => {this.eventClose(evt)}, 50)
                            this.featureConfig.handler.mobile()
                        } else {
                            this._dropdownEventHandler()
                        }
                    } else {
                        if (this.featureConfig.handler.pc) {
                            // if (this.isOpen) this.closeTimer = setTimeout(() => {this.eventClose(evt)}, 50)
                            this.featureConfig.handler.pc()
                        } else {
                            this._dropdownEventHandler()
                        }
                    }
                }
            } else {
                switchClickFunc = this._dropdownEventHandler
            }

            if (this.featureConfig.triggerEvent === 'CLICK') {
                switchNode.onclick = evt => {
                    evt.stopPropagation()
                    switchClickFunc.call(this, evt)
                }
            } else if (this.featureConfig.triggerEvent === 'HOVER') {
                switchNode.onclick = evt => {
                    evt.stopPropagation()
                    // 因為 mobile 沒有 hover event，所以要改用 click 事件
                    if (modeHelper.isMobileMode(this.mainNode)) {
                        switchClickFunc.call(this, evt)
                    }
                }

                switchNode.onmouseenter = evt => {
                    if (this.closeTimer) clearTimeout(this.closeTimer)
                    evt.stopPropagation()
                    if (modeHelper.isPCMode(this.mainNode)) {
                        switchClickFunc.call(this, evt)
                    }
                }

                switchNode.onmouseleave = evt => {
                    evt.stopPropagation()
                    if (this.closeTimer) clearTimeout(this.closeTimer)
                    if (modeHelper.isPCMode(this.mainNode)) {
                        this.closeTimer = setTimeout(() => {this.eventClose(evt)}, 300)
                    }
                }

                const contentNode = this._getCustomFeatureContentNode(container)
                if (contentNode) {
                    contentNode.onmouseenter = evt => {
                        evt.stopPropagation()
                        if (this.closeTimer) clearTimeout(this.closeTimer)
                    }
                    contentNode.onmouseleave = evt => {
                        evt.stopPropagation()
                        if (this.closeTimer) clearTimeout(this.closeTimer)
                        if (modeHelper.isPCMode(this.mainNode)) {
                            this.closeTimer = setTimeout(() => {this.eventClose(evt)}, 300)
                        }
                    }
                }
            }

            if (this.featureConfig.useArrow) {
                if (this.featureConfig.triggerEvent === 'HOVER') {
                    switchNode.classList.add(barCss['customFeature-arrow'])
                } else if (this.featureConfig.triggerEvent === 'CLICK') {
                    switchNode.classList.add(barCss['customFeature-arrow_click'])
                }
                switchNode.parentElement.classList.add(barCss['useArrow'])

                this.on(HeybarEvents.OPEN, [() => {this._getSwitchNode(this.mainNode).classList.add(barCss['customFeature-arrow-hover'])}])
                this.on('close', () => {this._getSwitchNode(this.mainNode).classList.remove(barCss['customFeature-arrow-hover'])})
            }

            if (this.featureConfig.iconWidth) {
                if (this.featureConfig.iconWidth === 'auto') {
                    const img = document.createElement('img')
                    img.src = this.featureConfig.icon[0]
                    img.style.opacity = 0
                    img.style.maxHeight = '24px'
                    switchNode.appendChild(img)
                    img.onload = evt => {
                        const style = evt.currentTarget.parentElement.getAttribute('style')
                        evt.currentTarget.parentElement.setAttribute('style', `${style};width:${evt.currentTarget.width}px;background-size: ${evt.currentTarget.width}px ${evt.currentTarget.height}px !important;`)
                        evt.currentTarget.parentElement.removeChild(evt.currentTarget)
                    }
                } else {
                    const style = switchNode.getAttribute('style')
                    switchNode.setAttribute('style', `${style};width:${this.featureConfig.iconWidth};background-size: ${this.featureConfig.iconWidth} 24px !important;`)
                }
            }

        } else error(`${this.name} switch event FAIL because can not found any switch node`)

        if (!this.featureConfig.dropdown.closeAfterClick) {
            const contentNode = this._getCustomFeatureContentNode(container)
            if (contentNode) contentNode.onclick = evt => {evt.stopPropagation()}
            else error(`set ${this.name} content node event error, can not found it.`)
        }

        // 點擊頁面關閉通知
        window.removeEventListener('click', this.__eventClose4BodyTemp)
        window.addEventListener('click', this.__eventClose4BodyTemp)

        let timer = null
        let offsetHight = 0
        this.bottomObserver = new IntersectionObserver((entries, observer) => {
            // 只有通知開啟時才運作
            if (!this.isOpen || !modeHelper.isMobileMode(this.mainNode)) {
                // console.log('-----> is NOT OPEN')
                return
            }
            // console.log('--->>1', entries[0].isIntersecting)
            // console.log('--->>2', entries[0].intersectionRatio)
            if (!entries[0].isIntersecting) {
                const x = this._getDropDownContentNode()
                // x.style.height = '100px'
                
                timer = setInterval(() => {
                    offsetHight ++
                    x.style.height = 'calc(100% - ' + (offsetHight) + 'px)'
                    // console.log(entries[0].intersectionRatio)
                    
                }, 10)
            } else {
                if (entries[0].intersectionRatio >= 1) {
                    clearInterval(timer)
                }
            }
        }, {threshold: [0, 1]})
    }

    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return // 已是開啟的情況下就不用再執行了
        this.fire(HeybarEvents.BEFORE_OPEN)

        // 開啟小視窗
        const cartNode = this._getCustomFeatureNode()
        if (cartNode) cartNode.classList.remove(barCss.closeBox)
        else error(`can not found "${this.name}" feature node, open shopping cart panel fail.`)

        // icon 亮橘色
        const switchNode = this._getSwitchNode()
        if (switchNode) switchNode.classList.add(barCss.barIcon_customFeature_selected)
        else error(`can not found "${this.name}" feature node switch node, change selected color fail.`)

        this.isOpen = true
        this.emit(SystemEvents.OPEN)
        this.fire(HeybarEvents.OPEN)

        this.bottomObserver.observe(this._getBottomObserverNode())
    }

    eventClose(evt, stopPropagation=true) {
        if (evt && stopPropagation) evt.stopPropagation()
        if (!this.isOpen) return // 已是關閉的情況下就不用再執行關閉了

        this.fire(HeybarEvents.BEFORE_CLOSE)

        // 關閉小視窗
        const cartNode = this._getCustomFeatureNode()
        if (cartNode) cartNode.classList.add(barCss.closeBox)
        else error(`can not found "${this.name}" feature node, close shopping cart panel fail.`)

        // icon(switch node)要拿掉橘色
        const switchNode = this._getSwitchNode()
        if (switchNode) switchNode.classList.remove(barCss.barIcon_customFeature_selected)
        else error(`can not found "${this.name}" feature switch node, change unselect color fail.`)

        this.isOpen = false
        this.emit(SystemEvents.CLOSE)
        this.fire(HeybarEvents.CLOSE)

        if (this.bottomObserver) this.bottomObserver.unobserve(this._getBottomObserverNode())
        
    }

    eventClose4Body(evt) {
        this.eventClose(evt, false)
    }

    setBadge(count) {
        const badgeNode = this._getBadgeNode()
        if (badgeNode) {
            badgeNode.querySelector('span').innerHTML = count >= 100 ? '99' : count
            if (count > 0) {
                badgeNode.style.display = null
            } else {
                badgeNode.style.display = 'none'
            }
        } else error('can not find badge node')
    }

    setDropdown(content, anchor=this.mainNode) {
        // const contentNode = this._getCustomFeatureContentNode(anchor)
        const contentNode = this._getDropDownContentNode(anchor)
        console.log(contentNode)
        if (typeof(content) === 'string') {
            if (content.startsWith('#') || content.startsWith('.')) {
                const dropdownNode = document.querySelector(content)
                if (dropdownNode) {
                    dropdownNode.style.display = null
                    contentNode.innerHTML = ''
                    contentNode.appendChild(dropdownNode)
                } else {
                    // error(`Can not find dropdown node (${content})`)
                    contentNode.innerHTML = content
                }
            } else {
                contentNode.innerHTML = content
            }
        } else if (content instanceof Node) {
            content.style.display = null
            contentNode.innerHTML = ''
            contentNode.appendChild(content)

        } else if (typeof(content) === 'function') {
            const dropdownObject = content()
            if (typeof(dropdownObject) === 'string') {
                contentNode.innerHTML = dropdownObject
            } else {
                dropdownObject.style.display = null
                contentNode.innerHTML = ''
                contentNode.appendChild(dropdownObject)
            }

        } else {
            contentNode.appendChild(content)
        }
    }

    cleanDropdown(anchor=this.mainNode) {
        // const contentNode = this._getCustomFeatureContentNode(anchor)
        const contentNode = this._getDropDownContentNode(anchor)
        const childNodes  = []
        while(contentNode.firstChild) {
            childNodes.push(contentNode.firstChild)
            contentNode.removeChild(contentNode.firstChild)
        }
        return childNodes
        // contentNode.innerHTML = ''
    }

    close() {
        this.eventClose()
    }

    open() {
        this.eventOpen()
    }

    toggle() {
        // if (this.closeTimer) {
        //     clearTimeout(this.closeTimer)
        //     this.closeTimer = null
        // }
        if (this.isOpen) this.eventClose()
        else this.eventOpen()
    }
}

Object.assign(CustomFeature.prototype, domMixin(), eventMixin())
export default CustomFeature
