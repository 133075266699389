import BaseComponent                         from '@/components/base-component'
import { searchInput }                       from '@/templates/search-input-temp'
import { stringToDom }                       from '@/utils/tools'
import { domMixin, eventMixin, searchMixin } from '@/mixins'
import * as SuggestEvents                    from '@/constants/suggest-event'
import * as SystemEvents                     from '@/constants/system-event'
import barCss                                from '~css/bar.scss'


const { debug, error } = require('@/utils/logger')(__filePath, __fileName, '#33CCFF')
const SEARCH_INPUT_ID         = 'heybarSearchInput'
const SEARCH_INPUT_KEYWORD_ID = 'heybarSearchInputKeyword'
const SEARCH_INPUT_MARK_TAG   = 'search-input'
const SEARCH_INPUT_BTN_ID     = 'heybarSearchInputBtn'
const SEARCH_INPUT_SUGGEST_ID = 'search_input_suggest'

class SearchInput extends BaseComponent {
    static MARK_TAG = 'search-input'
    
    constructor(mainNode, config) {
        super(mainNode, config, ['search', 'suggestion', 'suggestActivateNum'])
    }

    _findSearchInputKeywordNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_INPUT_KEYWORD_ID)
    }

    _findSearchInputBtnNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_INPUT_BTN_ID)
    }

    _findSearchInputSuggestNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_INPUT_SUGGEST_ID)
    }

    _generateNode() {
        return stringToDom(searchInput(this.suggestion))
    }

    _bindEvent(container) {
        const searchInputKeywordNode = this._findSearchInputKeywordNode(container)
        const searchInputBtnNode = this._findSearchInputBtnNode(container)
        
        if (searchInputKeywordNode) {
            this.bindSearchEvent(container, searchInputKeywordNode, this.search, searchInputBtnNode ? searchInputBtnNode.querySelector('img'): null, keyword => { this.emit(SystemEvents.KEYWORD, keyword) })
        }
        else error('Can not bind event to search input node because can not found search input node.')
    }

    drawPlaceholder(placeholder, prefix = '搜尋') {
        const keywordNode = this._findSearchInputKeywordNode()
        if (keywordNode) keywordNode.setAttribute('placeholder', `${prefix ? prefix + ' ' : ''}${placeholder ? placeholder : ''}`)
    }

    drawValue(value) {
        const keywordNode = this._findSearchInputKeywordNode()
        if (keywordNode) keywordNode.value = value
    }

    syncKeyword(keyword) {
        const searchInputKeywordNode = this._findSearchInputKeywordNode()
        if (searchInputKeywordNode) searchInputKeywordNode.value = keyword
    }

    syncSuggestion(evt) {
        switch (evt.action) {
            case SuggestEvents.OPEN_SUGGESTION:
                this.syncOpenSuggestion(evt)
                break
            case SuggestEvents.CLOSE_SUGGESTION:
            case SuggestEvents.NO_DATA:
            case SuggestEvents.CLICK_OUTSIDE:
            case SuggestEvents.EMPTY_KEYWORD:
                this.syncCloseSuggestion(evt)
                break
            case SuggestEvents.SUGGESTIONS:
                this.syncDrawSuggestion(evt)
                break
            case SuggestEvents.SELECTED_SUGGESTION:
                this.syncKeyword(evt.data)
                break
        }
    }

    syncOpenSuggestion(evt) {
        const suggestionNode = this._findSearchInputSuggestNode(this.mainNode)
        if (suggestionNode) suggestionNode.style.display = null
    }

    syncCloseSuggestion(evt) {
        const suggestionNode = this._findSearchInputSuggestNode(this.mainNode)
        if (suggestionNode) {
            suggestionNode.style.display = 'none'
            suggestionNode.innerHTML = ''
        }
    }

    syncDrawSuggestion(evt) {
        const suggestionWords = evt.data
        const suggestionNode = this._findSearchInputSuggestNode(this.mainNode)
        if (suggestionNode) {
            const searchInputKeywordNode = this._findSearchInputKeywordNode(this.mainNode)
            const inputKeyword = searchInputKeywordNode ? searchInputKeywordNode.value : ''
            suggestionNode.style.display = null
            suggestionNode.innerHTML = ''

            for (const word of suggestionWords) {
                let displayWord = word
                const ind = word.indexOf(inputKeyword)
                if (ind >= 0) {
                    displayWord = `${word.substring(0, ind)}<span style='color: #292929;'>${inputKeyword}</span>${word.substring(ind + inputKeyword.length)}`
                }

                const item = document.createElement('div')
                item.className = barCss.suggestion_item
                item.innerHTML = displayWord
                item.dataset.gtmSubParam = `關鍵字-${word}`
                item.dataset.suggestionWord = word

                item.tabIndex = 0
                
                item.onmouseenter = function(evt) { 
                    this.style.backgroundColor = '#ffeedf'
                    this.style.borderWidth = 0
                    this.style.outline = '0 none'
                    this.focus()
                }
                item.onmouseleave = function(evt) {
                    if (this.parentNode) {
                        for (const node of this.parentNode.childNodes) {
                            node.style.backgroundColor = null
                        }
                    }
                }
                const suggestionHandler = this.selectedSuggestion.bind(this)
                item.onkeydown = function(evt) {
                    evt.stopPropagation()
                    evt.preventDefault()
                    if (evt.key === 'ArrowDown') {
                        if (this.nextElementSibling) {
                            this.dispatchEvent(new MouseEvent('mouseleave'))
                            this.nextElementSibling.dispatchEvent(new MouseEvent('mouseenter'))
                            // this.nextElementSibling.focus()   
                        }
                    } else if (evt.key === 'ArrowUp') {
                        if (this.previousElementSibling) {
                            this.dispatchEvent(new MouseEvent('mouseleave'))
                            this.previousElementSibling.dispatchEvent(new MouseEvent('mouseenter'))
                            // this.previousElementSibling.focus()   
                        } else {
                            this.dispatchEvent(new MouseEvent('mouseleave'))
                            searchInputKeywordNode.focus()
                        }
                    } else if (evt.key === 'Enter') {
                        suggestionHandler(evt, searchInputKeywordNode)
                    }
                    
                }
                
                suggestionNode.appendChild(item)
            }
        }
    }

    clean() {
        this.restore()
    }
}

Object.assign(SearchInput.prototype, domMixin(), eventMixin(), searchMixin())

SearchInput.id = SEARCH_INPUT_ID

export default SearchInput
