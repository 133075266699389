import barCss from '~css/bar.scss'

const shoppingCartTemp = () => `
<!--購物車區塊-->
<!--加上class"closeBox"關閉購物車內容-->
<div class="${barCss.cart} ${barCss.closeBox}">
    <!--將class"barIcon_cart"換成"barIcon_cart_selected"會變橘色-->
    <a style="cursor:pointer;" class="${barCss.barIcon} ${barCss.barIcon_cart}"></a>
    <!--購物車小紅點-->
    <div class="${barCss.cart_count}" style='display: none'>
        <span></span>
    </div>
    <!--購物車內容 -->
    <div class="${barCss['cart-content']}"></div>
</div>
`

export {
    shoppingCartTemp,
}