const domMixin = {
    findById(anchor, id, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelector(`#${id}${others}`) : null
    },

    findByClassName(anchor, className, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelector(`.${className}${others}`) : null
    },

    findByTag(anchor, tagName, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelector(`${tagName}${others}`) : null
    },

    findAllById(anchor, id, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelectorAll(`#${id}${others}`) : null
    },

    findAllByClassName(anchor, className, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelectorAll(`.${className}${others}`) : null
    },

    findAllByTag(anchor, tagName, others = null) {
        others = others ? others : ''
        return anchor ? anchor.querySelectorAll(`${tagName}${others}`) : null
    }
}

export default () => ({...domMixin})