import Pusher                   from 'pusher-js'
import Base58                   from 'base-58'
import * as Event               from '@/_n11s-v2/events'
import { getParserByProductId } from '@/_n11s-v2/parsers'
import { isNil }                from '@/utils/tools'
const {debug, error, info} = require('@/utils/logger')(__filePath, __fileName, '#77DDFF')

class Notification {
    static EVENT  = Event
    static events = {}

    /**
     * 發送事件
     * @param {string} eventName 
     * @param {object} data 
     */
    static dispatch(eventName, data) {
        const event = this.events[eventName]

        if (event) {
            event.fire(data)
        }
    }

    /**
     * 註冊事件
     * @param {string} eventName 
     * @param {function} callback 
     */
    static on(eventName, callback) {
        let event = this.events[eventName]
        if (!event) {
            event = new Event.NotificationEvent(eventName)
            this.events[eventName] = event
        }

        event.register(callback)
    }

    /**
     * 反註冊事件
     * @param {string} eventName 
     * @param {function} callback 
     */
    static off(eventName, callback) {
        const event = this.events[eventName]

        if (event) {
            event.unregister(callback)

            if (event.callbacks.length === 0) {
                delete this.events[eventName]
            }
        }
    }

    static handler(params) {
        Notification.dispatch(Event.EVENT_CLICK_NOTIFICATION, params)

        if (params.eventType) {
            Notification.dispatch(params.eventType, params)
        }
    }

    /**
     * 解析通知
     * @param {*} notice 通知物件
     * @param {*} noticeProductId 通知的產品代碼
     * @param {*} productId 正在操作此方法的產品代碼
     * @returns 
     */
    static parseNotification(notice, noticeProductId, productId, productHomeMap) {
        const Parser               = getParserByProductId(noticeProductId) // 依照通知的產品代碼來取得解析器類別
        const notificationParser   = new Parser(productId, this.events, productHomeMap)    // new 出此解析器物件
        const noticeBody           = JSON.parse(new TextDecoder("utf-8").decode(Base58.decode(notice.body))) // 將通知的內容解碼(base-58)
        const {content, extension, link, handler} = notificationParser.parse(noticeBody, this.handler) // 從通知的內容中解出 content 和 extension 兩個區塊

        // 通知的 content 或 extension 其後一個為 null (沒此結構) 則直接當做是解析失敗
        if (content == null || extension == null) {
            error('Ignore notice(%s) because parse FAIL! (content or extension is NULL)', notice.id)
            return null
        }

        let groups = null
        if (notice.header.group) {
            groups = [notice.header.group]
        } else if (notice.header.receiversIsMe) {
            // 註：Array.concat() 可以傳入陣列或元素，最終出來的都會是一維陣列
            groups = notice.header.receiversIsMe.reduce((result, { receiver_group }) => receiver_group ? result.concat(receiver_group) : result, [])
        }

        // 為了讓 runtime 與從 api 查回的格式一致，因此不使用駝峰命名法 (排列順序同 n11s_v2-api 中的 send_notice 方法中的一致)
        return {
            id              : noticeBody.id,
            notice_type     : noticeBody.notice_type,
            content         : content,
            extension       : extension,
            is_read         : noticeBody.is_read == null ? false : noticeBody.is_read,
            create_date     : noticeBody.create_date,
            sender          : noticeBody.sender,
            sender_group    : noticeBody.sender_group,
            sender_product  : noticeBody.sender_product,
            receiver_product: notice.header.product,
            receiver_group  : groups,  // 有 string(分群) 或 array<string>(混群) 兩種格式
            notice_no       : noticeBody.notice_no,
            anonymous       : noticeBody.anonymous,
            ori_content     : noticeBody.content,
            link            : link,
            handler         : handler,
            // receiver_groups: groups,
            // receiver_group: notice.header.group,
            // receiver_groups: notice.header.receiversIsMe ? notice.header.receiversIsMe.reduce((result, { group }) => group ? result.concat(group) : result, []) : null,
            // receiver_groups: notice.header.receiversIsMe ? notice.header.receivers.map(receiver=>receiver.receiver_group ) : null,
        }
    }

    static connect({ appKey, cluster, authEndpoint, accessToken, hashPid, productId, products, groupInfoMap }) {
        // 建立 Pusher client
        const pusher = new Pusher(appKey, {
            cluster,
            authEndpoint,
            auth: {
                headers: {
                    'X-Heybar-Token': accessToken,
                },
            },
        })

        const bulletinChannel    = pusher.subscribe('public-bulletin')       // 公告頻道
        const groupNoticeChannel = pusher.subscribe('private-group-notice')  // 群發頻道
        const noticeChannel      = pusher.subscribe(`private-${hashPid}`)    // 個人通知頻道
        const productHomeMap     = {}

        if (products) {
            products.forEach(product => { productHomeMap[product.id] = product.home_link })
        }

        // 連線錯誤時，在 console 印出錯誤 (TODO: 之後看要怎麼處理錯誤再修改)
        pusher.connection.bind('error', err => {
            error('**************************************************')
            if (err.error && err.error.data) {
                error(`Connect N11s occur ERROR! (${err.error.data.code})`)
                error(err.error.data.message)
            } else {
                error('Connect N11s occur ERROR!')
                error(err)
            }
            error('**************************************************')
        })

        // 已成功連結到 pusher 的事件
        pusher.connection.bind('connected', () => {
            info('**************************************************')
            info('Connect to PUSHER success!')
            info('**************************************************')
        })

        /** 
         * 監聽公告頻道
         */ 
         bulletinChannel.bind_global((event, data) => {
            debug('bulletinChannel get event::' + event)
            debug(data)

            // bulletin channel 連結成功
            if (event === 'pusher:subscription_succeeded') {
                debug('=== subscribe Bulletin Channel SUCCESS!! ===')
            
            // 收到來自某產品的公告，解析並發出 ADD BUTTETIN 的事件
            // (n11s_v2-api 是用 receiver_product 當做 event name)
            } else if (products.includes(event)) {
                debug('bulletin channel get notification from %s', event)
                debug(data)
                const bulletin = this.parseNotification(data, event, productId, productHomeMap)
                this.dispatch(Event.EVENT_ADD_BUTTETIN, bulletin)
            }
        })

        /** 
         * 監聽群發頻道
         */ 
         groupNoticeChannel.bind_global((event, data) => {
            debug('groupNotifyChannel get event::' + event)
            debug(data)

            // 群發頻道連結成功
            if (event === 'pusher:subscription_succeeded') {
                debug('=== subscribe Group Notice Channel SUCCESS!! ===')

            // 只關心屬於自己的產品群發來且有註明接收者群的通知
            } else if (products.includes(event) && data.header.receivers) {
                debug('group notify channel get notification')
                debug(data)
                debug('receivers::')
                debug(data.header.receivers)

                //--- 檢查這則群發通知是否屬於自己該接收的
                const groupInfo     = groupInfoMap[event] || null // 取出此通知的接收產品的群組資料
                const receiversIsMe = []     // 把屬於自已的接收者資訊收到此處，之後要往後傳
                let belongMe        = false
                
                
                // 如果此產品沒 group 概念，那接收 receiver == pid 但不管 receiver_group
                if (!groupInfo) {
                    for (const receiver of data.header.receivers) {
                        if (receiver.receiver === hashPid) {
                            belongMe = true
                            receiversIsMe.push(receiver)
                        }
                    }
                
                // 如果沒 groupId 但有 groups，表示是有 group 概念，但是呈現上不分群組
                // 此時要收 receiver_group in groups AND (receiver == pid or receiver == null)
                // 和      receiver == pid AND receiver_group == null
                // 此時除了收 receiver == pid 之外，也要收 receiver_group in groups && recevier == null 的通知
                } else if (isNil(groupInfo.groupId) && groupInfo.groups) {
                    for (const receiver of data.header.receivers) {
                        if ((groupInfo.groupIds.includes(receiver.receiver_group) && (receiver.receiver === hashPid || isNil(receiver.receiver))) ||
                            (receiver.receiver === hashPid && isNil(receiver.receiver_group))) {
                            belongMe = true
                            receiversIsMe.push(receiver)
                        }
                    }

                // 如果 groupId 和 groups 都有值的話，表示有 group 概念且呈現上要分群
                // 此時只接收 receiver_group in groups AND (receiver == pid or receiver == null)
                } else {
                    for (const receiver of data.header.receivers) {
                        if (groupInfo.groupIds.includes(receiver.receiver_group) && (receiver.receiver === hashPid || isNil(receiver.receiver))) {
                            belongMe = true
                            receiversIsMe.push(receiver)
                        }
                    }
                }

                // 這邊濾出群發 receivers 中，屬於自己的 receiver (符合條件的 receiver or group)
                if (belongMe) {
                    debug('receivers is me :: %o', receiversIsMe)
                    data.header.receiversIsMe = receiversIsMe 
                    const notification        = this.parseNotification(data, event, productId, productHomeMap)
                    this.dispatch(Event.EVENT_ADD_NOTIFICATION, notification)
                }
            }
        })

        /**
         * 監聽個人頻道
         */
         noticeChannel.bind_global((event, data) => {
            debug('notifyChannel get event::' + event)
            debug(data)
            if (event === 'pusher:subscription_succeeded') {
                debug('=== subscribe Notification Channel SUCCESS!! ===')

            } else if (products.includes(event)) {
                debug('notify channel get notification')
                debug(data)
                
                const notification = this.parseNotification(data, event, productId, productHomeMap)
                this.dispatch(Event.EVENT_ADD_NOTIFICATION, notification)
            }
        })
    }
    
    /**
     * 連接到 Pusher Server 並設定公/私有頻道監聽
     * 
     * @param {object} param appKey      : Pusher Server 的 appKey
     *                       cluster     : Pusher 參數
     *                       authEndpoint: 因為有私有頻道，所以要提供驗證的 url
     *                       accessToken : Heybar 的 AccessToken
     *                       hashPid     : 目前 User Hash 過的 104 pid
     *                       productId   : 目前所在產品的代碼
     *                       products    : 目前 User 要關注那些產品的通知
     *                       groupId     : 目前所在的群組代碼
     *                       groups      : 目前 User 要關注那些群組的通知
     */
    // static connect2({ appKey, cluster, authEndpoint, accessToken, hashPid, productId, products, groupId, groups }) {
    //     // 若 products 無值，則 productId 為其值
    //     if (!products) {
    //         products = [productId]
    //     // 若 products 中沒有 productId，則將此 ProductId 加入 products
    //     } else if (!products.includes(productId)) {
    //         products.push(productId)
    //     }

    //     // 若有 groupId (表示有群組的操作) 卻無 groups，則 groupId 為其值
    //     if (groupId && !groups) {
    //         groups = [groupId]
    //     // 若 groupId 有值卻不在 groups，則將 groupId 加入
    //     } else if (groupId && groups && !groups.includes(groupId)) {
    //         groups.push(groupId)
    //     }

    //     const pusher = new Pusher(appKey, {
    //         cluster,
    //         authEndpoint,
    //         auth: {
    //             headers: {
    //                 'X-Heybar-Token': accessToken,
    //             },
    //         },
    //     })

    //     const bulletinChannel    = pusher.subscribe('public-bulletin')       // 公告頻道
    //     const groupNoticeChannel = pusher.subscribe('private-group-notice')  // 群發頻道
    //     const noticeChannel      = pusher.subscribe(`private-${hashPid}`)    // 個人通知頻道

    //     // 連線錯誤時，在 console 印出錯誤 (TODO: 之後看要怎麼處理錯誤再修改)
    //     pusher.connection.bind('error', err => {
    //         error('**************************************************')
    //         if (err.error && err.error.data) {
    //             error(`Connect N11s occur ERROR! (${err.error.data.code})`)
    //             error(err.error.data.message)
    //         } else {
    //             error('Connect N11s occur ERROR!')
    //             error(err)
    //         }
    //         console.error('**************************************************')
    //     })

    //     // 已成功連結到 pusher 的事件
    //     pusher.connection.bind('connected', () => {
    //         info('**************************************************')
    //         info('Connect to PUSHER success!')
    //         info('**************************************************')
    //     })

    //     /** 
    //      * 監聽公告頻道
    //      */ 
    //     bulletinChannel.bind_global((event, data) => {
    //         debug('bulletinChannel get event::' + event)
    //         debug(data)

    //         // bulletin channel 連結成功
    //         if (event === 'pusher:subscription_succeeded') {
    //             debug('=== subscribe Bulletin Channel SUCCESS!! ===')
            
    //         // 收到來自某產品的公告，解析並發出 ADD BUTTETIN 的事件
    //         // (n11s_v2-api 是用 receiver_product 當做 event name)
    //         } else if (products.includes(event)) {
    //             debug('bulletin channel get notification from %s', event)
    //             debug(data)
    //             const bulletin = this.parseNotification(data, event, productId)
    //             this.dispatch(Event.EVENT_ADD_BUTTETIN, bulletin)
    //         }
    //     })

    //     /** 
    //      * 監聽群發頻道
    //      */ 
    //     groupNoticeChannel.bind_global((event, data) => {
    //         debug('groupNotifyChannel get event::' + event)
    //         debug(data)

    //         // 群發頻道連結成功
    //         if (event === 'pusher:subscription_succeeded') {
    //             debug('=== subscribe Group Notice Channel SUCCESS!! ===')

    //         // 只關心屬於自己的產品群發來且有註明接收者群的通知
    //         } else if (products.includes(event) && data.header.receivers) {
    //             debug('group notify channel get notification')
    //             debug(data)
    //             debug('receivers::')
    //             debug(data.header.receivers)

    //             //--- 檢查這則群發通知是否屬於自己該接收的
    //             const receiversIsMe = [] // 把屬於自已的接收者資訊收到此處，之後要往後傳
    //             let belongMe = false
    //             // 如果此產品沒 group 概念，那接收 receiver == pid 但不管 receiver_group
    //             if (!groupId && !groups) {
    //                 for (const receiver of data.header.receivers) {
    //                     if (receiver.receiver === hashPid) {
    //                         belongMe = true
    //                         receiversIsMe.push(receiver)
    //                         // break
    //                     }
    //                 }
                
    //             // 如果沒 groupId 但有 groups，表示是有 group 概念，但是呈現上不分群組
    //             // 此時除了收 receiver == pid 之外，也要收 receiver_group in groups && recevier == null 的通知
    //             } else if (!groupId && groups) {
    //                 for (const receiver of data.header.receivers) {
    //                     if (receiver.receiver === hashPid || (receiver.receiver == null && groups.includes(receiver.receiver_group))) {
    //                         belongMe = true
    //                         receiversIsMe.push(receiver)
    //                         // break
    //                     }
    //                 }

    //             // 如果 groupId 和 groups 都有值的話(前面的程式已排除掉 groupId 有值但 groups == null 的情況)，表示有 group 概念且呈現上要分群
    //             // 此時只接收 receiver_group in groups
    //             } else {
    //                 for (const receiver of data.header.receivers) {
    //                     if ((receiver.receiver === hashPid || receiver.receiver == null) && groups.includes(receiver.receiver_group)) {
    //                         belongMe = true
    //                         receiversIsMe.push(receiver)
    //                         // break
    //                     }
    //                 }
    //             }

    //             if (belongMe) {
    //                 debug('receivers is me :: %o', receiversIsMe)
    //                 data.header.receiversIsMe = receiversIsMe // 這邊濾出群發 receivers 中，屬於自己的 receiver (符合條件的 receiver or group)
    //                 const notification = this.parseNotification(data, event, productId)
    //                 this.dispatch(Event.EVENT_ADD_NOTIFICATION, notification)
    //             }
    //         }
    //     })

    //     /**
    //      * 監聽個人頻道
    //      */
    //     noticeChannel.bind_global((event, data) => {
    //         debug('notifyChannel get event::' + event)
    //         debug(data)
    //         if (event === 'pusher:subscription_succeeded') {
    //             debug('=== subscribe Notification Channel SUCCESS!! ===')

    //         } else if (products.includes(event)) {
    //             debug('notify channel get notification')
    //             debug(data)
                
    //             const notification = this.parseNotification(data, event, productId)
    //             this.dispatch(Event.EVENT_ADD_NOTIFICATION, notification)
    //         }
    //     })
    // }

    static cleanEvents() {
        this.events = {}
    }
}

export default Notification