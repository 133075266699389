const stringToDom = domString => {
    const tempNode = document.createElement('div')
    tempNode.innerHTML = domString

    const fragment = document.createDocumentFragment()

    while (tempNode.childNodes.length > 0) fragment.append(tempNode.childNodes[0])

    return fragment
}

const diffNowDesc = date => {
    if (typeof(date) === 'string') {
        date = new Date(date)
    }

    if (!isNaN(date)) {
        const now = new Date()
        let diff = (now.getTime() - date.getTime()) / 1000 // 毫秒不計
        diff = diff >= 0 ? diff : 0

        if (diff / 60 < 60) {
            return `${Math.floor(diff/60)}分鐘前`

        } else if (diff / 3600 < 24) {
            return `${Math.floor(diff/3600)}小時前`
        
        } else if (diff / 86400 < 30) {
            return `${Math.floor(diff/86400)}天前`
        
        } else if (diff / 86400 < 365) {
            return `${Math.floor(diff/2592000)}個月前`
        
        } else {
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
            const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            return `${date.getFullYear()}.${month}.${day}`
        }
    } else {
        return ''
    }
}

/** Checks if `value` is `null` or `undefined`. */
const isNil = value => value == null
/** Checks if `value` is `null` or `undefined` or `length === 0` */
const isEmpty = value => isNil(value) ? true : value.length === 0

const  debounce = function (func, delay=250) {
    let timer = null;
   
    return () => {
        let context = this;
        let args = arguments;
   
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(context, args);
        }, delay)
    }
}

const transProductId = productId => {
    switch (productId) {
        case 'cms':
            return 'blog'
        case 'giverfarm':
            return 'beagiver'
    }

    return productId
}

const copyAttributes = (source, destination) => {
    for (let i = 0; i < source.attributes.length; i++) {
        var a = source.attributes[i];
        destination.setAttribute(a.name, a.value);
    }
}

export {
    stringToDom,
    diffNowDesc,
    isNil,
    isEmpty,
    debounce,
    transProductId,
    copyAttributes,
}