import { applyToken, getProductsUnreadAmount, getNotifications, setAsReadByDate, getGroupInfo } from '@/api'
import { getParserByProductId }                                                                 from '@/_n11s-v2/parsers'
import Notification                                                                             from '@/n11s'
import { EVENT_ADD_BUTTETIN, EVENT_ADD_NOTIFICATION }                                           from '@/_n11s-v2/events'
import { isNil, isEmpty }                                                                       from '@/utils/tools'
import Notify                                                                                   from '@/components/notify'
import { UseN11s }                                                                              from '@/constants/heybar-config'

const { debug, info, error } = require('@/utils/logger')(__filePath, __fileName, '#ff00e6')

const GROUP_TYPE_GROUP  = 'group'
const GROUP_TYPE_MIX    = 'mix'
const PRODUCT_KEY_TOKEN = '::'
const NOTIFY_TAB_NOTICE = Notify.NOTIFY_TAB_NOTICE
const NOTIFY_TAB_GROUP  = Notify.NOTIFY_TAB_GROUP
const NOTIFY_TAB_OTHER  = Notify.NOTIFY_TAB_OTHER


class NotificationHelper {
    /**
     * 
     * @param {Notify} notify Notify 物件
     * @param {Object} conf {
     *     pid (hashPid),   // {string} 104 user 代碼
     *     productId,       // {string} 產品代碼
     *     products,        // {Array<Object>} user 所啟用的所有產品 (含代碼和名稱)
     *     groupId,         // {string} 群組代碼
     *     groups,          // {Array<Object>} user 在 productId 下的所有群組 (含代碼和名稱)
     *     groupType,       // {string} 群組類型。group: 分群, mix: 不分群
     *     limitDate,       // {Date} 從 limitDate 開始查詢通知,
     *     defaultHeadshot, // {str} 預設大頭照路徑
     *     crossProducts,   // {Boolean} 是否顯示其他產品的通知
     *     useN11s,         // {int} 產品使用通知的類型 (0:不啟用/1:啟用/2:只啟用當前產品/3:只啟用其他產品)。
     * }
     */
    constructor(notify, conf) {
        debug('notify help config is %o', conf)

        this.notify = notify
        this.conf   = conf || {}

        // 將 conf 內的參數都轉成 this 的變數
        for (const k in this.conf) {
            this[k] = this.conf[k]
        }
        this.groupIds = null // this.groups 取出 group id 組成的陣列
        // 設定已讀 method 的 temp，由於要 bind this，為了在 off 的時候能指到同一個 method，所以將 bind this 的 method 存在此
        // this.__enableSetAsReadTimerTemp         = this.__enableSetAsReadTimer.bind(this)
        // this.__beforeTabCangeHandlerTemp        = this.__beforeTabCangeHandler.bind(this)
        this.__setAsReadHandlerTemp             = this.__setAsReadHandler.bind(this)
        this.__enableRefreshNoticeTimeTimerTemp = this.__enableRefreshNoticeTimeTimer.bind(this) // 啟動 "更新通知時間" 的 timer
        this.__refreshNoticeTimeTemp            = this.notify.refreshNoticeTime.bind(this.notify)

        this.noticePage       = this.__newPageInfo()  // 通知 tab 分頁資訊
        this.groupPage        = this.__newPageInfo()  // 當前產品但非當前群組 tab 的分頁資訊
        this.otherPage        = this.__newPageInfo()  // 其他產品 tab 的分頁資訊

        this.currentProduct   = null // 當前產品的資訊。結構為：{id, name, home_link, product_id(值同id), all_n11s_url, n11s_size, all_bulletins_url, bulletins_size, n11s_group_url}
        this.otherProducts    = []   // 所有非當前產品的資訊。陣列內結構同 currentProduct
        this.useGroupProducts = []   // 有使用群組的產品(不包含當前產品)。陣列內結構同 currentProduct
        this.currentGroup     = null // 當前產品下的當前群組。結構為 {id, name}
        this.otherGroups      = []   // 當前產品下的其他群組。陣列內結構同 currentGroup
        this.productKey       = null // 設定當前產品+群組的 key，主要用在 unreadAmountMap 上，在 __initiateAction() 初始化
        this.groupInfoMap     = {}   // 所有產品(包含當前)的群組資訊(只存有群組的產品)，結構為 {productId: { groupType, groups: [{id, name}], groupId, groupIds }
        this.productsMap      = {}   // 把 this.products 另存 map 形式的結構，方便存取內容
        this.productGroup  = {
            'current': null,    // 當下產品api查詢資訊，結構為：{product_id, group_typ, groups}
            'otherGroup': null, // 當下產品若有群組且型態為 GROUP，則此處存放當下產品其他群組的資訊，結構同 current，但 groups 中不會有當下群組的id
            'others': []        // 其他產品的資訊，其陣列內的結構同 current
        }
        this.unreadAmountMap = {}
        this.unreadAmountMap[NOTIFY_TAB_NOTICE] = 0
        this.unreadAmountMap[NOTIFY_TAB_GROUP]  = 0
        this.unreadAmountMap[NOTIFY_TAB_OTHER]  = 0
        
        this.setAsReadFlags = {}      // 執行"設定已讀"的旗標，為 true 才可進行
        this.setAsReadFlags[NOTIFY_TAB_NOTICE] = true
        this.setAsReadFlags[NOTIFY_TAB_GROUP]  = true
        this.setAsReadFlags[NOTIFY_TAB_OTHER]  = true
        
        for (const product of this.products) {
            this.productsMap[product.id] = product

            if (product.id === this.productId) {
                this.currentProduct = product
            } else {
                this.otherProducts.push(product)
                if (product.n11s_group_url) {
                    this.useGroupProducts.push(product)
                }
            }
        }
        
        this.notify.productsMap = this.productsMap
        this.notify.unreadAmountMap = this.unreadAmountMap
        

        // 當 myProductSettings 為 null 時，表示抓不到此產品的設定，發出 error 就好，為了讓後續程式能運作，將其設為空物件
        if (!this.currentProduct) {
            error(`*** ERROR *** Can not found ${this.productId}'s product settings.`)
            this.currentProduct = {id: this.productId, productId: this.productId}
        }

        this.setAsReadTimer          = {} // 設定為已讀的定時器
        this.refreshNoticeTimeTimer  = null // 更新通知/公告時間的定時器
        
    }

    //=============================================================================================================
    // 初始化區
    //=============================================================================================================
    /**
     * 設定 notice 與 bulletin 的讀取下一頁
     */
     __setQueryNext() {
        const noticeObserver = new IntersectionObserver(this.__drawNextPage.bind(this))
        noticeObserver.observe(this.notify.getObserverNode(NOTIFY_TAB_NOTICE))
        noticeObserver.observe(this.notify.getObserverNode(NOTIFY_TAB_GROUP))
        noticeObserver.observe(this.notify.getObserverNode(NOTIFY_TAB_OTHER))
    }
    
    /**
     * 執行初始化
     */
    __initiateAction(resolve, reject) {        
        // 若當前產品有群組且為分群時，分出當前群組與其他群組
        if (this.groupType === GROUP_TYPE_GROUP) {
            // 分出當前 group 和其他 group
            // 由於已經在 initiate() 中檢查 groupId != nil 與確保 groups 中一定存在 groupId 的資訊，因此 this.currentGroup 一定會有值
            for (const group of this.groups) {
                if (group.id === this.groupId) {
                    this.currentGroup = group
                } else {
                    this.otherGroups.push(group)
                }
            }
        }

        // 將 productId 與 group 參數回填到 notify 物件
        this.notify.productId = this.productId
        this.notify.groups    = this.groups
        if (this.currentGroup) {
            this.notify.groupId   = this.currentGroup.id
            this.notify.groupName = this.currentGroup.name
        }

        // 設定當前產品+群組的 key，可用在 unreadAmountMap 上
        this.productKey = this.__getProductKey(this.productId, this.currentGroup ? this.currentGroup.id : null)

        // 填完 this.productGroup 參數，用在之後取得未讀數和通知/公告的 api 參數上
        this.productGroup.current = {product_id: this.productId}
        if (this.groupType) {
            this.productGroup.current['group_type'] = this.groupType
            this.productGroup.current['groups'] = this.groupType === GROUP_TYPE_GROUP ? [this.groupId] : this.groups.map(g => g.id)
        }
        
        // if (this.otherGroups.length > 0) {
        if (this.otherGroups.length > 0 && this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS) {
            this.notify.showTab(NOTIFY_TAB_GROUP)
            this.productGroup.otherGroup = {product_id: this.productId}
            this.productGroup.otherGroup['group_type'] = this.groupType
            this.productGroup.otherGroup['groups'] = this.otherGroups.map(og => og.id)
        }

        if (this.otherProducts.length > 0) {
            // (2023-08-14 加) 如果 useN11s 是 ONLY_SELF 的話，那就不顯示其他產品的 Tab 了
            if (this.useN11s !== UseN11s.ENABLE_ONLY_SELF) {
                this.notify.showTab(NOTIFY_TAB_OTHER)
                for (const product of this.otherProducts) {
                    const productGroup = {product_id: product.id}
                    const groupInfo    = this.groupInfoMap[product.id]
                    if (groupInfo) {
                        productGroup['group_type'] = groupInfo.groupType
                        productGroup['groups']     = groupInfo.groupIds
                    }
                    this.productGroup.others.push(productGroup)
                }
            }
        // (2023-08-14 加) 若沒有其他產品但 useN11s 是 ONLY_OTHERS，則仍要秀出其他產品的 tab，但要開啟無資料的 item
        } else if (this.useN11s === UseN11s.ENABLE_ONLY_OTHERS) {
            this.notify.showTab(NOTIFY_TAB_OTHER)
            this.notify.showNodData(NOTIFY_TAB_OTHER, true)
            this.notify.showLoading(NOTIFY_TAB_OTHER, false)
        }

         // (2023-08-14 加) 
        if (this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS) {
            this.notify.showTab(NOTIFY_TAB_NOTICE)
            this.notify.setActiveTab(NOTIFY_TAB_NOTICE)
        } else {
            this.notify.showTab(NOTIFY_TAB_OTHER)
            this.notify.setActiveTab(NOTIFY_TAB_OTHER)
            this.notify.hideTabArea()
        }

        // 抓取未讀數
        //-- (2023-08-14 刪) let queryProducts = this.productGroup.others.concat([this.productGroup.current])
        //-- (2023-08-14 刪) if (this.productGroup.otherGroup) queryProducts = queryProducts.concat([this.productGroup.otherGroup])
        //-- (2023-08-14 刪) const unreadAmountPromise = getProductsUnreadAmount(queryProducts, 'mix')
        
        //-- (2023-08-14 加) 抓取未讀數，加入 useN11s 模式的判斷，若是 ONLY_OTHERS 就不用查自己產品的未讀數了
        const queryProducts = [].concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS ? [this.productGroup.current] : []
        ).concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS && this.productGroup.otherGroup ? [this.productGroup.otherGroup] : []
        ).concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_SELF ? this.productGroup.others : []
        )
        //-- (2023-08-14 加) 讀取未讀數的 Promise，若沒有產品/群組要查詢，則回一個 Promise.resolve
        const unreadAmountPromise = queryProducts.length > 0 ? getProductsUnreadAmount(queryProducts, 'mix') : Promise.resolve({data:{}})

        // 設定讀取下一頁的監視事件
        this.__setQueryNext()

        // 讀取通知
        //-- (2023-08-14 刪) const noticesPromises = [this.__drawList(NOTIFY_TAB_NOTICE, [this.productGroup.current])]
        //-- (2023-08-14 刪) if (this.productGroup.otherGroup) noticesPromises.push(this.__drawList(NOTIFY_TAB_GROUP, [this.productGroup.otherGroup]))
        //-- (2023-08-14 刪) if (this.productGroup.others && this.productGroup.others.length > 0) {
        //-- (2023-08-14 刪)     noticesPromises.push(this.__drawList(NOTIFY_TAB_OTHER, this.productGroup.others))
        //-- (2023-08-14 刪) }
        //-- (2023-08-14 加) 讀取通知，加入 useN11s 模式的判斷，若是 ONLY_OTHERS 就不用讀取自己產品的通知了
        const noticesPromises = [].concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS ? [this.__drawList(NOTIFY_TAB_NOTICE, [this.productGroup.current])] : []
        ).concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS && this.productGroup.otherGroup ? [this.__drawList(NOTIFY_TAB_GROUP, [this.productGroup.otherGroup])] : []
        ).concat(
            this.useN11s !== UseN11s.ENABLE_ONLY_SELF && this.productGroup.others && this.productGroup.others.length > 0 ? [this.__drawList(NOTIFY_TAB_OTHER, this.productGroup.others)] : []
        )

        // 抓未讀與畫出通知要全搞定才能算初始完成 
        const initDonePromises = []
        initDonePromises.push(
            unreadAmountPromise.then(result => {
                debug('get unread amount result:: %o', result)
                if (result.data) this.__setUnreadAmount(result.data)
            })
        )

        //-- (2023-08-14 加) 若沒有要讀取與繪製通知的情況(useN11s 為 ONLY_OTHERS 且又沒加入其他產品)，那麼就不用綁 pusher 了
        if (noticesPromises.length > 0) {
            // 通知/公告都載完了才開始綁 pusher
            initDonePromises.push(
                Promise.all(noticesPromises).then(() => {
                    Notification.on(EVENT_ADD_BUTTETIN    , this.__runtimeAddBulletin.bind(this))
                    Notification.on(EVENT_ADD_NOTIFICATION, this.__runtimeAddNotification.bind(this))
        
                    applyToken().then(token => {
                        // 接上 pusher
                        // 因為會用到 groupInfoMap，代表要在 drawItems(裏面有抓各產品的 group info)確實結束(含畫群組)才能執行此連結
                    
                        Notification.connect({
                            appKey      : process.env.PUSHER_APP_KEY,
                            cluster     : process.env.PUSHER_CLUSTER,
                            authEndpoint: process.env.PUSHER_AUTH_ENDPOINT,
                            accessToken : token,
                            hashPid     : this.pid,
                            productId   : this.productId,
                            products    : [...this.products.map(p=>p.id)],
                            groupInfoMap: this.groupInfoMap
                        })
        
                        
                        this.notify.on('open', this.__refreshNoticeTimeTemp)
                        this.notify.on('open', this.__enableRefreshNoticeTimeTimerTemp)
                        this.notify.on('open', this.__setAsReadHandlerTemp)
                        this.notify.on('tabChange', this.__setAsReadHandlerTemp)
        
                        // 有可能 notify 還在初始化時 user 就已經點開畫面了，由於 on(open) 已經改成放在 notify 初始結束後才加上(避免 loading 畫面還沒關掉通知就被設為已讀)，
                        // 因此在已開啟通知的前提下，在初始完成後要補跑一次上面 on(open) 的相關 function
                        if (this.notify.isOpen) {
                            // this.__enableSetAsReadTimer()
                            this.__refreshNoticeTime()
                            this.__enableRefreshNoticeTimeTimer()
                        }
        
                    }).catch(err => {
                        error('initial notification helper fail. get access token occur error! %o', err)
                        throw err
                    })
                }).catch(err => {
                    error('initial notification helper fail. draw notice and bulletin list occur error! %o', err)
                    throw err
                })
            )
        }

        // 上面全部的非同步都做完了，才能回 resolve()
        Promise.all(initDonePromises).then(() => {resolve()}).catch(err => {reject(err)})
    }

     /**
     * 執行 NotificationHelper 的初始化
     * 
     * 這個 function 主要是用來將初始時要準備好的所有需要查回 Group Info 的全部查回來。
     * 而初始化主要的邏輯則是放在 this.__initiateAction() 內
     */
    initiate() {
        return new Promise((resolve, reject) => {
            let isCurrentProductNeedFetchGroupInfo = false // 當前產品是否需要去 api 抓取 group 資訊
            
            // 先進行 call api 查詢 group info (不包含當前產品)
            const getGroupInfoPromiseList = []
            for (const product of this.useGroupProducts) {
                getGroupInfoPromiseList.push(getGroupInfo(product.id, product.n11s_group_url, product.n11s_group_url_type))
            }

            // 如果 useN11s 的模式不是"使用通知但只限其他產品"的話，那就要再進一步看一下自己產品是否需要去 api 抓 group 資訊 (2023-08-14)
            if (this.useN11s !== UseN11s.ENABLE_ONLY_OTHERS) {
                // 如果 currentProduct 中的 n11s_group_url 有值則表示此產品有使用群組，而若其群組沒有從 "config" 傳進來，那就自己去產品提供的 api 抓
                if (this.currentProduct.n11s_group_url && (!this.conf.groups || this.conf.groups.length === 0)) {
                    getGroupInfoPromiseList.push(getGroupInfo(this.productId, this.currentProduct.n11s_group_url, this.currentProduct.n11s_group_url_type))
                    isCurrentProductNeedFetchGroupInfo = true
                }
            }

            debug('need to fetch Group Info number is %o', getGroupInfoPromiseList.length)
            // 有需要查 group info 的話，要先查完再進初始設定
            if (getGroupInfoPromiseList.length > 0) {
                Promise.all(getGroupInfoPromiseList).then(infoList => {
                    debug('Fetch Group Info List:: %o', infoList)
                    for (const info of infoList) {
                        if (info.data) {
                            // group 資訊一定要有 groups 和 groupType
                            if (info.data.groups && info.data.groupType) {
                                // 將產品的群組資訊寫到 groupInfoMap 中以利之後查詢
                                this.groupInfoMap[info.productId] = info.data
                                // 檢查與補完 groups
                                this.groupInfoMap[info.productId]['groups'] = this.__checkAndfix4TypeGroup(info.data.groupType, info.data.groupId, info.data.groups, info.productId == this.currentProduct.id, `${info.productId}' group typ is "GROUP", but it's groupId is NIL.`)
                                // 為了查詢通知/群發通知 API 方便，將 groups 多轉出 groupIds 只存放 groupId
                                const groupIds = info.data.groups.map(g => g.id)
                                this.groupInfoMap[info.productId]['groupIds'] = groupIds

                                // 如果是當前的產品，則代表當前產品的群組是從 api 查，將結果填回相關變數
                                if (info.productId == this.currentProduct.id) {
                                    this.groups    = info.data.groups
                                    this.groupId   = info.data.groupId
                                    this.groupType = info.data.groupType || GROUP_TYPE_MIX
                                    this.groups    = this.groupInfoMap[info.productId]['groups']
                                    this.groupIds  = this.groupInfoMap[info.productId]['groupIds']
                                    // this.groups    = this.__checkAndfix4TypeGroup(this.groupType, this.groupId, this.groups, true, 'initiate HeyBar FAIL!! groupId is required when this product group type is "GROUP" (FROM API)')
                                    // this.groupIds  = this.groups.map(g => g.id)

                                    //-- 註：回填 notify 的動作移到 __initiateAction() 執行 --//
                                }

                            }  else {
                                error('invalid group info, groups or groupType not exists ERROR!! %o', info.data)
                                if (info.productId == this.currentProduct.id) {
                                    throw Error('initialte HeyBar FAIL!! invalid group info from API.')
                                }
                            }

                        } else {
                            error('Get group info from API orccur ERROR!!L!! %o', info.data)
                            // 沒抓到 group info 時，若當前產品也需要抓 group info 的話，則報錯不往下畫了。
                            if (isCurrentProductNeedFetchGroupInfo) {
                                throw Error("initiate HeyBar FAIL!! Can not fetch current product's group info.")
                            }
                        }
                    }

                    if (!isCurrentProductNeedFetchGroupInfo && this.groupType) {
                        this.groups = this.__checkAndfix4TypeGroup(this.groupType, this.groupId, this.groups, true, 'initiate HeyBar FAIL!! groupId is required when this product group type is "GROUP"')
                        this.groupIds = this.groups.map(g => g.id)

                        this.groupInfoMap[this.productId] = {
                            groupType: this.groupType,
                            groups   : this.groups,
                            groupId  : this.groupId,
                            groupIds : this.groupIds,
                        }

                    }
                    this.__initiateAction(resolve, reject)
                })

            // 若完全沒有要查 group info 就直接進初始設定
            } else {
                if (this.groupType) {
                    this.groups = this.__checkAndfix4TypeGroup(this.groupType, this.groupId, this.groups, true, 'initiate HeyBar FAIL!! groupId is required when this product group type is "GROUP"')
                    this.groupIds = this.groups.map(g => g.id)

                    this.groupInfoMap[this.productId] = {
                        groupType: this.groupType,
                        groups   : this.groups,
                        groupId  : this.groupId,
                        groupIds : this.groupIds,
                    }
                }

                this.__initiateAction(resolve, reject)
            }
        })
    }

    destroy() {
        this.notify.off('open', this.__refreshNoticeTimeTemp)
        this.notify.off('open', this.__enableRefreshNoticeTimeTimerTemp)
        this.notify.off('open', this.__setAsReadHandlerTemp)
        this.notify.off('tabChange', this.__setAsReadHandlerTemp)

        // Notification.cleanEvents()
    }

    //=============================================================================================================
    // 運作區
    //=============================================================================================================
    /**
     * 讀取通知/公告，並畫出第一層或第三層的通知/公告項目
     * 
     * @returns 
     */
     __drawList(tab, products) {
        return new Promise((resolve, reject) => {
            // 顯示 loading 圖示
            this.notify.showLoading(tab, true)
            // 關閉無資料 item
            this.notify.showNodData(tab, false)
            // 取得分頁記錄物件()
            const pageInfo = this[`${tab}Page`]
            
            
            // 設定查詢參數
            const params = {
                start    : pageInfo.start,
                size     : pageInfo.size,
                queryType: tab !== NOTIFY_TAB_GROUP ? 'mix' : 'notice',
                products,
            }

            getNotifications(params).then(result => {
                debug('--%slist --', tab)
                debug(result)
                
                // 如果有查詢到資料
                if (result && result.data && result.data.result) {
                    // 依產品代碼取出該產品所使用的 notice parser
                    const Parser = getParserByProductId(this.productId)
                    const parser = new Parser(this.productId, Notification.events, this.productHomeMap)
                    let appender = null

                    pageInfo.hasNext = !!result.data.has_next
                    pageInfo.next    = result.data.next
                    
                    
                    // 確定沒抓到資料要秀無資料
                    if (result.data.count === 0) this.notify.showNodData(tab, true)

                    for (const noticeData of result.data.result) {
                        const {content, extension, link, handler} = parser.parse(noticeData, Notification.handler)
                        this.notify.appendNotice(tab, noticeData, content, extension, link, handler)
                    }
                } else if (pageInfo.hasNext === null) {
                    // 開啟無資料 item
                    this.notify.showNodData(tab, true)
                }

                // 關閉 loading 圖示
                this.notify.showLoading(tab, false)

                resolve()

            }).catch(err => {
                // 關閉 loading 圖示
                this.notify.showLoading(tab, false)
                reject(err)
            })
        })
    }

    /**
     * 畫出下一頁的通知/公告
     * 此為 IntersectionObserver 的 handler function
     * 
     */
     __drawNextPage(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                console.log('--observer-------:::', this.notify.activeTab)
                const tab = this.notify.activeTab
                const noticePage = this[`${tab}Page`]
                
                if (noticePage.hasNext && noticePage.next !== null) {
                    noticePage.start   = noticePage.next
                    noticePage.hasNext = false
                    noticePage.next    = null

                    let products = []
                    switch(tab) {
                        case NOTIFY_TAB_NOTICE:
                            products = [this.productGroup.current]
                            break
                        case NOTIFY_TAB_GROUP:
                            products = [this.productGroup.otherGroup]
                            break
                        case NOTIFY_TAB_OTHER:
                            products = this.productGroup.others
                    }

                    this.__drawList(tab, products).then(() => {
                        // 每次讀取下一頁完成後觸發設定已讀計時器，來避免還來不及設定已讀就捲動到下一頁，而下一頁有未讀通知時，會有畫面未清掉未讀的問題
                        // this.__enableSetAsReadTimer()
                    })
                }
            }
        })
    }

    __setUnreadAmount(key, value) {
        // 表示輸入的是 key-value
        if (typeof(key) === 'string' && !isNil(value)) {    
            if (key === NOTIFY_TAB_NOTICE || key === NOTIFY_TAB_GROUP || key === NOTIFY_TAB_OTHER) {
                this.unreadAmountMap[key] = value
            } else {
                error(`set unread amount FAIL. key(${key}) is invalid`)
            }
            
        // 表示輸入的是一個 object
        } else if (typeof(key) === 'object') {
            this.unreadAmountMap[NOTIFY_TAB_NOTICE] = 0
            this.unreadAmountMap[NOTIFY_TAB_GROUP]  = 0
            this.unreadAmountMap[NOTIFY_TAB_OTHER]  = 0
            for (const k in key) {
                const tab = this.__whichTab(k)
                if (tab) {
                    this.unreadAmountMap[tab] += key[k]
                }
            }
        }

        // 設定 tab 的未讀紅泡
        this.notify.showTabNewBadge(NOTIFY_TAB_NOTICE, this.unreadAmountMap[NOTIFY_TAB_NOTICE] !== 0)
        this.notify.showTabNewBadge(NOTIFY_TAB_GROUP , this.unreadAmountMap[NOTIFY_TAB_GROUP] !== 0)
        this.notify.showTabNewBadge(NOTIFY_TAB_OTHER , this.unreadAmountMap[NOTIFY_TAB_OTHER] !== 0)

        // 設定通知鈴噹上的未讀紅泡
        if (this.unreadAmountMap[NOTIFY_TAB_NOTICE] + 
            this.unreadAmountMap[NOTIFY_TAB_GROUP]  +
            this.unreadAmountMap[NOTIFY_TAB_OTHER] === 0) {
            this.notify.showSwitchNewBadge(false)
        } else {
            this.notify.showSwitchNewBadge(true)
        }
    }

    /**
     * 加入指定 tab 的未讀數量
     * @param {*} tab 
     * @param {*} value 
     */
    __addUnreadAmount(tab, value) {
        if (tab === NOTIFY_TAB_NOTICE || tab === NOTIFY_TAB_GROUP || tab === NOTIFY_TAB_OTHER) {
            this.unreadAmountMap[tab] += value

            if (value > 0) {
                this.notify.showTabNewBadge(tab, true)
                this.notify.showSwitchNewBadge(true)
            }
        } else {
            error(`add unread amount FAIL. tab(${tab}) is invalid`)
        }
    }

    /**
     * 設定成已讀
     */
     __setAsRead(tab, products) {
        // 2022-05-03 改成在到後端設成已讀前，就要將紅點消除
        const realUnreadAmount = this.unreadAmountMap[tab]
        this.__setUnreadAmount(tab, 0)
        return new Promise((resolve, reject) => {
            if (tab !== NOTIFY_TAB_NOTICE && tab !== NOTIFY_TAB_GROUP && tab !== NOTIFY_TAB_OTHER) {
                error(`set as read FAIL. tab(${tab}) is invalid`)
                resolve()
            } else {
                // 如果未讀數量大於 0 則進行設定已讀
                // 2022-05-03 改成在發設定已讀前就將數量清空，因此原本在此讀取當下是否有未讀的變數就沒效了(因為在呼叫設定已讀前就被清成 0 了)
                // 因此要在清成 0 之前把數量記下來，然後再改判斷這個記下來的變數
                // if (this.unreadAmountMap[tab] > 0) {
                if (realUnreadAmount > 0) {
                    setAsReadByDate({
                        queryType: tab !== NOTIFY_TAB_GROUP ? 'mix' : 'notice',
                        products
                    }).then(result => {
                        // 2022-05-03 改成在到後端設成已讀前，就要將紅點消除
                        // this.__setUnreadAmount(tab, 0)
                        // 設成已讀後，各個通知的未讀圖示在此版本中先不清理，直到頁面 reload
                        // this.notify.cleanAllNoticeItemNewBadge(layer, noticeType)
                        
                        const totalUnreadAmount = (this.unreadAmountMap[NOTIFY_TAB_NOTICE] || 0) + (this.unreadAmountMap[NOTIFY_TAB_GROUP] || 0) + (this.unreadAmountMap[NOTIFY_TAB_OTHER] || 0)
                        debug('-->totalUnreadAmount:', totalUnreadAmount)

                        if (totalUnreadAmount === 0) Notification.dispatch(Notification.EVENT.EVENT_NO_UNREAD_ALL)
                        
                        // 如果是當前產品/群組的"通知"被設已讀，則當其未讀數為 0 發事件出來
                        // 如果當前產品/群組的"通知"或"公告"被設已讀，則加總其通知與公告的未讀數，若為 0 則發事件
                        // (因為此版本通知與公告放同一個 tab，因此當 notice tab 被設為已讀時，則同時發出上述兩個事件)
                        if (tab === NOTIFY_TAB_NOTICE && (this.unreadAmountMap[tab] || 0) === 0) {
                            Notification.dispatch(Notification.EVENT.EVENT_NO_NOTIFICATION_UNREAD_THIS)
                            Notification.dispatch(Notification.EVENT.EVENT_NO_UNREAD_THIS)
                        }

                        resolve()
                    }).catch (err => {
                        error('call api to set as read FAIL!! %o', err)
                        reject(err)
                    })
                } else {
                    resolve()
                }
            }
        })
    }

    /**
     * 執行"設定已讀"
     */
    __setAsReadHandler() {
        const activeTab = this.notify.activeTab
        debug('------- set as read tab is "%s" ---------', activeTab)
        debug('------- set as read FLAG is "%s" ---------', this.setAsReadFlags[activeTab])
        
        if (this.notify.activeTab && this.setAsReadFlags[activeTab]) {
            let products = null
            switch (activeTab) {
                case NOTIFY_TAB_NOTICE:
                    if (this.productGroup['current']) {
                        products = [this.productGroup['current']]
                    }
                    break
                case NOTIFY_TAB_GROUP:
                    if (this.productGroup['otherGroup']) {
                        products = [this.productGroup['otherGroup']]
                    }
                    break
                case NOTIFY_TAB_OTHER:
                    if (this.productGroup['others'] && this.productGroup['others'].length > 0) {
                        products = this.productGroup['others']
                    }
                    break
            }

            if (products && products.length > 0) {
                debug('------ begin to set %s as read --------', activeTab)
                this.setAsReadFlags[activeTab] = false
                this.__setAsRead(activeTab, products).finally(() => {
                    debug('------ end to set %s as read --------', activeTab)
                    this.setAsReadFlags[activeTab] = true
                })
            } else {
                debug('------ set as read fail!! products is empty. %o', products)
            }
        }
    }

    /**
     * 刷新通知裏所有的時間描述
     */
     __refreshNoticeTime() {
        this.notify.refreshNoticeTime()
    }
    /**
     * 啟動定時刷新通知裏所有時間描述的 Timer
     */
     __enableRefreshNoticeTimeTimer() {
        if (this.refreshNoticeTimeTimer) {
            clearTimeout(this.refreshNoticeTimeTimer)
            this.refreshNoticeTimeTimer = null
        }

        this.refreshNoticeTimeTimer = setTimeout(() => {
            if (this.notify.isOpen) {
                this.notify.refreshNoticeTime()
                this.__enableRefreshNoticeTimeTimer()
            }
            
        }, 3000)
    }

    /**
     * 接收從 pusher 來的即時公告(bulletin)，並反應到 notify 畫面上
     * 
     * @param {object} bulletinInfo {
     *     id: {string} 公告編號,
     *     noticeType: {string} 通知類型,
     *     content: {string | object} 公告內容
     *     extension: {object} 公告內容的擴充資訊
     *     is_read: {boolean} 是否已讀
     *     create_date: {string} 公告日期
     *     sender: {string} 發公告者,
     *     sender_group: {string} 發公告者的群組代碼,
     *     sender_product: {string} 發公告者的產品代碼,
     *     receiver_product: {string} 接收公告者的產品代碼,
     *     receiver_group: {array} 接收公告的群組群,
     *     notice_no: {string} 通知編號
     *     anonymous: {boolean} 是否匿名
     *     oriContent: 未進行解析的公告內容
     * }
     */
     __runtimeAddBulletin(bulletinInfo) {
        debug('--- runtime add bulletion ---')
        debug(bulletinInfo)

        const tab = bulletinInfo.receiver_product === this.productId ? NOTIFY_TAB_NOTICE : NOTIFY_TAB_OTHER

        // 如果公告是發給目前登入的產品，那將公告加進列表中
        this.notify.appendNotice(tab, bulletinInfo, bulletinInfo.content, bulletinInfo.extension, bulletinInfo.link, bulletinInfo.handler, 'head')
        // 將 bulletin 的未讀數加 1
        this.__addUnreadAmount(tab, 1)

        // 如果寫入的 tab 是目前已開啟的 tab 且通知視窗是打開的話，則呼叫設定已讀
        if (this.notify.isOpen && tab === this.notify.activeTab) {
            this.__setAsReadHandler()
        }
    }


    /**
     * 接收從 pusher 來的即時通知(notice)，並反應到 notify 畫面上
     * 
     * @param {object} notificationInfo {
     *     id: {string} 通知編號,
     *     noticeType: {string} 通知類型,
     *     content: {string | object} 通知內容
     *     extension: {object} 通知內容的擴充資訊
     *     is_read: {boolean} 是否已讀
     *     create_date: {string} 通知日期
     *     sender: {string} 發通知者,
     *     sender_group: {string} 發通知者的群組代碼,
     *     sender_product: {string} 發通知者的產品代碼,
     *     receiver_product: {string} 接收通知者的產品代碼,
     *     receiver_group: {array} 接收通知的群組群,
     *     notice_no: {string} 通知編號
     *     anonymous: {boolean} 是否匿名
     *     oriContent: 未進行解析的通知內容
     * }
     */
    __runtimeAddNotification(notificationInfo) {
        debug('--- runtime add notification ---')
        debug(notificationInfo)

        
        let tab = null
        //------- 判斷收到的通知要放到那個 Tab 上 (無法用 this.__whichTabById() 因為使用情境不同) -------------//
        const xorGroups = isNil(this.groupIds) || isEmpty(notificationInfo.receiver_group) ? [] : this.groupIds.filter(n => notificationInfo.receiver_group.includes(n))
        // 如果是當前產品
        if (notificationInfo.receiver_product === this.productId) {
            // 1. 當前產品無群組且通知也沒群組
            // 2. 當前產品有群組
            //    2.1 若為混群，則通知沒群組或通知的群組內有"對中"當前產品的群組
            //    2.2 若為分群，則通知要有群組且其中包含有當前產品的當前群組
            // 以上則屬於 NOTICE TAB
            // 2023-01-10 改成跟 n11s-api 對齊，也就是當 groupType 是 null 時，則抓 receiver = pid 而不管其 receiver_group
            // if ((isNil(this.groupType) && isNil(notificationInfo.receiver_group)) || 
            if (isNil(this.groupType) || 
                (!isNil(this.groupType) && (
                    (this.groupType === GROUP_TYPE_MIX && (isEmpty(notificationInfo.receiver_group) || xorGroups.length > 0)) ||
                    (this.groupType === GROUP_TYPE_GROUP && !isEmpty(notificationInfo.receiver_group) && notificationInfo.receiver_group.includes(this.groupId))
                ))) {
                tab = NOTIFY_TAB_NOTICE

            // 若不為上述條件，且當前產品為分群時，則通知有群組，且通知的群組有任一個"對中"此產品下的群組，則屬於 GROUP TAB

            } else if (!isNil(this.groupType) && this.groupType === GROUP_TYPE_GROUP) {
                if (!isEmpty(notificationInfo.receiver_group) && xorGroups.length > 0) {
                    tab = NOTIFY_TAB_GROUP
                }
            }
        // 如果此通知不屬於當前產品，仍要進行以下的判斷
        // 1. 必須是 user 所擁有的產品
        // 2. 通知的產品本身無群組且通知亦無群組
        // 3. 通知的產品本身有群組
        //    3.1. 若為混群，則通知無群組或是通知群組有"對中"該產品的群組
        //    3.2. 若為分群，則通知需要有群組且通知群組有"對中"該產品的群組
        } else if (!isNil(this.productsMap[notificationInfo.receiver_product])) {
            // 取出 productId 的群組資訊(有可能該產品無群組而沒有群組資訊)
            const groupInfo = this.groupInfoMap[notificationInfo.receiver_product]
            const xorGroup2 = isNil(groupInfo) || isNil(isNil(groupInfo.groupIds)) || isEmpty(notificationInfo.receiver_group) ? [] : groupInfo.groupIds.filter(n => notificationInfo.receiver_group.includes(n))

            if ((isNil(groupInfo) && isEmpty(notificationInfo.receiver_group)) ||
                (!isNil(groupInfo) && (
                    (groupInfo.groupType === GROUP_TYPE_MIX && (isEmpty(notificationInfo.receiver_group) || xorGroups2.length > 0)) ||
                    (groupInfo.groupType === GROUP_TYPE_GROUP && !isEmpty(notificationInfo.receiver_group) && xorGroups2.length > 0)
                ))) {
                    tab = NOTIFY_TAB_OTHER
                }
        }


        // 如果此通知對不到該呈現在那個 tab 的話，發出警告並結束
        debug('runtime notifictaion belong TAB %s', tab)
        if (isNil(tab)) {
            info(`Can not found TAB use runtime notification info. %o`, {id: notificationInfo.id, noticeType: notificationInfo.notice_type, product: notificationInfo.receiver_product, groups: notificationInfo.receiver_groups})
        } else {
            this.notify.appendNotice(tab, notificationInfo, notificationInfo.content, notificationInfo.extension, notificationInfo.link, notificationInfo.handler, 'head')
            this.__addUnreadAmount(tab, 1)

            // 如果寫入的 tab 是目前已開啟的 tab 且通知視窗是打開的話，則呼叫設定已讀
            if (this.notify.isOpen && tab === this.notify.activeTab) {
                this.__setAsReadHandler()
            }
        }
    }
    //=============================================================================================================
    // 工具方法區
    //=============================================================================================================
    /** 
     * New 出分頁資訊物件
     */
     __newPageInfo() {
        return {
            start  : 1,
            size   : 10,
            next   : null,
            hasNext: null
        }
    }
    /**
     * 組合出 ProductKey
     * 
     * 規則為有 productId 則 ProductKey = productId
     * 同時有 productId 與 groupId，則 ProductKey = productId::groupId
     */
     __getProductKey(productId, groupId) {
        return isNil(groupId) ? productId : `${productId}${PRODUCT_KEY_TOKEN}${groupId}`
    }

    /**
     * 檢查 groupType == group 時，groupId 是否有值
     * 另外若發現 groups 中沒有 groupId 資訊時，會將其補上
     */
    __checkAndfix4TypeGroup(groupType, groupId, groups, throwOnError, errorMsg) {
        if (groupType === GROUP_TYPE_GROUP) {
            if (isNil(groupId)) {
                const defaultErrMsg = 'invalid GROUP INFO. when group type is "GROUP", group id can not be "NULL"'
                error(errorMsg || defaultErrMsg)
                if (throwOnError) throw new Error(errorMsg || defaultErrMsg)
            } else {
                // 如果 groupId 的資訊不存 groups 內，則填進去
                if (isNil(groups) || groups.length === 0 || groups.filter(g=>g.id == groupId).length === 0) {
                    const group = {'id': groupId, 'name': groupId}
                    if (isNil(groups)) groups = [group]
                    else groups.push(group)
                }
            }
        }

        return groups
    }

    __whichTab(productKey) {
        // 從 productKey 中還原出 productId 與 groupId
        const [productId, ..._gidTmp] = productKey.split(PRODUCT_KEY_TOKEN)
        const groupId = _gidTmp.length > 0 ? _gidTmp.join(PRODUCT_KEY_TOKEN) : null

        return this.__whichTabById(productId, groupId)
    }

    __whichTabById(productId, groupId) {
        // 當前產品
        if (productId === this.productId) {
            // 如果無群組或有群組但為混群時，productKey 內無 groupId 時，則為 NOTIFY_TAB_NOTICE tab
            if ((isNil(this.groupType) || this.groupType === GROUP_TYPE_MIX) && isNil(groupId)) {
                return NOTIFY_TAB_NOTICE
            
            // 如果當前產品是分群
            } else if (this.groupType === GROUP_TYPE_GROUP) {
                // 如果 productKey 中無 groupId (表示是公告) 或是當前群組 === productKey 中的 groupId
                if (isNil(groupId) || this.groupId === groupId) {
                    return NOTIFY_TAB_NOTICE
                // 如果 productKey 中的 groupId 在 this.groupIds 中，表示此群組為當前產品的非當前群組
                } else if (this.groupIds.includes(groupId)) {
                    return NOTIFY_TAB_GROUP
                }
            }
        // 若非當前產品，則亦必須在此 user 有加入的產品中
        } else if (!isNil(this.productsMap[productId])) {
            // 取出 productId 的群組資訊(有可能該產品無群組而沒有群組資訊)
            const groupInfo = this.groupInfoMap[productId]
            // 非當前產品還要進一步檢查
            // 1. 該產品無群組且 productKey 內也無 groupId
            // 2. 該產品有群組但 productKey 無 groupId (分群且為公告或混群的情況)
            // 3. 該產品有群組且為分群，則 productKey 內的 groupId 要在該產品的 groupIds 內
            if ((isNil(groupInfo) && isNil(groupId)) || (groupInfo && (isNil(groupId) || groupInfo.groupIds.includes(groupId)))) {
                return NOTIFY_TAB_OTHER
            } else if (groupInfo) {
                if (groupInfo.groupType === GROUP_TYPE_MIX && isNil(groupId)) {
                    return NOTIFY_TAB_OTHER
                } else if (groupInfo.groupType === GROUP_TYPE_GROUP && (isNil(groupId) || groupInfo.groupIds.includes(groupId))) {
                    return NOTIFY_TAB_OTHER
                }
            }

        }
        
        error(`Can not found TAB(product id: ${productId}, group id: ${groupId})`)
        return null
    }
}

export default NotificationHelper
