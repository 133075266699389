import barCss from '~css/bar.scss'
// import iconSearchGuideLine from '~img/icon-search-guideline.png'
const iconSearchGuideLine = `${process.env.SERVER_URL}/img/icon-search-guideline.png`

// const searchSuggestion = () => `<div class="${barCss.suggestion}" id="search_suggest" style="display: none"></div>`
const getSuggestSkeletonItems = () => {
    const items = []
    for (let i = 0; i < 10; i++) {
        items.push(`<div class="${barCss.searchArea_allHotkey}"><a class="${barCss.searchArea_hotkey_item}"><span class="${barCss.searchArea_skeleton_item}">&nbsp;</span></a></div>`)
    }
    return items.join('')
}
const searchSuggestion = () => `
<div class="${barCss.searchArea_allHotkey}" id="search_suggest" style="display: none">
    <div class="${barCss.searchArea_allHotkey}">
        <a class="${barCss.searchArea_hotkey_item}" id="heybarSuggestKeyword" style="cursor: pointer"></a>
    </div>
    <div id="heybarSearchSuggestWords">${getSuggestSkeletonItems()}</div>
</div>`
const search = addSuggestion => `
<div class="${barCss.searchArea} ${barCss['searchArea--close']}" id="heybarSearch">
    <!--將class"barIcon_search"換成"barIcon_search_selected"會變橘色-->
    <a class="${barCss.barIcon} ${barCss.barIcon_search}" id="heybarSearchOpen" style="cursor: pointer" data-gtm-whitebar="search_tools-search">
        <!--向上的三角形-->
        <!--加上class"border-up--close"三角形會隱藏-->
        <!--
        <div class="${barCss['border-up']} ${barCss['border-up--close']}" id="heybarSearchArrowUp">
            <span></span>
        </div>
        -->
    </a>
    <!--搜尋內容-->
    <div class="${barCss.searchArea_content}">
        <!--關閉icon
        <a class="${barCss.barIcon} ${barCss.barIcon_close}" id="heybarSearchClose" style="cursor: pointer"></a>
        -->
        <!--搜尋input-->
        <div class="${barCss.searchBox} ${barCss['searchBox--sm']}">
            <input type="text" class="${barCss['form-control']}" placeholder="搜尋" id="heybarSearchKeyword" autocomplete="off" data-suggest='search_suggest'>
            <button type="button" id="heybarSearcBtn">
                <img src="${iconSearchGuideLine}" alt="" width="16">
            </button>
        </div>
        <!--熱門關鍵字-->
        <div class="${barCss.searchArea_hotkey}" id="heybarSearchHotkeysContainer">
            <div class="${barCss.searchArea_label}">熱門關鍵字</div>

            <!--所有關鍵字列表-->
            <div style="height: 100vh; overflow-y: auto;">
                <div class="${barCss.searchArea_allHotkey}" id="heybarSearchHotkeys">
                </div>
                ${addSuggestion ? `${searchSuggestion()}` : ''}
            </div>
        </div>
    </div>
</div>`

export {
    search,
    getSuggestSkeletonItems,
}