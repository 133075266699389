import { proxyGet } from '@/api'
import barCss       from '~css/bar.scss'

const { debug, error} = require('@/utils/logger')(__filePath, __fileName, '#009FCC')


class HRArticleMFD {
    static async generate(link, showImage, rootText, name, domain) {
        const result = await proxyGet(link)
        const adAreaNode  = document.createElement('div')
        const adHeadNode  = document.createElement('div')
        const adLabelNode = document.createElement('div')
        const moreNode    = document.createElement('a')
        const adBodyNode  = document.createElement('div')

        adAreaNode.className  = barCss['ad-area']
        adHeadNode.className  = barCss['ad-head']
        adLabelNode.className = barCss['ad-label']
        moreNode.className    = barCss['more']
        adBodyNode.className  = barCss['ad-body']
        adBodyNode.style.overflow = 'auto'
        
        adLabelNode.innerHTML = '推薦文章'
        moreNode.innerHTML    = `<span>更多</span><span class="${barCss['arrow-forward']}"></span>`
        moreNode.style.cursor = 'pointer'
        moreNode.dataset.gtmWhitebar = 'read_guided-tools-article-more'
        moreNode.dataset.gtmValue    = `${rootText}-${name}`
        moreNode.onclick             = () => { HRArticleMFD.openToMore(link, domain) }

        for (const article of result) {
            const adItemNode = document.createElement('div')
            const adDescNode = document.createElement('a')

            let actionNode = null
            if (showImage && article.jetpack_featured_media_url) {
                const adPicNode  = document.createElement('div')
                const imgDivNode = document.createElement('div')
                const imgNode    = document.createElement('img')
                adPicNode.className  = barCss['ad-pic']
                imgDivNode.className = barCss['img']
                imgNode.src = HRArticleMFD.transInnerUrlToOutterUrl(article.jetpack_featured_media_url)
                imgDivNode.appendChild(imgNode)
                adPicNode.appendChild(imgDivNode)
                adItemNode.appendChild(adPicNode)
                actionNode = adItemNode
                
            } else {
                adDescNode.classList.add(barCss['has-dot'])
                actionNode = adDescNode
            }
            
            adItemNode.className = barCss['ad-item']
            adDescNode.classList.add(barCss['ad-desc'])
            
            adDescNode.style.cursor = 'pointer'
            adDescNode.innerHTML    = article && article.title && article.title.rendered ? article.title.rendered : ''
            actionNode.onclick      = () => { HRArticleMFD.openToArticle(article.link, domain)}
            adItemNode.appendChild(adDescNode)

            const text = adDescNode.innerText.replaceAll('\n', '')  // 把 tag 濾掉並把換行符號去掉
            actionNode.dataset.gtmWhitebar = 'read_guided-tools-article'
            actionNode.dataset.gtmValue    = `${rootText}-${text}`

            adBodyNode.appendChild(adItemNode)
        }

        adHeadNode.appendChild(adLabelNode)
        adHeadNode.appendChild(moreNode)
        adAreaNode.appendChild(adHeadNode)
        adAreaNode.appendChild(adBodyNode)

        return adAreaNode
    }

    static openToArticle(link, domain) {
        window.open(NavigationMFDHelper.appendUTM(link, 'whitebar_guided-article', domain))
    }

    static openToMore(link, domain) {
        const m = /cats_id=(\w+)/.exec(link)
        if (m) {
            window.open(`https://blog.104${process.env.BUILD_STAGE === 'dev' || !process.env.BUILD_STAGE ? '-dev' : process.env.BUILD_STAGE === 'stg' ? '-staging' : ''}.com.tw/?cat=${m[1]}`, 'whitebar_guided-article-more', domain)
        } else {
            window.open(`https://blog.104${process.env.BUILD_STAGE === 'dev' || !process.env.BUILD_STAGE ? '-dev' : process.env.BUILD_STAGE === 'stg' ? '-staging' : ''}.com.tw`, 'whitebar_guided-article-more', domain)
        }
    }

    static transInnerUrlToOutterUrl(url) {
        if (url) {
            const pattern = /(104dc(?:-(dev|staging))?.com)/gm
            const m = pattern.exec(url)
            if (m) {
                return url.replaceAll(pattern, `104${m[2] ? '-' + m[2] : ''}.com.tw`)
            }
        }
        return url
    }
}


class ImageMFD {
    static async generate(image_path, link, rootText, name, domain) {
        const adAreaNode     = document.createElement('div')
        const adBodyNode     = document.createElement('div')
        const bannerAreaNode = document.createElement('div')
        const bannerBoxNode  = document.createElement('div')
        const bannerImgNode  = document.createElement('div')
        const imageNode      = document.createElement('img')

        adAreaNode.className           = barCss['ad-area']
        adAreaNode.dataset.gtmWhitebar = 'read_guided-tools-image'
        adAreaNode.dataset.gtmValue    = `${rootText}-${name}`
        adBodyNode.className           = barCss['ad-body']
        bannerAreaNode.className       = barCss['banner-area']
        bannerBoxNode.classList.add(barCss['banner-box'])
        bannerBoxNode.classList.add(barCss['banner-xl'])
        bannerImgNode.className        = barCss['banner-img']
        bannerImgNode.style.cursor     = 'pointer'
        imageNode.src                  = image_path

        if (link !== null && link.length > 0) {
            bannerImgNode.onclick = () => window.open(NavigationMFDHelper.appendUTM(link, 'whitebar_guided-image', domain))
        }

        bannerImgNode.appendChild(imageNode)
        bannerBoxNode.appendChild(bannerImgNode)
        bannerAreaNode.appendChild(bannerBoxNode)
        adBodyNode.appendChild(bannerAreaNode)
        adAreaNode.appendChild(adBodyNode)

        return adAreaNode
    }
}

class TwoImageMFD {
    static async generate(image_path1, link1, name1, image_path2, link2, name2, rootText, domain) {
        const adAreaNode     = document.createElement('div')
        const adBodyNode     = document.createElement('div')
        const bannerAreaNode = document.createElement('div')
        const bannerBox1Node = document.createElement('div')
        const bannerImg1Node = document.createElement('div')
        const image1Node     = document.createElement('img')
        const bannerBox2Node = document.createElement('div')
        const bannerImg2Node = document.createElement('div')
        const image2Node     = document.createElement('img')


        adAreaNode.className           = barCss['ad-area']
        adBodyNode.className           = barCss['ad-body']
        bannerAreaNode.className       = barCss['banner-area']
        bannerBox1Node.classList.add(barCss['banner-box'])
        bannerBox1Node.classList.add(barCss['banner-sm'])
        bannerBox2Node.classList.add(barCss['banner-box'])
        bannerBox2Node.classList.add(barCss['banner-sm'])

        bannerImg1Node.className           = barCss['banner-img']
        bannerImg1Node.style.cursor        = 'pointer'
        bannerImg1Node.dataset.gtmWhitebar = 'read_guided-tools-multi-image'
        bannerImg1Node.dataset.gtmValue    = `${rootText}-${name1}`
        image1Node.src                     = image_path1
        if (link1 !== null && link1.length > 0) {
            bannerImg1Node.onclick = () => window.open(NavigationMFDHelper.appendUTM(link1, 'whitebar_guided-multi-image', domain))
        }

        bannerImg2Node.className           = barCss['banner-img']
        bannerImg2Node.style.cursor        = 'pointer'
        bannerImg2Node.dataset.gtmWhitebar = 'read_guided-tools-multi-image'
        bannerImg2Node.dataset.gtmValue    = `${rootText}-${name2}`
        image2Node.src                     = image_path2
        if (link2 !== null && link2.length > 0) {
            bannerImg2Node.onclick = () => window.open(NavigationMFDHelper.appendUTM(link2, 'whitebar_guided-multi-image', domain))
        }


        bannerImg1Node.appendChild(image1Node)
        bannerImg2Node.appendChild(image2Node)
        bannerBox1Node.appendChild(bannerImg1Node)
        bannerBox2Node.appendChild(bannerImg2Node)
        bannerAreaNode.appendChild(bannerBox1Node)
        bannerAreaNode.appendChild(bannerBox2Node)
        adBodyNode.appendChild(bannerAreaNode)
        adAreaNode.appendChild(adBodyNode)

        return adAreaNode
    }
}

class ImageTextMFD {
    static async generate(image_path, link, text, rootText, name, domain) {
        const adAreaNode     = document.createElement('div')
        const adBodyNode     = document.createElement('div')
        const bannerAreaNode = document.createElement('div')
        const bannerBoxNode  = document.createElement('div')
        const bannerPicNode  = document.createElement('div')
        const imageNode      = document.createElement('img')
        const bannerDescNode = document.createElement('div')
        const aNode          = document.createElement('a')
        const aDescNode      = document.createElement('span')
        const aForwardNode   = document.createElement('span')

        adAreaNode.className           = barCss['ad-area']
        adAreaNode.dataset.gtmWhitebar = 'read_guided-tools-image-text'
        adAreaNode.dataset.gtmValue    = `${rootText}-${name}`
        adBodyNode.className           = barCss['ad-body']
        bannerAreaNode.className       = barCss['banner-area']
        bannerBoxNode.classList.add(barCss['banner-box'])
        bannerBoxNode.classList.add(barCss['banner-lg'])
        bannerPicNode.className        = barCss['banner-pic']
        imageNode.src                  = image_path
        bannerDescNode.className       = barCss['banner-desc']
        bannerDescNode.innerHTML       = text
        aNode.className                = barCss['banner-goto']
        aDescNode.innerHTML            = '前往查看'
        aForwardNode.className         = barCss['arrow-forward']

        if (link !== null && link.length > 0) {
            adAreaNode.onclick = () => window.open(NavigationMFDHelper.appendUTM(link, 'whitebar_guided-image-text', domain))
        }
        
        aNode.appendChild(aDescNode)
        aNode.appendChild(aForwardNode)
        bannerPicNode.appendChild(imageNode)
        bannerBoxNode.appendChild(bannerPicNode)
        bannerAreaNode.appendChild(bannerBoxNode)
        bannerAreaNode.appendChild(bannerDescNode)
        bannerAreaNode.appendChild(aNode)
        adBodyNode.appendChild(bannerAreaNode)
        adAreaNode.appendChild(adBodyNode)

        return adAreaNode
    }
}

export default class NavigationMFDHelper {
    static async parse(mfds, rootText, domain) {
        try {
            if (mfds && mfds.length > 0) {
                // 目前 mfds == 2 的只有雙圖，所以先這樣寫
                if (mfds.length >= 2) {
                    if (mfds[0].type === 'IMAGE' && mfds[1].type === 'IMAGE') {
                        return await TwoImageMFD.generate(mfds[0].full_link, mfds[0].link, mfds[0].link, mfds[1].full_link, mfds[1].link, mfds[1].link, rootText, domain)
                    }
                }
                // 不是雙圖的話，就只取第一個來顯示

                if (mfds[0].type === 'IMAGE') {
                    return await ImageMFD.generate(mfds[0].full_link, mfds[0].link, rootText, mfds[0].name, domain)
                } else if (mfds[0].type === 'ARTICLE') {
                    const node = await HRArticleMFD.generate(mfds[0].link, mfds[0].extra && mfds[0].extra.show_image ? true : false, rootText, mfds[0].name, domain)
                    if (node) return node
                } else if (mfds[0].type === 'IMAGE_TEXT') {
                    return await ImageTextMFD.generate(mfds[0].full_link, mfds[0].link, mfds[0].text, rootText, mfds[0].name, domain)
                }   
            }
            
        } catch (err) {
            error('parse MFD data occur error: ' + err.message)
        }
        
        // const emptyNode = document.createTextNode('NO DATA')
        const emptyNode = document.createElement('div')
        emptyNode.style.textAlign = 'center'
        emptyNode.style.fontSize  = '30px'
        emptyNode.style.width     = '100%'
        emptyNode.innerHTML       = ''
        return emptyNode
    }

    static appendUTM(link, medium, domain) {
        const utm = `utm_source=104&utm_medium=${medium}&utm_campaign=${domain}`
        if (link !== null && link.length > 0) {
            if (link.indexOf('?') > -1) {
                return `${link}&${utm}`
            } else {
                return `${link}?${utm}`
            }
        } else {
            return link
        }
    }
}