module.exports = function (filePath, fileName, color = null) {
    const namespace = `heybar${filePath.replace('/', ':')}:${fileName}`
    const debugJs = require('debug')
    const info  = debugJs(`info:${namespace}`)
    const error = debugJs(`error:${namespace}`)
    const debug = debugJs(namespace)

    if (color) {
        info.color = color
        debug.color = color
    }

    info.log = console.info.bind(console)
    error.log = console.error.bind(console)

    return {
        info, error, debug
    }
}