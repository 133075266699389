import BaseComponent from '@/components/base-component'
import barCss        from '~css/bar.scss'
const {debug} = require('@/utils/logger')(__filePath, __fileName, '#FF8800')

/**
 * 特別注意：
 * 共有兩個 Logo 區塊，且因為有一個 logo 落在在導航的 template 內，所以要在 draw 了導航區塊之後才能執行此 Logo 的 draw
 */
class Logo extends BaseComponent {
    static MARK_TAG = 'logo'

    constructor(mainNode, config) {
        super(mainNode, config, ['logo', 'logoAction'])
    }

    _generateNode() {
        const actionNode = document.createElement('a')
        const logoNode   = document.createElement('img')
        logoNode.src     = this.logo
        logoNode.classList.add(barCss.logoHeight)
        // logoNode.dataset.gtmSubParam = 'Logo'
        logoNode.dataset.gtmWhitebar = 'logo'
        actionNode.appendChild(logoNode)
        // actionNode.title = '新年快樂'

        return actionNode
    }

    _bindEvent(container) {
        if (this.logoAction) {
            if (typeof(this.logoAction) === 'function') {
                container.onclick      = this.logoAction
                container.style.cursor = 'pointer'
            } else {
                container.setAttribute('href', this.logoAction)
            }
        } else {
            container.style.cursor = 'pointer'
        }
    }
}

export default Logo