import BaseComponent            from '@/components/base-component'
import { shoppingCartTemp }     from "@/templates/shopping-cart-temp"
import { stringToDom, isNil }   from '@/utils/tools'
import { domMixin, eventMixin } from '@/mixins'
import * as SystemEvents        from '@/constants/system-event'
import * as HeybarEvents        from '@/constants/heybar-event'
import * as modeHelper          from '@/helper/heybar-mode-helper'
import barCss                   from '~css/bar.scss'

const { debug, error } = require('@/utils/logger')(__filePath, __fileName, '#C94DDB')

const SHOPPING_CART_MARK_TAG = 'shopping-cart'

class ShoppingCart extends BaseComponent {
    static MARK_TAG = SHOPPING_CART_MARK_TAG
    
    constructor(mainNode, config, shoppingCartConfig) {
        super(mainNode, config)
        this.isOpen = false
        this.shoppingCartConfig = shoppingCartConfig
        this.__eventClose4BodyTemp  = this.eventClose4Body.bind(this)
    }

    _getCartNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss.cart)
    }

    _getCartContentNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss['cart-content'])
    }

    _getSwitchNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss.barIcon, `.${barCss.barIcon_cart}`)
    }

    _getBadgeNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss.cart_count)
    }

    _generateNode() {
        const node = stringToDom(shoppingCartTemp())
        if (!isNil(this.shoppingCartConfig.action) && 
                (!isNil(this.shoppingCartConfig.action.dropdown) || (!isNil(this.shoppingCartConfig.action.pc) && !isNil(this.shoppingCartConfig.action.pc.dropdown)))) {
            this.setDropdown(this.shoppingCartConfig.action.pc.dropdown, node)
        }
        // const contentNode = this._getCartContentNode(node)
        // if (typeof(this.shoppingCartConfig.action.pc.dropdown) === 'string') {
        //     if (this.shoppingCartConfig.action.pc.dropdown.startsWith('#') || this.shoppingCartConfig.action.pc.dropdown.startsWith('.')) {
        //         const dropdownNode = document.querySelector(this.shoppingCartConfig.action.pc.dropdown)
        //         if (dropdownNode) {
        //             dropdownNode.style.display = null
        //             contentNode.appendChild(dropdownNode)
        //         } else {
        //             error(`Can not find dropdown node (${this.shoppingCartConfig.action.pc.dropdown})`)
        //         }
        //     } else {
        //         contentNode.innerHTML = this.shoppingCartConfig.action.pc.dropdown
        //     }
        // } else if (typeof(this.shoppingCartConfig.action.pc.dropdown) === 'function') {
        //     const dropdownObject = this.shoppingCartConfig.action.pc.dropdown()
        //     if (typeof(dropdownObject) === 'string') {
        //         contentNode.innerHTML = dropdownObject
        //     } else {
        //         dropdownObject.style.display = null
        //         contentNode.appendChild(dropdownObject)
        //     }

        // } else {
        //     contentNode.appendChild(this.shoppingCartConfig.action.pc.dropdown)
        // }
        
        return node
    }

    _bindEvent(container) {
        const switchNode = this._getSwitchNode(container)
        if (switchNode) {
            switchNode.onclick = evt => {
                evt.stopPropagation()
                if (typeof(this.shoppingCartConfig.action.link) === 'string') {
                    location.href = this.shoppingCartConfig.action.link
                } else if (typeof(this.shoppingCartConfig.action.link) === 'function') {
                    this.shoppingCartConfig.action.link()
                } else error('Can not do any thing when shopping cart mobile link is not string and not function')
                // if (modeHelper.isMobileMode(this.mainNode)) {
                //     if (typeof(this.shoppingCartConfig.action.mobile.link) === 'string') {
                //         location.href = this.shoppingCartConfig.action.mobile.link
                //     } else if (typeof(this.shoppingCartConfig.action.mobile.link) === 'function') {
                //         this.shoppingCartConfig.action.mobile.link()
                //     } else error('Can not do any thing when shopping cart mobile link is not string and not function')
                // } else {
                //     if (this.isOpen) this.eventClose(evt)
                //     else this.eventOpen(evt)
                // }
            }
        } else error('Shopping Cart switch event FAIL because can not found any switch node')

        if ((!isNil(this.shoppingCartConfig.action.closeAfterClick) && !this.shoppingCartConfig.action.closeAfterClick) 
            ||
            (!isNil(this.shoppingCartConfig.action.pc) && !isNil(this.shoppingCartConfig.action.pc.closeAfterClick) && !this.shoppingCartConfig.action.pc.closeAfterClick)
            ||
            (!isNil(this.shoppingCartConfig.action.mobile) && !isNil(this.shoppingCartConfig.action.mobile.closeAfterClick) && !this.shoppingCartConfig.action.mobile.closeAfterClick)
        ) {
            const contentNode = this._getCartContentNode(container)
            if (contentNode) contentNode.onclick = evt => {evt.stopPropagation()}
            else error('set shopping cart content node event error, can not found it.')
        }
        
        // 點擊頁面關閉通知
        window.removeEventListener('click', this.__eventClose4BodyTemp)
        window.addEventListener('click', this.__eventClose4BodyTemp)
    }

    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return // 已是開啟的情況下就不用再執行了

        // 開啟小視窗
        const cartNode = this._getCartNode()
        if (cartNode) cartNode.classList.remove(barCss.closeBox)
        else error('can not found shopping cart node, open shopping cart panel fail.')

        // icon 亮橘色
        const switchNode = this._getSwitchNode()
        if (switchNode) switchNode.classList.add(barCss.barIcon_cart_selected)
        else error('can not found shopping cart switch node, change selected color fail.')

        this.isOpen = true
        this.emit(SystemEvents.OPEN)
        this.fire(HeybarEvents.OPEN)
    }

    eventClose(evt, stopPropagation=true) {
        if (this.shoppingCartConfig) {
            if (evt && stopPropagation) evt.stopPropagation()
            if (!this.isOpen) return // 已是關閉的情況下就不用再執行關閉了

            this.fire(HeybarEvents.BEFORE_CLOSE)

            // 關閉小視窗
            const cartNode = this._getCartNode()
            if (cartNode) cartNode.classList.add(barCss.closeBox)
            else error('can not found shopping cart node, close shopping cart panel fail.')

            // icon(switch node)要拿掉橘色
            const switchNode = this._getSwitchNode()
            if (switchNode) switchNode.classList.remove(barCss.barIcon_cart_selected)
            else error('can not found shopping cart switch node, change unselect color fail.')

            this.isOpen = false
            this.emit(SystemEvents.CLOSE)
            this.fire(HeybarEvents.CLOSE)
        }
    }

    eventClose4Body(evt) {
        this.eventClose(evt, false)
    }

    setBadge(count) {
        const badgeNode = this._getBadgeNode()
        if (badgeNode) {
            badgeNode.querySelector('span').innerHTML = count >= 100 ? '99' : count
            if (count > 0) {
                badgeNode.style.display = null
            } else {
                badgeNode.style.display = 'none'
            }
        } else error('can not find badge node')
    }

    setDropdown(content, anchor=this.mainNode) {
        const contentNode = this._getCartContentNode(anchor)
        if (typeof(content) === 'string') {
            if (content.startsWith('#') || content.startsWith('.')) {
                const dropdownNode = document.querySelector(content)
                if (dropdownNode) {
                    dropdownNode.style.display = null
                    contentNode.innerHTML = ''
                    contentNode.appendChild(dropdownNode)
                } else {
                    error(`Can not find dropdown node (${content})`)
                }
            } else {
                contentNode.innerHTML = content
            }
        } else if (typeof(content) === 'function') {
            const dropdownObject = content()
            if (typeof(dropdownObject) === 'string') {
                contentNode.innerHTML = dropdownObject
            } else {
                dropdownObject.style.display = null
                contentNode.innerHTML = ''
                contentNode.appendChild(dropdownObject)
            }

        } else {
            contentNode.appendChild(content)
        }
    }

    cleanDropdown(anchor=this.mainNode) {
        const contentNode = this._getCartContentNode(anchor)
        contentNode.innerHTML = ''
    }

    close() {
        this.eventClose()
    }

    open() {
        this.eventOpen()
    }
}

Object.assign(ShoppingCart.prototype, domMixin(), eventMixin())
export default ShoppingCart
