const eventBus = {
    handlers: {}, // {eventName: [{id:'', handler: function(event){}, {id:'', handler: function}, ...]}
    
    on (event, id, handler) {
        if (!event || event.length === 0 || !id || id.length == 0 || !handler) {
            throw Error(`EventBus Can not handle event. event::${event}, id:: ${id}, handler::${handler}`)
        }

        if (!eventBus.handlers[event]) {
            eventBus.handlers[event] = []
        }

        eventBus.handlers[event].push({id, handler})
    },

    off (event, id, handler) {
        const handlers = eventBus.handlers[event] || []
        for (let i = 0; i < handlers.length; i++) {
            if (handlers[i] && handlers[i].id === id && handlers[i].handler === handler) {
                eventBus.handlers[event].splice(i, 1)
                break
            }
        }
    },

    fire (event, id, data) {
        const handlers = eventBus.handlers[event] || []
        for (const handler of handlers) {
            if (handler.id !== id) {
                handler.handler(data)
            }
        }
    }

}

export default eventBus