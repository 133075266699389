import Base58 from 'base-58'
import { EVENT_CLICK_NOTIFICATION } from '@/_n11s-v2/events'
import { stringToDom } from '@/utils/tools'

const {debug, error} = require('@/utils/logger')(__filePath, __fileName, '#FFFF77')

class StandardNotificationParser {
    constructor(productId, events, productHomeMap) {
        this.productId      = productId
        this.events         = events
        this.productHomeMap = productHomeMap || {}
    }

    
    parse(notice, handler) {
        const result = {content: null, extension: null, link: null, handler: null} // 2022-04-27 新加回傳 link 與 handler 屬性，用來表示整個通知都要包連結

        // 檢查通知的 content 與 extension 是否為 null，是的話返回內容為 null 的 result
        if (!notice.content || !notice.extension) {
            error('parse FAIL!! notice(' + notice.id + ') content or extension is null')
            return result
        }

        // 將 extension 轉成 json 物件
        const contentStr   = new TextDecoder("utf-8").decode(Base58.decode(notice.content))
        const extensionStr = new TextDecoder("utf-8").decode(Base58.decode(notice.extension))
        
        // 先把 extension 轉成 json 物件，如果轉失敗則直接結束
        try {
            result.extension = JSON.parse(extensionStr)
        } catch (err) {
            error('parse FAIL!! notice extension is not a JSON string')
            return result
        }

        // 將通知內容 parse 成 json 物件，若 parse 失敗表示 content 是純字串，
        let content = null
        try {
            content = JSON.parse(contentStr)
        } catch (err) {
            const textNotice = contentStr.replace(/\n/g, '<br/>')
            // 純文字的通知，如果非本產品的通知則用該產品的 home link 包成連結(如果有的話)，否則就呈現純文字
            // 2022-04-27 取消整個通知包連結，改成把連結往外帶，由呼叫者決定
            // if (this.productId !== notice.receiver_product && this.productHomeMap[notice.receiver_product]) {
                
                // const link     = document.createElement('a')
                // link.innerHTML = textNotice
                // link.setAttribute('href', this.productHomeMap[notice.receiver_product])
                // link.setAttribute('target', '_blank')
                // result.content = link
                
            // } else {
                // result.content = stringToDom(textNotice)
            // }
            result.content = stringToDom(textNotice)
            if (this.productId !== notice.receiver_product && this.productHomeMap[notice.receiver_product]) {
                result.link = this.productHomeMap[notice.receiver_product]
            }

            return result
        }
        
        let message = content.message ? content.message.replace(/\n/g, '<br/>') : content.message
        // 如果有 link 欄位，表示整個要用連結包起來
        if (content.link) {
            const {content:htmlContent, link, handler:eventHandler} = this._generateLink({
                text: message,
                params: content.link,
                notificationId: notice.id,
                isOutside: this.productId !== notice.receiver_product,
                handler,
                groupId: notice.receiver_group,
                homeLink: this.productHomeMap[notice.receiver_product],
                makeLink: false,
            })

            result.content = htmlContent
            result.link    = link
            result.handler = eventHandler

        } else if (content.params) {
            // 把 message 中的 {key} 換成 <key /> 以方便轉成 node 的操作
            Object.keys(content.params).forEach(key => { message = message.replace(new RegExp(`{\\s*${key}\\s*}`), `<${key}></${key}>`) })
            // 把 message 轉成 node
            const messageNode = stringToDom(message)

            for (const key of Object.keys(content.params)) {
                const linkNode = this._generateLink({
                    text: content.params[key].text
                        ? content.params[key].text.replace(/\n/g, '<br>')
                        : content.params[key].text,
                    params: content.params[key],
                    notificationId: notice.id,
                    isOutside: this.productId !== notice.receiver_product,
                    handler,
                    groupId: notice.receiver_group,
                    homeLink: this.productHomeMap[notice.receiver_product],
                })

                if (linkNode) {
                    const keyNodes = messageNode.querySelectorAll(key)
                    if (keyNodes) {
                        for (const keyNode of keyNodes) {
                            keyNode.parentNode.replaceChild(linkNode, keyNode)
                        }
                    }
                }
            }
            result.content = messageNode
        
        } else {
            // 純文字的通知，如果非本產品的通知則用該產品的 home link 包成連結(如果有的話)，否則就呈現純文字
            // 2022-04-27 取消整個通知包連結，改成把連結往外帶，由呼叫者決定
            // if (this.productId !== notice.receiver_product && this.productHomeMap[notice.receiver_product]) {
            //     const link     = document.createElement('a')
            //     link.innerHTML = message
            //     link.setAttribute('href', this.productHomeMap[notice.receiver_product])
            //     link.setAttribute('target', '_blank')
            //     result.content = link
            // } else {
            //     result.content = stringToDom(message)
            // }
            result.content = stringToDom(message)
            if (this.productId !== notice.receiver_product && this.productHomeMap[notice.receiver_product]) {
                result.link = this.productHomeMap[notice.receiver_product]
            }
        }
        
        return result
    }

    _generateLink({ text, params, notificationId, isOutside, handler, groupId, homeLink, makeLink = true }) {
        // 如果是外部連結(即通知非此產品所屬)，且有 outside_path 用連結
        if (isOutside && (params.outside_path || homeLink)) {
            // 如果沒有外部連結則用產品的 home link
            const outsidePath = params.outside_path || homeLink
            if (makeLink) {
                const link = document.createElement('a')
                link.innerHTML = text
                link.setAttribute('href', outsidePath)
                link.setAttribute('target', '_blank')
                return link
            } else {
                return {content: stringToDom(text), link: outsidePath, handler: null}
            }
        
        // event 有值的話，走發 event 的方式 && 看看有沒監聽此事件的 handler，有的話就用 event 的方式發送
        } else if (params.event && ((params.event.eventType && this.events[params.event.eventType]) || this.events[EVENT_CLICK_NOTIFICATION])) {
            const eventParams = Object.assign({}, params.event)
            eventParams.notificationId = notificationId
            eventParams.groupId = groupId
            eventParams.outsidePath = params.outside_path

            if (makeLink) {
                const link = document.createElement('a')
                link.innerHTML = text
                link.setAttribute('href', '#')
                link.onclick = () => { handler(eventParams) }

                return link
            } else {
                return {content: stringToDom(text), link: null, handler: function() {handler(eventParams)}}
            }

        // 沒有 event 或是沒有監聽 event 的 handler，則改用 path 的方式
        } else if (params.path) {
            if (makeLink) {
                const link = document.createElement('a')
                link.innerHTML = text
                link.setAttribute('href', params.path)
                
                return link
            } else {
                return {content: stringToDom(text), link: params.path, handler: null}
            }
        }

        // 啥都沒有就直接包成 text node 回傳
        if (makeLink) {
            return stringToDom(text)
        } else {
            return {content: stringToDom(text), link: null, handler: null}
        }
    }
}

export default StandardNotificationParser
