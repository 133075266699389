import barCss from '~css/bar.scss'

export const isMobileMode = anchor => {
    anchor = anchor ? anchor : document
    const iAmMobileNode = document.querySelector(`.${barCss.i_am_mobile}`)
    const style         = window.getComputedStyle(iAmMobileNode)
    if (iAmMobileNode && style.display !== 'none') {
        return true
    } else {
        return false
    }
}

export const isPCMode = anchor => {
    anchor = anchor ? anchor : document
    const iAmMobileNode = document.querySelector(`.${barCss.i_am_mobile}`)
    const style         = window.getComputedStyle(iAmMobileNode)
    if (iAmMobileNode && style.display === 'none') {
        return true
    } else {
        return false
    }
}

export const currentMode = anchor => {
    anchor = anchor ? anchor : document
    const iAmMobileNode = document.querySelector(`.${barCss.i_am_mobile}`)
    const style         = window.getComputedStyle(iAmMobileNode)
    if (iAmMobileNode) {
        if (style.display === 'none') {
            return 'PC'
        } else {
            return 'MOBILE'
        }
    } else {
        return 'UNKNOWN'
    }
}