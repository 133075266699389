import BaseComponent                         from '@/components/base-component'
import { search, getSuggestSkeletonItems }   from '@/templates/search-temp'
import { stringToDom }                       from '@/utils/tools'
import { domMixin, eventMixin, searchMixin } from '@/mixins'
import * as SuggestEvents                    from '@/constants/suggest-event'
import * as SystemEvents                     from '@/constants/system-event'
import * as HeybarEvents                     from '@/constants/heybar-event'
import barCss                                from '~css/bar.scss'

const { error } = require('@/utils/logger')(__filePath, __fileName, '#33CCFF')
const SEARCH_ID          = 'heybarSearch'
const SEARCH_HOTKEYS_ID  = 'heybarSearchHotkeys'
const SEARCH_KEYWORD_ID  = 'heybarSearchKeyword'
const SEARCH_OPEN_ID     = 'heybarSearchOpen'
const SEARCH_ARROW_UP_ID = 'heybarSearchArrowUp'
// const SEARCH_CLOSE_ID    = 'heybarSearchClose'
const SEARCH_MARK_TAG    = 'search'
const SEARCH_BTN_ID      = 'heybarSearcBtn'
const SEARCH_HOTKEYS_CONTAINER_ID = 'heybarSearchHotkeysContainer'
const SEARCH_SUGGEST_KEYWORD_ID   = 'heybarSuggestKeyword'
const SEARCH_SUGGEST_ID           = 'search_suggest'
const SEARCH_SUGGEST_WORDS_ID     = 'heybarSearchSuggestWords'

class Search extends BaseComponent {
    static MARK_TAG = 'search'

    // constructor(mainNode, config, lottiePromise) {  //===> 節日彩蛋使用
    constructor(mainNode, config) {
        super(mainNode, config, ['search', 'hotkeys', 'suggestion', 'suggestActivateNum'])
        this.isOpen = false
        // this.lottiePromise = lottiePromise  //===> 節日彩蛋使用
        // this.__closeSuggestionTemp = this.closeSuggestion.bind(this)
    }

    _findSearchOpenNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_OPEN_ID)
    }

    // _findSearchCloseNode(anchor) {
    //     anchor = anchor ? anchor : this.mainNode
    //     return this.findById(anchor, SEARCH_CLOSE_ID)
    // }

    // _findSearchArrowUpNode() {
    //     return this.findById(this.mainNode, SEARCH_ARROW_UP_ID)
    // }

    _findSearchHotkeysContainerNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_HOTKEYS_CONTAINER_ID)
    
    }

    _findSearchKeywordNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_KEYWORD_ID)
    }

    _findSearchHotKeysNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_HOTKEYS_ID)
    }

    _findSearchBtnNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_BTN_ID)
    }

    _findSearchSuggestKeywordNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_SUGGEST_KEYWORD_ID)
    }

    _findSearchSuggestNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_SUGGEST_ID)
    }

    _findSearchHotkeyLabelNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findByClassName(anchor, barCss.searchArea_label)
    }

    _findSearchSuggestWordsNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, SEARCH_SUGGEST_WORDS_ID)
    }

    _generateNode() {
        const container = stringToDom(search(this.suggestion))
        
        this.refreshHotkeys(this.hotkeys, container)
        

        return container
    }


    _bindEvent(container) {
        const openNode          = this._findSearchOpenNode(container)
        // const closeNode         = this._findSearchCloseNode(container)
        const searchKeywordNode = this._findSearchKeywordNode(container)
        const searchBtnNode     = this._findSearchBtnNode(container)
        
        // if (openNode) openNode.onclick = this.eventOpen.bind(this)
        // else error('Can not bind open event to search because can not found open button node.')
        if (openNode) openNode.onclick = evt => {
            if (this.isOpen) this.eventClose(evt)
            else this.eventOpen(evt)
        }
        else error('Can not bind open event to search because can not found open button node.')
        
        // if (closeNode) closeNode.onclick = this.eventClose.bind(this)
        // else error('Can not bind close event to search because can not found close button node.')
        
        if (searchKeywordNode) {
            this.bindSearchEvent(container, searchKeywordNode, this.search, searchBtnNode ? searchBtnNode.querySelector('img'): null, keyword => { this.emit(SystemEvents.KEYWORD, keyword) })
        }
        else error('Can not bind search event to search because can not found input keyword node.')

    }

    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return

        const searchNode = this._getSelfNode() // this._findSearchNode()
        const openNode = this._findSearchOpenNode()
        // const arrowUpNode = this._findSearchArrowUpNode()
        // const searchKeywordNode = this._findSearchKeywordNode()

        // =====> 節日彩蛋使用 START <=============================================================
        // this.lottieSearch.play()
        // =====> 節日彩蛋使用 END <=============================================================

        if (searchNode) searchNode.classList.remove(barCss['searchArea--close'])
        else error('Can not found search node, open search menu fail.')
        
        if (openNode) openNode.classList.add(barCss['barIcon_search_selected'])
        else error('Can not found search open node, open search menu fail.')
        
        // if (arrowUpNode) arrowUpNode.classList.remove(barCss['border-up--close'])
        // else error('Can not found search arrow up node, open search menu fail.')
        

        this.isOpen = true
        // this.fire(`${this.SYSTEM_EVENT_PREFIX}open`)
        // this.fire('open')
        this.emit(SystemEvents.OPEN)
        this.fire(HeybarEvents.OPEN)
    }

    eventClose(evt) {
        if (evt) evt.stopPropagation()
        if (!this.isOpen) return

        const searchNode = this._getSelfNode() // this._findSearchNode()
        const openNode = this._findSearchOpenNode()
        // const arrowUpNode = this._findSearchArrowUpNode()
        const searchKeywordNode = this._findSearchKeywordNode()

        // =====> 節日彩蛋使用 START <=============================================================
        // this.lottieSearch.goToAndStop(0)
        // =====> 節日彩蛋使用 END <=============================================================

        if (searchNode) searchNode.classList.add(barCss['searchArea--close'])
        else error('Can not found search node, close search menu fail.')
        if (openNode) openNode.classList.remove(barCss['barIcon_search_selected'])
        else error('Can not found search open node, close search menu fail.')
        // if (arrowUpNode) arrowUpNode.classList.add(barCss['border-up--close'])
        // else error('Can not found search arrow up node, close search menu fail.')

        if (searchKeywordNode) {
            searchKeywordNode.value = ''
            // this.fire(`${this.SYSTEM_EVENT_PREFIX}keyword`, searchKeywordNode.value)
            this.emit(SystemEvents.KEYWORD, searchKeywordNode.value)
            if (this.suggestion) this.closeSuggestion()
        }

        this.isOpen = false
        // this.fire('close')
        this.emit(SystemEvents.CLOSE)
        this.fire(HeybarEvents.CLOSE)
    }

    // =====> 節日彩蛋使用 START <=============================================================
    // draw(...params) {
    //     super.draw(...params)
    //     const openNode = this._findSearchOpenNode()
    //     if (openNode) openNode.setAttribute('style', "background-size: 0px !important")

    //     this.lottiePromise.then(() => {
    //         this.lottieSearch = lottie.loadAnimation({
    //             container: openNode,
    //             renderer: 'svg',
    //             loop: false,
    //             autoplay: false,
    //             name: 'heybarNotifySearchAnim',
    //             path: `${process.env.SERVER_URL}/lib/2022_moon_search.json` // 中秋節
    //             // path: `${process.env.SERVER_URL}/lib/lf30_th6cgxnf.json`  // 聖誕節
    //             // path: `${process.env.SERVER_URL}/lib/2024_new_year_search.json`  // 2024新年
    //         })
    //         this.lottieSearch.addEventListener('DOMLoaded', () => {
    //             const searchSvg = document.querySelector(`#${SEARCH_OPEN_ID} svg`)
    //             if (searchSvg) {
    //                 searchSvg.setAttribute('class', barCss['festival_ring'])
    //             }
    //         })
    //     })
    // }
    // =====> 節日彩蛋使用 END <=============================================================


    /**
     * 
     * @param {*} hotkeys 
     * @param {*} container 在初始化時，因為還沒寫到 document，所以如果用 document 去 queryselector 會找不到，需要依賴初始化時建立的 search container 來抓出 hotkeys 的位置
     */
    refreshHotkeys(hotkeys, container=null) {
        container = container ? container : document
        const hotkeysNode = this._findSearchHotKeysNode(container)
        const hotkeyContainerNode = this._findSearchHotkeysContainerNode(container)
        if (hotkeysNode && hotkeys && hotkeys.length > 0) {
            if (hotkeyContainerNode) hotkeyContainerNode.style.display = null
            hotkeysNode.innerHTML = ''  // 先清掉原有的內容
            for (const hotkey of hotkeys) {
                const div = document.createElement('div')
                div.classList.add(barCss.searchArea_allHotkey)
                if (!hotkey.link && this.search && typeof(this.search) === 'function') {
                    const hotkeyNode = document.createElement('a')
                    hotkeyNode.className = barCss.searchArea_hotkey_item
                    hotkeyNode.style.cursor = 'pointer'
                    hotkeyNode.innerHTML = hotkey.text
                    hotkeyNode.onclick = evt => { this.search(hotkey.text) }
                    div.appendChild(hotkeyNode)
                } else {
                    div.innerHTML = `<a href="${hotkey.link}" class="${barCss.searchArea_hotkey_item}">${hotkey.text}</a>`
                }
                
                hotkeysNode.appendChild(div)
            }
        } else {
            if (hotkeyContainerNode) hotkeyContainerNode.style.display = 'none'
        }
    }

    drawPlaceholder(placeholder, prefix = '搜尋') {
        const keywordNode = this._findSearchKeywordNode()
        if (keywordNode) keywordNode.setAttribute('placeholder', `${prefix ? prefix + ' ' : ''}${placeholder ? placeholder : ''}`)
    }

    drawValue(value) {
        const keywordNode = this._findSearchKeywordNode()
        if (keywordNode) keywordNode.value = value
    }


    // drawProductName(productName) {
    //     const keywordNode = this._findSearchKeywordNode()
    //     if (keywordNode) keywordNode.setAttribute('placeholder', `搜尋 ${productName ? productName : ''}`)
    // }

    syncKeyword(keyword) {
        const searchKeywordNode = this._findSearchKeywordNode()
        if (searchKeywordNode) searchKeywordNode.value = keyword
    }
    
    syncSuggestKeyword(keyword) {
        const searchSuggestKeywordNode = this._findSearchSuggestKeywordNode()
        if (searchSuggestKeywordNode) {
            // searchSuggestKeywordNode.dataset.gtmSubParam    = `關鍵字-${keyword}`
            searchSuggestKeywordNode.dataset.suggestionWord = keyword
            searchSuggestKeywordNode.innerHTML              = `<span style='font-weight:bold;'>${keyword}</span>`
        }
    }

    syncSuggestion(evt) {
        switch (evt.action) {
            case SuggestEvents.OPEN_SUGGESTION:
                this.syncOpenSuggestion(evt)
                break
            case SuggestEvents.CLOSE_SUGGESTION:
            case SuggestEvents.EMPTY_KEYWORD:
                this.syncCloseSuggestion(evt)
                break
            case SuggestEvents.SUGGESTIONS:
                this.syncDrawSuggestion(evt)
                break
            case SuggestEvents.SELECTED_SUGGESTION:
                this.syncKeyword(evt.data)
                break
            case SuggestEvents.SUGGEST_START:
                this.syncDrawSuggesting()
                break
            case SuggestEvents.NO_DATA:
                this.syncDrawNoData()
                break
        }
    }

    syncOpenSuggestion(evt) {
        const suggestionNode = this._findSearchSuggestNode()
        if (suggestionNode) suggestionNode.style.display = null
        const hotkeyNode = this._findSearchHotKeysNode()
        if (hotkeyNode) hotkeyNode.style.display = 'none'
        const hotkeyLabelNode = this._findSearchHotkeyLabelNode()
        if (hotkeyLabelNode) hotkeyLabelNode.style.display = 'none'
    }

    syncCloseSuggestion(evt) {
        const suggestionNode = this._findSearchSuggestNode()
        if (suggestionNode) suggestionNode.style.display = 'none'
        const hotkeyNode = this._findSearchHotKeysNode()
        if (hotkeyNode) hotkeyNode.style.display = null
        const hotkeyLabelNode = this._findSearchHotkeyLabelNode()
        if (hotkeyLabelNode) hotkeyLabelNode.style.display = null
        const suggestWordsNode = this._findSearchSuggestWordsNode()
        if (suggestWordsNode) suggestWordsNode.innerHTML = getSuggestSkeletonItems()
    }

    syncDrawSuggestion(evt) {
        const suggestionNode = this._findSearchSuggestNode()
        if (suggestionNode) {
            const suggestionWords   = evt.data
            const searchKeywordNode = this._findSearchKeywordNode()       // 取得 search input node
            const suggestionNode    = this._findSearchSuggestNode()
            const suggestWordsNode  = this._findSearchSuggestWordsNode()  // 取得繪製 suggestion items 的區塊

            const inputKeyword      = searchKeywordNode ? searchKeywordNode.value : ''
            if (searchKeywordNode) searchKeywordNode.value = inputKeyword
            this.syncOpenSuggestion()

            if (suggestionNode && suggestWordsNode) {
                // 清掉 suggestion items 區塊的內容 (要重新繪製)
                suggestWordsNode.innerHTML = ''

                for (const word of suggestionWords) {
                    // 回來的字與輸入的 keyword 相同則跳過 ( 此規則只適用於 Search 而不應用於 SearchInput )
                    if (word === inputKeyword) continue
                    let displayWord = word
                    const idx = word.indexOf(inputKeyword)
                    if (idx >= 0) {
                        displayWord = `${word.substring(0, idx)}<span style='font-weight:bold;'>${inputKeyword}</span>${word.substring(idx + inputKeyword.length)}`
                    }

                    const item        = document.createElement('div')
                    const itemContent = document.createElement('a')

                    itemContent.className = barCss.searchArea_hotkey_item
                    // itemContent.dataset.gtmSubParam    = `關鍵字-${word}`
                    itemContent.dataset.suggestionWord = word
                    itemContent.innerHTML              = displayWord
                    itemContent.style.cursor           = 'pointer'
                    item.appendChild(itemContent)

                    item.className = barCss.searchArea_allHotkey
                    
                    suggestWordsNode.appendChild(item)
                }
            }
        }
    }

    syncDrawSuggesting() {
        const suggestWordsNode = this._findSearchSuggestWordsNode()
        if (suggestWordsNode) suggestWordsNode.innerHTML = getSuggestSkeletonItems()
        const suggestionNode = this._findSearchSuggestNode()
        if (suggestionNode && suggestionNode.style.display === 'none') {
            this.syncOpenSuggestion()
        }
    }

    syncDrawNoData() {
        const suggestWordsNode = this._findSearchSuggestWordsNode()
        if (suggestWordsNode) suggestWordsNode.innerHTML = ''
    }

    clean() {
        this.restore()
        
        this.cleanHandlers()
        this.isOpen = false
    }
}

Object.assign(Search.prototype, domMixin(), eventMixin(), searchMixin())
Search.id = SEARCH_ID

export default Search
