class BaseComponent {
    constructor(mainNode, config, saveToThisParamNames = []) {
        this.mainNode = mainNode
        this.config   = config
        this.name     = this.constructor.name

        saveToThisParamNames.forEach(paramName => {this[paramName] = this.config[paramName]})
    }
    /**
     * 取得所有 MarkNode
     * 回傳為 NodeList 或 Node，若是呼叫 BaseComponent 的此方法，則一律回傳的是 NodeList
     */
    _getMarkNode()        { return this.mainNode.querySelectorAll(this.getMarkTagName()) }
    /**
     * 取得所有此產生在畫面上的 component node
     * 回傳為 NodeList 或 Node，若是呼叫 BaseComponent 的此方法，則一律回傳的是 NodeList
     */
    _getRestoreNode()     { return this.mainNode.querySelectorAll(`#${this.getId()}`) }
    _getRestoreMarkNode() { return document.createElement(this.getMarkTagName()) }
    _getSelfNode()        { return this.mainNode.querySelector(`#${this.getId()}`) }

    _generateNode()       { throw new Error('not implemented') }
    _bindEvent(container) { throw new Error('not implemented') }

    getMarkTagName() { return this.constructor.MARK_TAG}
    getId() { return this.constructor.id}

    getNode(...params) {
        const container = this._generateNode(...params)
        this._bindEvent(container, ...params)

        return container
    }

    draw(...params) {
        let markNodes = this._getMarkNode(...params)
        if (markNodes) {
            if (!Array.isArray(markNodes) && !(markNodes instanceof NodeList)) {
                markNodes = [markNodes]
            }

            for (const markNode of markNodes) {
                const node = this.getNode(...params)
                if (node.nodeName !==  '#document-fragment') {
                    markNode.classList.forEach(clazz => {
                        if(!node.classList.contains(clazz)) {
                            node.classList.add(clazz)
                        }
                    })
                }
                if (this.beforeDraw) this.beforeDraw(node)
                markNode.parentNode.replaceChild(node, markNode)
                // if ('folder' in markNode.attributes) {
                //     markNode.appendChild(node)
                // } else {
                //     markNode.parentNode.replaceChild(node, markNode)
                // }
            }
        }
        else error(`Can not draw ${this.constructor.name} because can not found it's tag node`)
    }

    restore(keepClass = false, ...params) {
        const nodes    = this._getRestoreNode(...params)
        const markNode = this._getRestoreMarkNode(...params)


        if (nodes) {
            if (!Array.isArray(nodes) && !(nodes instanceof NodeList)) {
                nodes = [nodes]
            }

            for (const node of nodes) {
                if (node) {
                    if (node.nodeName !==  '#document-fragment' && keepClass) {
                        node.classList.forEach(clazz => {
                            if(!markNode.classList.contains(clazz)) {
                                markNode.classList.add(clazz)
                            }
                        })
                    }
                    node.parentNode.replaceChild(markNode, node)
                }
                else error(`Can not restore ${this.constructor.name} because can not found ${this.constructor.name} Node`)
            }
        }
    }
}


export default BaseComponent
