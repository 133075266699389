export class UseN11s {
    static DISABLE = 0  // 不使用通知
    static ENABLE  = 1  // 使用通知
    static ENABLE_ONLY_SELF   = 2  // 使用通知但只限自己產品
    static ENABLE_ONLY_OTHERS = 3  // 使用通知但只限其他產品
}

export class DrawWhen {
    static LOADING   = 'LOADING'
    static DOM_READY = 'DOMREADY'
}