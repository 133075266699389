import BaseComponent               from '@/components/base-component'
import { notifyTemp}               from '@/templates/notify-temp'
import { domMixin, eventMixin }    from '@/mixins'
import { stringToDom, diffNowDesc} from '@/utils/tools'
import * as SystemEvents           from '@/constants/system-event'
import * as HeybarEvents           from '@/constants/heybar-event'
import * as modeHelper             from '@/helper/heybar-mode-helper'
import barCss                      from '~css/bar.scss'
// import defaultAvatar               from '~img/img-default-avatar-pre-login.svg'
// import defaultAvatar            from '~lib/2022_moon_DefaultAvatar.svg'  // 節日彩蛋使用 (中秋節)
// import defaultAvatar            from '~lib/img_Xmas_DefaultAvatar.svg'  // 聖誕節
// import defaultAvatar            from '~lib/2023_new_year_DefaultAvatar.svg'  // 2023新年

const defaultAvatar = `${process.env.SERVER_URL}/img/img-default-avatar-pre-login.svg`
// const defaultAvatar = `${process.env.SERVER_URL}/lib/img_Xmas_DefaultAvatar.svg` // 節日彩蛋使用 (聖誕節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2022_moon_DefaultAvatar.svg` // 節日彩蛋使用 (中秋節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2023_DBF_DefaultAvatar.svg`  // 節日彩蛋使用 (2023端午節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2023_new_year_DefaultAvatar.svg`

const {debug, error}           = require('@/utils/logger')(__filePath, __fileName, '#00AAAA')
const NOTIFY_ID                = 'heybarNotify'
const NOTIFY_OPEN_COOKIE_KEY   = '__heybar_open_notify__'
const NOTIFY_TAB_NOTICE        = 'notice'
const NOTIFY_TAB_GROUP         = 'group'
const NOTIFY_TAB_OTHER         = 'other'
const NOTIFY_DATE_CN           = 'heybarNotifyDate'
const NOTIFY_ARROW_UP_ID       = 'heybarNotifyArrowUp'
const GROUP_TYPE_GROUP         = 'group'
const GROUP_TYPE_MIX           = 'mix'
const NOTIFY_TYPE_NOTICE       = "0"
const NOTIFY_TYPE_BULLETIN     = "2"
const NOTIFY_TYPE_GROUP_NOTICE = "1"

class Notify extends BaseComponent {
    static MARK_TAG      = 'notify'
    static ITEM_MARK_TAG = 'notify-item'

    constructor(mainNode, config, domain) {
        super(mainNode, config)
        this.isOpen      = false  // 通知畫面是否已開啟
        this.domain      = domain
        this.activeTab   = NOTIFY_TAB_NOTICE
        this.productName = ''        // 在取回初始資料後才補回
        this.productId   = null      // 在取回初始資料後才補回
        this.groupId     = null      // 在取回登入資料後才補回
        this.groupName   = ''        // 在取回登入資料後才補回
        this.groupDesc   = ''        // 在取回登入資料後才補回
        this.useHeadshot = false     // 在取回登入資料後才補回
        this.defaultHeadshot = null  // 在取回登入資料後才補回
        this.groups      = null      // user 於當下產品所擁有的所有群組資訊，在 helper 初始化完成後才補回
        this.productsMap = null      // 所有產品的設定資訊，在 helper 初始化完成後才補回
        this.useN11s     = null      // 產品使用通知的類型 (0:不啟用/1:啟用/2:只啟用當前產品/3:只啟用其他產品)。在取回初始資料後才補回。

        this.__eventClose4BodyTemp  = this.eventClose4Body.bind(this)
        this.otherItemUpperObserver = null
        this.__isInitComputeNotifyTabBoxHeight = false
    }

    //========GET NODE AREA========================================================================================
    /** 取得主要的通知node */
    _getNotifyNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss.notice)
    }

    /** 取得開啟/關閉通知視窗的"開關"node */
    _getSwitchNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, barCss.barIcon, `.${barCss.barIcon_notice}`)
    }

    _getTabNode(anchor = this.mainNode, tab = this.activeTab) {
        return this.findByClassName(anchor, `${tab}-tab`)
    }

    _getTabNodeList(anchor = this.mainNode) {
        return this.findAllByClassName(anchor, barCss['notice-tag'])
    }

    _getTabListNode(anchor = this.mainNode, tab = this.activeTab) {
        return this.findByClassName(anchor, `${tab}-tabListNode`)
    }

    _getTabListNodeList(anchor = this.mainNode) {
        return this.findAllByClassName(anchor, barCss["notice-tabList"])
    }

    /**  取得指定 tab list 內的所有通知 item node 內的 detail node */
    _getTabItemDetailNodeList(anchor = this.mainNode, tab = this.activeTab) {
        return this.findAllById(anchor, `${tab}-tabListNode`, ` .${barCss['notice-tabItem']}:not(.${barCss.nothing}) .${barCss['notice-detail']}`)
    }

    /** 取得"無資料" node */
    _getNoDataNode(anchor = this.mainNode, tab=this.activeTab) {
        return this.findByClassName(anchor, `${tab}-tabListNode`, ` .${barCss['notice-tabItem']}.${barCss.nothing}`)
    }

    /** 取得 observer node */
    _getObserverNode(anchor = this.mainNode, tab = this.activeTab) {
        return this.findByClassName(anchor, `${tab}-tabListNode`, ` div[ref=infiniteScroll]`)
    }

    /** 取得 loading node */
    _getLoadingNode(anchor = this.mainNode, tab = this.activeTab) {
        return this.findByClassName(anchor, `${tab}-tabListNode`, ` footer`)
    }

    /** 取得目前通知區塊上所有通知/公告內的通知時間區塊 */
    _getAllNotifyCreateTimeNodeList() {
        return this.findAllByClassName(this.mainNode, NOTIFY_DATE_CN)
    }

    _getBottomObserverNode(anchor = this.mainNode) {
        return this.findByClassName(anchor, `${barCss['notice-tabBox']} > .bottom-observer`)
    }


    //======== 通用方法區 ==========================================================================================
    getProductName(product_id) {
        if (this.productsMap) {
            const product = this.productsMap[product_id]
            if (product && product.name && product.name.length > 0) {
                return product.name
            }
        }
        return product_id
    }

    getGroupName(group_id, groups) {
        if (group_id && groups) {
            const group_ids = group_id.split(/,\s*/)
            for (const group of groups) {
                if (group.id === group_ids[0]) {
                    return group.name
                }
            }
        }
        return group_id
    }

    //======== 事件區  =============================================================================================  
    _bindEvent(container) {
        // 開啟/關閉通知
        const switchNode = this._getSwitchNode(container)
        if (switchNode) {
            switchNode.onclick = evt => {
                if (this.isOpen) this.eventClose(evt)
                else this.eventOpen(evt)
            }
        } else error('Notify switch event FAIL because can not found any switch node')

        // tab 切換
        const tabNodeList = this._getTabNodeList(container)
        if (tabNodeList) tabNodeList.forEach(node => {node.onclick = evt => { this.eventTabChange(evt) }})
        else error('Notify bind tab change event FAIL because can not found any tab node')

        // 點擊頁面關閉通知
        window.removeEventListener('click', this.__eventClose4BodyTemp)
        window.addEventListener('click', this.__eventClose4BodyTemp)

        // 避免點擊通知區塊觸發 eventClose4Body 而關閉通知區塊
        const notifyNode = this._getNotifyNode(container)
        notifyNode.onclick = evt => {evt.stopPropagation()}

        let timer = null
        let offsetHight = 0
        this.bottomObserver = new IntersectionObserver((entries, observer) => {
            // 只有通知開啟時才運作
            if (!this.isOpen || !modeHelper.isMobileMode(this.mainNode)) {
                // console.log('-----> is NOT OPEN')
                return
            }
            // console.log('--->>1', entries[0].isIntersecting)
            // console.log('--->>2', entries[0].intersectionRatio)
            if (!entries[0].isIntersecting) {
                const x = this._getBottomObserverNode()
                
                timer = setInterval(() => {
                    offsetHight ++
                    x.style.height = offsetHight + 'px'
                    
                }, 10)
            } else {
                clearInterval(timer)
            }
        }, {threshold: [0, 1]})

    }

    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return // 已是開啟的情況下就不用再執行了

        // 開啟通知區塊
        const notifyNode = this._getNotifyNode()
        if (notifyNode) notifyNode.classList.remove(barCss.closeBox)
        else error('can not found notify node, open notify panel fail.')

        // 小鈴鐺(switch node)要設成橘色
        const switchNode = this._getSwitchNode()
        if (switchNode) switchNode.classList.add(barCss.barIcon_notice_selected)
        else error('can not found switch node, change selected color fail.')

        // =====> 節日彩蛋使用 START <=============================================================
        // switchNode.setAttribute('style', "background-size: 0px !important")
        // this.lottieRing.play()
        // =====> 節日彩蛋使用 END <=============================================================

        // 顯示三角箭號
        // const arrowUpNode = this._getArrowUpNode()
        // if (arrowUpNode) arrowUpNode.classList.remove(barCss['border-up--close'])
        // else error('can not found notify arrow up node, open notify panel fail.')

        this.isOpen = true
        // this.fire(`${this.SYSTEM_EVENT_PREFIX}open`)
        // this.fire('open')
        const unreadThis = this.unreadAmountMap[NOTIFY_TAB_NOTICE] || 0
        const unreadAll  = (this.unreadAmountMap[NOTIFY_TAB_NOTICE] || 0) + (this.unreadAmountMap[NOTIFY_TAB_GROUP] || 0) + (this.unreadAmountMap[NOTIFY_TAB_OTHER] || 0)
        this.emit(SystemEvents.OPEN)
        this.fire(HeybarEvents.OPEN, {unreadThis, unreadAll})

        const tabBoxNode = this.findByClassName(this.mainNode, barCss['notice-tabBox'])
        // console.log('tagBoxNode::', tabBoxNode)
        // console.log('isInitComputeNotifyTabBoxHeight::', this.__isInitComputeNotifyTabBoxHeight)
        // console.log('navigator::', navigator)
        // console.log('navigator userAgent::', navigator.userAgent)
        // console.log('Safari', navigator.userAgent.indexOf('Safari'))
        // console.log('iPhone', navigator.userAgent.indexOf('iPhone'))
        // if (tabBoxNode && !this.__isInitComputeNotifyTabBoxHeight && navigator.userAgent.indexOf('Safari') >= 0 && navigator.userAgent.indexOf('iPhone') >= 0) {
        //     // console.log('---> Match tabbox node conditions')
        //     this.__isInitComputeNotifyTabBoxHeight = true
        //     tabBoxNode.style.height = '8000px'
        //     // console.log('--->Success Set tabbox node height')
        //     setTimeout(() => { 
        //         // console.log('--->Set tabbox node height to null')
        //         tabBoxNode.style.height = null
        //         // console.log('--->Success clean tabbox node height')
        //         // 避免"其他通知/其他公告"被手機瀏覽器下方的工具列遮住，在通知開啟時要進行偵測，若被遮住要抬升
        //         const otherItemNode = this.findByClassName(this.mainNode, barCss['notice-btnLink'], `.${barCss.topLine}`)
        //         if (this.otherItemUpperObserver && otherItemNode) this.otherItemUpperObserver.observe(otherItemNode)
        
        //     }, 500)
        // } else {
        //     // console.log('---> xx NOT Match tabbox node conditions')
        //     // 避免"其他通知/其他公告"被手機瀏覽器下方的工具列遮住，在通知開啟時要進行偵測，若被遮住要抬升
        //     const otherItemNode = this.findByClassName(this.mainNode, barCss['notice-btnLink'], `.${barCss.topLine}`)
        //     if (this.otherItemUpperObserver && otherItemNode) this.otherItemUpperObserver.observe(otherItemNode)
        // }
        this.bottomObserver.observe(this._getBottomObserverNode())
    }

    eventClose(evt, stopPropagation=true) {
        if (evt && stopPropagation) evt.stopPropagation()
        if (!this.isOpen) return // 已是關閉的情況下就不用再執行關閉了

        // this.fire('beforeClose')
        this.fire(HeybarEvents.BEFORE_CLOSE)
        
        // 關閉通知視窗
        const notifyNode = this._getNotifyNode()
        if (notifyNode) notifyNode.classList.add(barCss.closeBox)
        else error('can not found notify node, close notify panel fail.')

        // 小鈴鐺(switch node)要拿掉橘色
        const switchNode = this._getSwitchNode()
        if (switchNode) switchNode.classList.remove(barCss.barIcon_notice_selected)
        else error('can not found switch node, change unselect color fail.')

        // =====> 節日彩蛋使用 START <=============================================================
        // this.lottieRing.goToAndStop(0)
        // =====> 節日彩蛋使用 END <=============================================================

        // 隱藏三角箭號
        // const arrowUpNode = this._getArrowUpNode()
        // if (arrowUpNode) arrowUpNode.classList.add(barCss['border-up--close'])
        // else error('can not found notify arrow up node, close notify panel fail.')

        this.isOpen = false
        // this.fire('close')
        this.emit(SystemEvents.CLOSE)
        this.fire(HeybarEvents.CLOSE)

        if (this.bottomObserver) this.bottomObserver.unobserve(this._getBottomObserverNode())
    }

    eventClose4Body(evt) {
        this.eventClose(evt, false)
    }

    eventTabChange(evt) {
        if (evt) evt.stopPropagation()

        if (!evt.triggerType || evt.triggerType !== 'init') {
            // 觸發事件
            // this.fire('beforeTabChange', this.activeTab)
            this.fire(HeybarEvents.BEFORE_TAB_CHANGE, this.activeTab)
        }
        
        // 設定被點選的 tab/tabList 亮起來/顯示出來，其他的tab/tabList要暗掉/隱藏
        // (2023-08-14 加)
        let tab = NOTIFY_TAB_NOTICE
        if (evt.currentTarget.classList.contains('group-tab')) {
            tab = NOTIFY_TAB_GROUP
        } else if (evt.currentTarget.classList.contains('other-tab')) {
            tab = NOTIFY_TAB_OTHER
        }
        this.setActiveTab(tab)


        // (2023-08-14 刪除)
        // const tabNodeList = this._getTabNodeList()
        // if (tabNodeList) tabNodeList.forEach(tabNode => {tabNode.classList.remove(barCss.active)})
        // else error('Can not inactive tab nodes because can not find them.')
        
        // const tabListNodeList = this._getTabListNodeList()
        // if (tabListNodeList) tabListNodeList.forEach(node => {node.style.display = 'none'})
        // else error('Can not hidden tab list node because can not find them.')

        // if (evt.currentTarget.classList.contains('group-tab')) {
        //     this.activeTab = NOTIFY_TAB_GROUP
        // } else if (evt.currentTarget.classList.contains('other-tab')) {
        //     this.activeTab = NOTIFY_TAB_OTHER
        // } else {
        //     this.activeTab = NOTIFY_TAB_NOTICE
        // }

        // const tabNode = this._getTabNode()
        // if (tabNode) tabNode.classList.add(barCss.active)
        // else error('Can not active tab node because can not find it.')

        // const tabListNode = this._getTabListNode()
        // if (tabListNode) tabListNode.style.display = null
        // else error('Can not show tab list node because can not find it.')

        if (!evt.triggerType || evt.triggerType !== 'init') {
            // 觸發事件
            // this.fire('tabChange', this.activeTab)
            this.fire(HeybarEvents.TAB_CHANGE, this.activeTab)
        }
    }

    //======== 繪製區  =============================================================================================
    // =====> 節日彩蛋使用 START <=============================================================
    // draw(...params) {
    //     super.draw(...params)
    //     const switchNode = this._getSwitchNode()
    //     if (switchNode) switchNode.setAttribute('style', "background-size: 0px !important")
        
    //     this.lottieRing = lottie.loadAnimation({
    //         container: document.querySelector('#heybarNotifyRing'),
    //         renderer: 'svg',
    //         loop: false,
    //         autoplay: false,
    //         name: 'heybarNotifyRingAnim',
    //         path: `${process.env.SERVER_URL}/lib/2022_moon_bell.json` // (中秋節)
    //         // path: `${process.env.SERVER_URL}/lib/lf30_xk4wdbaf.json` // (聖誕節)
    //         // path: `${process.env.SERVER_URL}/lib/2024_new_year_bell.json` // (2024新年)
    //     })
    //     this.lottieRing.addEventListener('DOMLoaded', () => {
    //         const ringSvg = document.querySelector('#heybarNotifyRing svg')
    //         if (ringSvg) {
    //             ringSvg.setAttribute('class', barCss['festival_ring'])
    //             // ringSvg.style.display = 'none'
    //         }
    //     })
    // }
    // =====> 節日彩蛋使用 END <=============================================================
    _generateNode() {
        let template = notifyTemp()
        template = template.replace(/{{domain}}/g, `${this.domain ? this.domain : ''}`)
        template = template.replace(/{{groupDesc}}/g, this.groupDesc ? this.groupDesc : '其他公司')
        template = template.replace(/{{productName}}/g, this.productName || '通知')
        const notifyNode = stringToDom(template)

        // 取得第1層通知 title 區塊
        // const notifyTitleNode = this._getNotifyTitleNode(notifyNode)
        // notifyTitleNode.innerHTML = this._combineTitleName(this.productName, this.groupName)
        

        return notifyNode
    }

    /**
     * 加入一則通知/公告到指定層級的列表中
     * 
     * TODO: 可考慮多傳 productId 與 groupId 避免第三層快速切換時，有可能 appendNotice 已經不是當初的目標了
     * 
     * @param {string} tab 要繪製的是通知(notice)或公告(bulletin)
     * @param {object} notice 通知物件(json)
     * @param {object} content 通知內容
     * @param {object} extension 通知的額外資訊內容
     * @param {string} loc 該則通知加入的方向，head: 加到最上面，tail: 加到最下面
     */
     appendNotice(tab, notice, content, extension, link, handler, loc = 'tail') {
        if (!content) {
            error('Can not appned %s when content is null', tab)
            return
        }

        if (!extension) debug("%s's extension is null", tab)


        // 如果有 "無通知" 的 node 存在則隱藏它
        this.showNodData(tab, false)
        

        // 建出一筆通知
        const noticeNode = document.createElement('div')
        noticeNode.classList.add(barCss['notice-tabItem'])
        noticeNode.setAttribute('id', notice.id)
        // let tabDesc = '個人'
        // if (tab === NOTIFY_TAB_GROUP) tabDesc = `${this.groupDesc ? this.groupDesc : "其他公司"}`
        // else if (tab === NOTIFY_TAB_OTHER) tabDesc = '其他產品'
        // noticeNode.dataset.gtmNotify = `通知區塊-內文-${tabDesc}`
        
        if (tab === NOTIFY_TAB_GROUP) {
            noticeNode.dataset.gtmWhitebar = 'read_notify-list-others-cmy'
            noticeNode.dataset.gtmValue    = extension && extension.receiver_group_name && extension.receiver_group_name !== '' ? extension.receiver_group_name : notice.receiver_group 
        } else if (tab === NOTIFY_TAB_OTHER) {
            noticeNode.dataset.gtmWhitebar = 'read_notify-list-others'
            noticeNode.dataset.gtmValue    = notice.receiver_product
        } else {
            noticeNode.dataset.gtmWhitebar = 'read_notify-list-this'
        }

        // if (notice.is_read) {
        //     noticeNode.classList.add(barCss.readed)
        // }

        if (link) {
            noticeNode.onclick = () => { window.open(link) }
            noticeNode.style.cursor = 'pointer'
        } else if (handler) {
            noticeNode.onclick = () => {handler()}
            noticeNode.style.cursor = 'pointer'
        }

        // 有大頭照則加入大頭照，在通知下，若沒大頭照但有傳入預設大頭照且設定成使用大頭照時，則顯示預設的大頭照，但如果是公告，則沒大頭照就不秀預設頭像
        // (此規則不管有沒匿名的條件，匿名的條件是在此規則成立後，往下執行的子規則中)
        // 總規則如下：
        //     (沒有 sender 大頭照) --> 使用產品預設大頭照 --> (沒有產品預設大頭照) --> 不顯示大頭照
        //     (有 sender 大頭照) --> (不是匿名) --> 顯示 sender 大頭照
        //                       --> (是匿名) --> (有產品預設大頭照) --> 顯示產品預設大頭照
        //                                   --> (沒有產品預設大頭照) --> 顯示 heybar 預設大頭照
        if (extension.sender_headshot || (notice.notice_type !== NOTIFY_TYPE_BULLETIN && this.defaultHeadshot && this.useHeadshot)) {
            const headshotDivNode = document.createElement('div')
            const headshotImgNode = document.createElement('img')
            let headshot = null
            // 在有大頭照的前提下
            if (extension.sender_headshot) {
                // 如果該則通知是匿名
                if (notice.anonymous) {
                    // 如果有產品預設大頭照且要使用產品預設大頭照的話，就顯示產品大頭照，不然的話就使用 heybar 預設大頭照
                    if (this.defaultHeadshot && this.useHeadshot) {
                        headshot = this.defaultHeadshot
                    } else {
                        headshot = defaultAvatar
                    }
                // 如果不是匿名，就秀 sender 的大頭照
                } else {
                    headshot = extension.sender_headshot
                }
            // 如果沒有大頭照的話，程式能 run 到這邊表示滿足 (notice.notice_type !== NOTIFY_TYPE_BULLETIN && this.defaultHeadshot && this.useHeadshot)
            // 因此顯示產品大頭照即可
            } else {
                headshot = this.defaultHeadshot
            }

            // headshotDivNode.className = barCss['notice-logoImg']
            headshotDivNode.className = barCss['notice-avatar']
            headshotImgNode.setAttribute('src', headshot)
            // headshotImgNode.setAttribute('onerror', `this.onerror=null;this.src=${this.defaultHeadshot}`)
            
            // 若有大頭照且有輸入預設大頭照，則設定 img 的 onerror，讓大頭照失效時能顯示預設大頭照 (限定為 notice 才有)
            // if (tab !== NOTIFY_TYPE_BULLETIN && extension.sender_headshot && this.config.defaultHeadshot) {
            if (this.defaultHeadshot) {
                headshotImgNode.onerror = () => {
                    debug('img (%s) onerror', headshotImgNode.src)
                    headshotImgNode.onerror = null
                    headshotImgNode.src = this.defaultHeadshot
                }
                // 監聽 img 的 onload 事件是因為當 img 404 時，若 img 的 server 返回的 content type 是 image
                // 則 img tag 會以為有收到 image 而忽略 404 導致 onerror 不會觸發
                // 為解決此問題，則要監聽 onload 事件，並在判斷載入的圖片高寬小於 1 時認定為無效的圖，改用預設圖
                headshotImgNode.onload = () => {
                    // debug('img (%s) onload, width:(%s), height:(%s), offsetHeight:(%s), offsetWidth(%s)', headshotImgNode.src, headshotImgNode.height, headshotImgNode.width, headshotImgNode.offsetHeight, headshotImgNode.offsetWidth)

                    if (headshotImgNode.height <= 1 && headshotImgNode.width <= 1 && headshotImgNode.offsetHeight <= 1 && headshotImgNode.offsetWidth <= 1) {
                        headshotImgNode.onload = null
                        headshotImgNode.src = this.defaultHeadshot
                    }
                    
                }
            }
            headshotDivNode.appendChild(headshotImgNode)
            noticeNode.appendChild(headshotDivNode)
        }

        // 內文 && 通知人/通知日期
        const noticeBodyNode = document.createElement('div')
        const noticeTextNode = document.createElement('div')
        const noticeTailNode = document.createElement('div')

        noticeBodyNode.className = barCss['notice-detail']
        noticeTextNode.className = barCss['notice-txtLg']
        noticeTailNode.className = barCss['notice-txtSm']

        if (!notice.is_read) {
            noticeBodyNode.classList.add(barCss.yellowDot)
        }

        const spanNode = document.createElement('span')
        // spanNode.className = barCss['notice-txtBlue']
        spanNode.appendChild(content)
        noticeTextNode.appendChild(spanNode)

        let senderName = null
        switch(tab) {
            case NOTIFY_TAB_OTHER:
                senderName = this.getProductName(notice.receiver_product)
                break
            case NOTIFY_TAB_GROUP:
                senderName = this.getGroupName(notice.receiver_group, this.groups)
                break
        }

        noticeTailNode.innerHTML = (senderName ? `<span style="text-overflow:ellipsis;max-width:150px;overflow:hidden;white-space:nowrap;float:left" title=${senderName}>${senderName}</span> ｜ ` : '') +
                            `<span class="${NOTIFY_DATE_CN}" data-date="${notice.create_date}">${diffNowDesc(notice.create_date)}</span>`

        noticeBodyNode.appendChild(noticeTextNode)
        noticeBodyNode.appendChild(noticeTailNode)
        noticeNode.appendChild(noticeBodyNode)


        if (loc === 'head') {
            const noticeTabListNode = this._getTabListNode(this.mainNode, tab)
            if (noticeTabListNode) {
                if (noticeTabListNode.firstChild) noticeTabListNode.insertBefore(noticeNode, noticeTabListNode.firstChild)
                else noticeTabListNode.appendChild(noticeNode)
            } else {
                error('Can not found %s tab list node, append notice fail!!', tab)
            }
            
        } else {
            const observerNode = this._getObserverNode(this.mainNode, tab)
            if (observerNode) observerNode.parentNode.insertBefore(noticeNode, observerNode)
        }
    }
    

    //===== 操作區 ========================================================================================================

    showTab(tab) {
        const tabNode = this._getTabNode(this.mainNode, tab)
        if (tabNode) tabNode.style.display = null
        else error(`hide tab node fail. can not found ${tab} TAB NODE.`)
    }

    showLoading(tab, isShow) {
        const loadingNode = this._getLoadingNode(this.mainNode, tab)
        loadingNode.style.display = isShow ? null : 'none'
    }

    showNodData(tab, isShow) {
        const noDataNode = this._getNoDataNode(this.mainNode, tab)
        if (noDataNode) noDataNode.style.display = isShow ? null : 'none'
    }

    /**
     * 顯示/隱藏 tab 上的紅泡泡
     * @param {string} tab 
     * @param {boolean} isShow 
     */
    showTabNewBadge(tab, isShow) {
        const tabNode = this._getTabNode(this.mainNode, tab)
        if (tabNode) isShow ? tabNode.classList.add(barCss.redDot) : tabNode.classList.remove(barCss.redDot)
        else error(`set tab red dot fail. tab node(${tab}) can not found`)
    }

    showSwitchNewBadge(isShow) {
        const switchNode = this._getSwitchNode()
        if (switchNode) isShow ? switchNode.classList.add(barCss.barIcon_notice__redDot) : switchNode.classList.remove(barCss.barIcon_notice__redDot)
    }

    /**
     * 抓取 observer(判斷是否捲到通知/公告 tabList 最下面的區塊，以進行讀取下一頁)
     * 
     * @param {string}  tab    要抓那個 tab 內的 observer 圖示(notice/group/other)
     */
     getObserverNode(tab) {
        return this._getObserverNode(this.mainNode, tab)
    }

    /**
     * 更新所有通知/公告的顯示時間
     * (因為顯示時間是用距離現在多久的描述，所以要隨時間刷新)
     */
     refreshNoticeTime() {
        debug('=== refresh notice time =======')
        const dateNodeList = this._getAllNotifyCreateTimeNodeList()

        for (const dateNode of dateNodeList) {
            const date = dateNode.dataset.date
            dateNode.innerHTML = diffNowDesc(date)
        }
    }

    /**
     * 關閉指定層級/tab內的所有通知/公告的未讀紅點
     */
     cleanAllNoticeItemNewBadge(tab = this.activeTab) {
        const itemDetailNodeList = this._getTabItemDetailNodeList(this.mainNode, tab)
        if (itemDetailNodeList) itemDetailNodeList.forEach(node => {node.classList.remove(barCss.yellowDot)})
    }

    clean() {
        // 移掉頁面上的 close notify panel 事件
        document.body.removeEventListener('click', this.__eventClose4BodyTemp)
        // 將 notify 從 heybar 上移除
        const notifyNode = this._getNotifyNode()
        const notifyMark = document.createElement(this.constructor.MARK_TAG)
        if (notifyNode) notifyNode.parentNode.replaceChild(notifyMark, notifyNode)
        else error('Can not clean Notify because can not found Notify Node.')

        // 將 activeTab 設回初始值 (因為整個 notify dom 都拿掉了，所以不用考慮畫面的切換)
        this.activeTab   = NOTIFY_TAB_NOTICE
        // 將 isOpen 設回初始值
        this.isOpen      = false
    }
    destroy() {
        this.clean()
        this.cleanHandlers()
    }

    /**
     * 2023-08-14 新加
     * 設定指定的 tab/tabList 亮起來/顯示出來，其他的tab/tabList要暗掉/隱藏
     * 
     * @param {string} tab 
     */
    setActiveTab(tab) {
        // 先移除所有 tab node 的 active css
        const tabNodeList = this._getTabNodeList()
        if (tabNodeList) tabNodeList.forEach(tabNode => {tabNode.classList.remove(barCss.active)})
        else error('Can not inactive tab nodes because can not find them.')
        // 將所有 tab node 所對應的 tabList node 隱藏起來
        const tabListNodeList = this._getTabListNodeList()
        if (tabListNodeList) tabListNodeList.forEach(node => {node.style.display = 'none'})
        else error('Can not hidden tab list node because can not find them.')

        // 設定 this.activeTab
        this.activeTab = tab

        // 將 active 的 css 加到指定的 tab node 上
        const tabNode = this._getTabNode()
        if (tabNode) tabNode.classList.add(barCss.active)
        else error('Can not active tab node because can not find it.')

        // 將 activeTab 對應到的 tabList Node 設為可視
        const tabListNode = this._getTabListNode()
        if (tabListNode) tabListNode.style.display = null
        else error('Can not show tab list node because can not find it.')
    }

    hideTabArea() {
        const tabAreaNode = this.findByClassName(this.mainNode, barCss['notice-tabHead'])
        if (tabAreaNode) tabAreaNode.style.display = 'none'
    }

}

Object.assign(Notify.prototype, domMixin(), eventMixin())
Notify.id = NOTIFY_ID
Notify.OPEN_NOTIFY_KEY = NOTIFY_OPEN_COOKIE_KEY
Notify.NOTIFY_TAB_NOTICE = NOTIFY_TAB_NOTICE
Notify.NOTIFY_TAB_GROUP = NOTIFY_TAB_GROUP
Notify.NOTIFY_TAB_OTHER = NOTIFY_TAB_OTHER

export default Notify
