import BaseComponent            from '@/components/base-component'
import { domMixin, eventMixin } from '@/mixins'
import * as HeybarEvents        from '@/constants/heybar-event'
import * as modeHelper          from '@/helper/heybar-mode-helper'
import barCss                   from '~css/bar.scss'


const { debug, error } = require('@/utils/logger')(__filePath, __fileName, '#44CCFF')

const ONE_WORD_BTN_ID = 'heybarOneWordBtn'

class OneWordBtn extends BaseComponent {
    static MARK_TAG = 'one-word-btn'

    constructor(mainNode, config) {
        super(mainNode, config, ['oneWord', 'oneWordAction'])
    }

    _generateNode() {
        const oneWordBtnNode = document.createElement('div')
        oneWordBtnNode.className           = barCss.askArea
        oneWordBtnNode.id                  = ONE_WORD_BTN_ID
        // oneWordBtnNode.dataset.gtmSubParam = '自訂功能鈕'
        oneWordBtnNode.dataset.gtmWhitebar = 'read_tools-action-btn'
        oneWordBtnNode.innerHTML           = this.oneWord
        // const oneWordBtnNode = stringToDom(`<div style='height:32px;width:32px;border-radius: 50% 0 0 50%;background-color: #ff9100;'></div><div class='${barCss.askArea}'><span style='white-space:nowrap'>${this.oneWord}<span></div><div style='height:32px;width:32px;border-radius: 0 50% 50% 0;background-color: #ff9100;'></div`)
        

        return oneWordBtnNode
    }

    _bindEvent(container) {
        container.onclick = () => {
            // this.fire('click')
            this.fire(HeybarEvents.CLICK)
            if (this.oneWordAction) {
                this.oneWordAction()
            }
        }

        const oneWordBtnObserver = new IntersectionObserver(entries => {
            // console.log('##########aaaa;;;;bbb;;;', entries[0])
            if (modeHelper.isMobileMode(this.mainNode)) {
                
                // 不能設 display='none' 因為這會讓 Node 在頁面上消失，導致之後就不再觸發 IntersectionObserver 了
                if (entries[0].isIntersecting) {
                    entries[0].target.style.visibility = 'visible'
                } else {
                    entries[0].target.style.visibility = 'hidden'
                }
            }
            
        }, {threshold: 1})

        // 一開始還沒畫出來就啟動監控的話，會導致 isIntersecting 為 false 而讓一開始就隱藏，因此延遲 500ms 再啟動監控，確保一字鈕已經畫到頁面上。
        setTimeout(() => {oneWordBtnObserver.observe(container)}, 500)
        
    }

    clean() {
        this.restore()
    }
}

Object.assign(OneWordBtn.prototype, domMixin(), eventMixin())

OneWordBtn.id = ONE_WORD_BTN_ID

export default OneWordBtn