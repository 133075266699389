import BaseComponent            from '@/components/base-component'
import { personalTemp }         from '@/templates/personal-temp'
import { stringToDom }          from '@/utils/tools'
import { domMixin, eventMixin } from '@/mixins'
import * as SystemEvents        from '@/constants/system-event'
import * as HeybarEvents        from '@/constants/heybar-event'
import barCss                   from '~css/bar.scss'
// import arrowRight               from '~img/icon-arrow-right-blue.png' 
// import defaultAvatar            from '~img/img-default-avatar-pre-login.svg'
// import defaultAvatar            from '~lib/2022_moon_DefaultAvatar.svg'  // 節日彩蛋使用 (中秋節)
// import defaultAvatar            from '~lib/img_Xmas_DefaultAvatar.svg'  // 聖誕節
// import defaultAvatar            from '~lib/2023_new_year_DefaultAvatar.svg'  // 2023新年

const { debug, error } = require('@/utils/logger')(__filePath, __fileName, '#BBBB01')

const arrowRight    = `${process.env.SERVER_URL}/img/icon-arrow-right-blue.png`
const defaultAvatar = `${process.env.SERVER_URL}/img/img-default-avatar-pre-login.svg`
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2023_DBF_DefaultAvatar.svg`  // 節日彩蛋使用 (2023端午節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2022_moon_DefaultAvatar.svg`  // 節日彩蛋使用 (中秋節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/img_Xmas_DefaultAvatar.svg` // 節日彩蛋使用 (聖誕節)
// const defaultAvatar = `${process.env.SERVER_URL}/lib/2023_new_year_DefaultAvatar.svg` // 節日彩蛋使用 (2024新年)

const PERSONAL_ID                            = 'heybarPersonal'
const LOGIN_LINK_CN                          = 'heybarLoginLink'
const LOGOUT_LINK_ID                         = 'heybarLogoutLink'
const PERSONAL_MARK_TAG                      = 'personal'
const PRODUCT_FUNCTION_MARK_TAG              = 'product-function'
const PERSONAL_OPEN_ID                       = 'heybarPersonalOpen'
const PERSONAL_CLOSE_ID                      = 'heybarPersonalClose'
const PERSONAL_CONTENT_CN                    = 'heybarPersonalContent'
const PERSONAL_AVATAR_ID                     = 'heybarPersonalAvatar'
// const PERSONAL_ARROW_UP_ID                   = 'heybarPersonalArrowUp'
const PERSONAL_LINKS_BEFORE_LOGIN_ID         = 'heybarPersonalLinksBeforeLogin'
const PERSONAL_LINKS_BEFORE_LOGIN_OPEN_ID    = 'heybarPersonalLinksBeforeLoginOpen'
const PERSONAL_LINKS_BEFORE_LOGIN_CONTENT_ID = 'heybarPersonalLinksBeforeLoginContent'
const PERSONAL_INFO_ID                       = 'heybarPersonalInfo'
const PERSONAL_HEADSHOT_ID                   = 'heybarPersonalHeadshot'
const PERSONAL_NAME_ID                       = 'heybarPersonalName'
const PERSONAL_NAME_ON_BAR_ID                = 'heybarPersonalNameOnBar'

class Personal extends BaseComponent {
    static MARK_TAG = PERSONAL_MARK_TAG
    
    constructor(mainNode, config, domain) {
        super(mainNode, config, ['login', 'logout', 'linksBeforeLogin', 'linksBeforeLoginAction', 'loginSlogan', 'loginText', 'logoutText', 'loginPrompt', 'hideLogoutLink'])
        this.linksBeforeLogin = this.linksBeforeLogin && this.linksBeforeLogin.length > 0 ? this.linksBeforeLogin : null
        this.domain           = domain
        this.isOpen           = false
        this.isLogin          = false

        this.__eventClose4BodyTemp            = this.eventClose4Body.bind(this)
        this.__eventCloseLinksBeforeLoginTemp = this.eventCloseLinksBeforeLogin.bind(this)
    }

    _findMarkNode() {
        return this.findByTag(this.mainNode, PERSONAL_MARK_TAG)
    }

    // _findPersonalNode() {
    //     return this.findById(this.mainNode, PERSONAL_ID)
    // }

    _findLoginLinkNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        // return this.findById(anchor, PERSONAL_ID, `.${LOGIN_LINK_CN}`)
        return this.findByClassName(anchor, LOGIN_LINK_CN)
    }

    _findLogoutLinkNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, LOGOUT_LINK_ID)
    }

    _findProductFuncMarkNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findByTag(anchor, PRODUCT_FUNCTION_MARK_TAG)
    }

    _findPersonalOpenNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_OPEN_ID)
    }

    _findPersonalCloseNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_CLOSE_ID)
    }

    _findPersonalContentNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findByClassName(anchor, PERSONAL_CONTENT_CN)
    }

    _findPersonalAvatarNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_AVATAR_ID)
    }

    _findPesonalNameInBarNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_OPEN_ID)
    }

    // _findPersonalArrowUpNode(anchor) {
    //     anchor = anchor ? anchor : this.mainNode
    //     return this.findById(anchor, PERSONAL_ARROW_UP_ID)
    // }

    _findPersonalLinksBeforeLoginNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_LINKS_BEFORE_LOGIN_ID)
    }

    _findPersonalLinksBeforeLoginOpenNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_LINKS_BEFORE_LOGIN_OPEN_ID)
    }

    _findPersonalLinksBeforeLoginContentNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_LINKS_BEFORE_LOGIN_CONTENT_ID)
    }

    _findPersonalInfoNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_INFO_ID)
    }

    _findPersonalSmallHeadshotNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, 'heybarSmall')
    }

    _findPersonalHeadshotNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_HEADSHOT_ID)
    }

    _findPersonalNameNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_NAME_ID)
    }

    _findPersonalNameOnBarNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        return this.findById(anchor, PERSONAL_NAME_ON_BAR_ID)
    }

    _generateNode(loginInfo, officialServices) {
        let mode = loginInfo ? 'login' : this.linksBeforeLogin ? 'logout' : 'simpleLogout'
        let template = personalTemp(mode, this.hideLogoutLink)

        if (mode === 'login') {
            template = template.replace(/{{headshot}}/g, loginInfo.headshot ? loginInfo.headshot : defaultAvatar)
            template = template.replace(/{{name}}/g, loginInfo.name ? loginInfo.name : '')
            template = template.replace(/{{intro}}/g, loginInfo.intro ? loginInfo.intro : '')
            template = template.replace(/{{prompt}}/g, loginInfo.prompt ? `<p class="${barCss.personal_checkData}">${loginInfo.prompt}<img src="${arrowRight}" alt="" width="16"></p>` : '')
            template = template.replace(/{{domain}}/g, this.domain ? this.domain : '')
            template = template.replace(/{{logoutText}}/g, this.logoutText ? this.logoutText : '')
            
            const personalNode = stringToDom(template)
            const logoutLinkNode = this._findLogoutLinkNode(personalNode)
            const productFuncMark = this._findProductFuncMarkNode(personalNode)

            // if (loginInfo.name) {
            //     const nameNode = this.findByClassName(personalNode, barCss.personal_nickname)
            //     if (nameNode) nameNode.title = loginInfo.name
            // }

            // if (loginInfo.intro) {
            //     const introNode = this.findByClassName(personalNode, barCss.personal_jobName)
            //     if (introNode) introNode.title = loginInfo.intro
            // }
            // const infoNode = this._findPersonalInfoNode(personalNode)

            // if (infoNode) infoNode.setAttribute('data-gtm-sub-param', 'profile-頭像與姓名')
            // else error('Can not found personal info node')

            if (logoutLinkNode) {
                // 處理登出連結
                if (typeof(this.logout) === 'function') {
                    logoutLinkNode.onclick = this.logout

                } else if (typeof(this.logout) === 'string') {
                    logoutLinkNode.setAttribute('href', this.logout)

                } else {
                    error('"logout" must be a "string" or "function"')
                }

                // logoutLinkNode.setAttribute('data-gtm-sub-param', 'profile-登出')

                // 處理官方連結
                if (officialServices) {
                    for (const service of officialServices) {
                        const serviceNode = document.createElement('a')
                        serviceNode.setAttribute('href', service.link)
                        // serviceNode.setAttribute('data-gtm-sub-param', `profile-${service.text.includes('My104') ? 'My104': service.text}`)
                        serviceNode.innerText = service.text
                        serviceNode.classList.add(barCss.personal_member_item)
                        logoutLinkNode.parentNode.insertBefore(serviceNode, logoutLinkNode)

                    }
                }

            } else {
                error('Can not found logout link node')
            }

            // 處理產品客製區塊
            if (loginInfo.productFunc) {
                if (productFuncMark) {
                    let productFuncNode = null
                    if (typeof(loginInfo.productFunc) === 'string') {
                        productFuncNode = stringToDom(loginInfo.productFunc)
                    
                    } else if (loginInfo.productFunc instanceof Node) {
                        productFuncNode = loginInfo.productFunc

                    } else {
                        error('"productFunc" must be a "string" or "Node"')
                    }

                    if (productFuncNode) {
                        productFuncMark.parentNode.replaceChild(productFuncNode, productFuncMark)
                    }
                } else {
                    error('Can not found product function tag, insert ProductFunction FAIL!!')
                }
            }
            
            return personalNode

        } else {
            template = template.replace(/{{headshot}}/g, defaultAvatar)
            template = template.replace(/{{domain}}/g, this.domain ? this.domain : '')
            template = template.replace(/{{prompt}}/g, '')
            template = template.replace(/{{loginText}}/g, this.loginText ? this.loginText : '')
            template = template.replace(/{{loginPrompt}}/g, this.loginPrompt ? this.loginPrompt : '')
            
            if (mode === 'logout') {
                template = template.replace(/{{intro}}/g, this.loginSlogan ? this.loginSlogan : '')
            }
            
            const personalNode = stringToDom(template)
            let loginLinkNode = this._findLoginLinkNode(personalNode)
            loginLinkNode = loginLinkNode ? loginLinkNode : this._findPersonalOpenNode(personalNode)
            let smallHeadShotNode = mode === 'logout' ? null : this._findPersonalSmallHeadshotNode(personalNode)

            if (loginLinkNode) {
                // 處理登入連結
                if (typeof(this.login) === 'function') {
                    loginLinkNode.onclick = this.login
                    if (smallHeadShotNode) smallHeadShotNode.onclick = this.login

                } else if (typeof(this.login) === 'string') {
                    if (loginLinkNode.tagName === 'a') {
                        loginLinkNode.setAttribute('href', this.login)
                    } else {
                        loginLinkNode.onclick = () => { location.href = this.login }
                    }
                    if (smallHeadShotNode) {
                        if (smallHeadShotNode.tagName === 'a') {
                            smallHeadShotNode.setAttribute('href', this.login)
                        } else {
                            smallHeadShotNode.onclick = () => { location.href = this.login }
                        }
                    }

                } else {
                    error('"login" must be a "string" or "function"')
                }

                if (mode === 'logout' && !this.loginSlogan) {
                    if (loginLinkNode.parentNode) {
                        loginLinkNode.parentNode.style.display = 'flex'
                        loginLinkNode.parentNode.style.alignItems = 'center'
                    }
                }

            } else {
                error('Can not found login link node.')
            }

            // 處理登入前連結
            if (this.linksBeforeLogin) {
                const linksBeforeLoginContentNode = this._findPersonalLinksBeforeLoginContentNode(personalNode)
                if (linksBeforeLoginContentNode) {
                    for (const link of this.linksBeforeLogin) {
                        const linkNode = document.createElement('a')
                        linkNode.href = link.link ? link.link : '#'
                        linkNode.innerHTML = link.text ? link.text : ''
                        linkNode.classList.add(barCss.personal_qaItem)
                        if (this.linksBeforeLoginAction && typeof(this.linksBeforeLoginAction) === 'function') {
                            const linksBeforeLoginAction = this.linksBeforeLoginAction
                            linkNode.onclick = function(e) {
                                linksBeforeLoginAction(e, this.innerHTML, this.getAttribute('href'))
                            }
                        }
                        linksBeforeLoginContentNode.appendChild(linkNode)
                    }
                } else {
                    error('Can not found links before login node')
                }
            }

            return personalNode
        }        
    }

    _bindEvent(container, loginInfo) {
        if (this.isLogin || this.linksBeforeLogin) {
            const openNode   = this._findPersonalOpenNode(container)
            const closeNode  = this._findPersonalCloseNode(container)
            const avatarNode = this._findPersonalAvatarNode(container)
            
            // if (openNode) openNode.onclick = this.eventOpen.bind(this)
            // else error('Can not found personal open node.')
            if (openNode) openNode.onclick = evt => {
                if (this.isOpen) this.eventClose(evt)
                else this.eventOpen(evt)
            }
            else error('Can not found personal open node.')


            // if (closeNode) closeNode.onclick = this.eventClose.bind(this)
            // else error('Can not found personal close node')
            
            // if (avatarNode) avatarNode.onclick = this.eventOpen.bind(this)
            // else error('Can not found personal avatar node')
            if (avatarNode) avatarNode.onclick = evt => {
                if (this.isOpen) this.eventClose(evt)
                else this.eventOpen(evt)
            }
            else error('Can not found personal avatar node')


            // 點擊頁面就能關閉 personal menu
            document.body.removeEventListener('click', this.__eventClose4BodyTemp)
            document.body.addEventListener('click', this.__eventClose4BodyTemp)

            const contentNode = this._findPersonalContentNode(container)
            if (contentNode) contentNode.onclick = evt => { evt.stopPropagation() }

            if (this.isLogin) {
                const infoNode = this._findPersonalInfoNode(container)
                if (infoNode) {
                    infoNode.onclick = () => {
                        if (loginInfo.clickUserAction) {
                            loginInfo.clickUserAction()
                        }
                        // this.fire('clickUser')
                        this.fire(HeybarEvents.CLICK_USER)
                    }
                }
            }
        }
    }

    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return // 已是開啟的情況下就不用再執行了

        const personalNode = this._getSelfNode() // this._findPersonalNode()
        
        if (personalNode) {
            const avatarNode = this._findPersonalAvatarNode(personalNode) // container.querySelector(`.${barCss.personal_avatar}`)  // 手機版右上角頭像
            const nameNode = this._findPesonalNameInBarNode(personalNode) // container.querySelector(`.${barCss.personal_name}`)  // PC 版右上角姓名
            // const arrowUpNode = this._findPersonalArrowUpNode(personalNode) // container.querySelector(`.${barCss['border-up-personal']}`)  // 手機版展開時右上角頭像下的箭頭
            // const personalNode = container // container.querySelector(`.${barCss.personal}`)
            
            if (avatarNode) avatarNode.classList.add(barCss['avatar-active'])
            else error('Can not found personal avatar node')

            if (nameNode) nameNode.classList.add(barCss['personal_name--org'])
            else error('Can not found personal name node')

            // if (arrowUpNode) arrowUpNode.classList.remove(barCss['border-up-personal--close'])
            // else error('Can not found personal arrow up node')
            
            personalNode.classList.remove(barCss['personal--close'])

            this.isOpen = true
            // this.fire(`${this.SYSTEM_EVENT_PREFIX}open`)
            // this.fire('open')
            this.emit(SystemEvents.OPEN)
            this.fire(HeybarEvents.OPEN)
        } else {
            error('Can not found personal node, open personal menu fail.')
        }
    }

    eventClose4Body(evt) {
        this.eventClose(evt, false)
    }

    eventClose(evt, stopPropagation=true) {
        if (evt && stopPropagation) evt.stopPropagation()
        if (!this.isOpen) return // 已是關閉的情況下就不用再執行關閉了

        const personalNode = this._getSelfNode() // this._findPersonalNode()
        
        if (personalNode) {
            const avatarNode = this._findPersonalAvatarNode(personalNode) // container.querySelector(`.${barCss.personal_avatar}`)  // 手機版右上角頭像
            const nameNode = this._findPesonalNameInBarNode(personalNode) // container.querySelector(`.${barCss.personal_name}`)  // PC 版右上角姓名
            // const arrowUpNode = this._findPersonalArrowUpNode(personalNode) // container.querySelector(`.${barCss['border-up-personal']}`)  // 手機版展開時右上角頭像下的箭頭
            // const personalNode = container // container.querySelector(`.${barCss.personal}`)
            
            if (avatarNode) avatarNode.classList.remove(barCss['avatar-active'])
            else error('Can not found personal avatar node')

            if (nameNode) nameNode.classList.remove(barCss['personal_name--org'])
            else error('Can not found personal name node')

            // if (arrowUpNode) arrowUpNode.classList.add(barCss['border-up-personal--close'])
            // else error('Can not found personal arrow up node')
            
            personalNode.classList.add(barCss['personal--close'])

            this.isOpen = false
            // this.fire('close')
            this.emit(SystemEvents.CLOSE)
            this.fire(HeybarEvents.CLOSE)
        
        } else {
            error('Can not found personal node, close personal menu fail.')
        }
    }

    eventOpenLinksBeforeLogin(evt) {
        if (evt) evt.stopPropagation()
        
        const linksBeforeLoginNode = this._findPersonalLinksBeforeLoginNode()

        if (linksBeforeLoginNode) {
            const linksBeforeLoginOpenNode = this._findPersonalLinksBeforeLoginOpenNode()
            
            if (linksBeforeLoginOpenNode) linksBeforeLoginOpenNode.classList.add(barCss.barIcon_info_selected)
            else error('Can not found links before login open node')
            
            linksBeforeLoginNode.classList.remove(barCss['info--close'])

            // this.isOpen = true
            // this.fire(`${this.SYSTEM_EVENT_PREFIX}linksBeforeLoginOpen`)
            // this.fire('linksBeforeLoginOpen')
            this.emit(SystemEvents.LINKS_BEFORE_LOGIN_OPEN)
            this.fire(HeybarEvents.LINKS_BEFORE_LOGIN_OPEN)
        
        } else {
            error('Can not found links before login node')
        }
    }

    eventCloseLinksBeforeLogin(evt) {
        if (evt) evt.stopPropagation()
        
        const linksBeforeLoginNode = this._findPersonalLinksBeforeLoginNode()

        if (linksBeforeLoginNode) {
            const linksBeforeLoginOpenNode = this._findPersonalLinksBeforeLoginOpenNode()
            
            if (linksBeforeLoginOpenNode) linksBeforeLoginOpenNode.classList.remove(barCss.barIcon_info_selected)
            else error('Can not found links before login open node')
            
            linksBeforeLoginNode.classList.add(barCss['info--close'])

            // this.isOpen = false
            // this.fire('linksBeforeLoginClose')
            this.fire(HeybarEvents.LINKS_BEFORE_LOGIN_CLOSE)
        
        } else {
            // error('Can not found links before login node')
        }
    }
   
    _getMarkNode() { return super._getMarkNode().length > 0 ? super._getMarkNode() : this._getSelfNode()}

    draw(loginInfo, officialServices) {
        this.isLogin = !!loginInfo

        super.draw(loginInfo, officialServices)
    }

    drawProductName(productName, groupName) {
        const productNameNode = stringToDom(`${productName ? productName : ''}${groupName ? ' - ' + groupName : ''}`)
        const productNameMark = this.findByTag(this.mainNode, 'personal-product-name')

        if (productNameMark) productNameMark.parentNode.replaceChild(productNameNode, productNameMark)
    }

    replaceHeadshot(headshot) {
        if (!this.login) {
            error('replace headshot can only be after login')
            return
        }
        const headshotNode = this._findPersonalHeadshotNode()
        const avatarNode = this._findPersonalAvatarNode()

        if (!headshotNode) error('Can not found headshot node in Personal')
        else if (!avatarNode) error('Can not found avatar node in Personal')
        else {
            headshotNode.setAttribute('src', headshot)
            avatarNode.setAttribute('src', headshot)
        }
    }

    replaceName(name) {
        if (!this.login) {
            error('replace name can only be after login')
            return
        }
        const nameNode = this._findPersonalNameNode()
        const nameOnBarNode = this._findPersonalNameOnBarNode()

        if (!nameNode) error('Can not found name node in Personal')
        else if (!nameOnBarNode) error('Can not found name on bar node in Personal')
        else {
            nameNode.innerHTML = name
            nameOnBarNode.innerHTML = name
            nameOnBarNode.setAttribute('title', name)
        }
    }

    clean() {
        document.body.removeEventListener('click', this.__eventClose4BodyTemp)
        document.body.removeEventListener('click', this.__eventCloseLinksBeforeLoginTemp)
        this.isOpen = false
    }

    destroy() {
        this.clean()
        this.cleanHandlers()
    }
}

Object.assign(Personal.prototype, domMixin(), eventMixin())

Personal.id = PERSONAL_ID
export default Personal
